import React, { useEffect, useState } from "react";
import {
  ClickAwayListener,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import ReactDatePicker from "react-datepicker";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import {
  asyncGetBankDetailData,
  asyncGetCurrencyData,
} from "../../../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetAccountData,
  asyncSearchAccountData,
  handleAccSearchData,
} from "../../../../redux/features/Finance/Account/ChartOfAccountSlice";
import { asyncGetAllChequeSerials } from "../../../../redux/features/Finance/Account/BankBook/ChequeBookSlice";
import Totals from "./Totals";
import { voucherType, dropMenuProp, validationSchema } from "./helper";
import { handleVoucherType } from "../../../../redux/features/Finance/Vouchers/VoucherEntry/Voucher";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  AsyncCreatVoucherEntries,
  AsyncGetSingleVoucherEntries,
  AsyncUpdateSingleVoucherEntries,
} from "../../../../redux/features/Voucher/VoucherSlice";
import BtnLoader from "../../../AppForm/BtnLoader";
import moment from "moment";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import * as yup from "yup";
import { getSingleVoucherLog } from "../../../../redux/features/booking/bookingDetailsSlice";
import { useParams } from "react-router-dom";

function Voucher() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { bankDetails, currencyData } = useSelector(
    (state) => state?.bank_curr_roleSlice
  );
  const { accountData, accSearchData } = useSelector(
    (state) => state.chartAccount
  );

  const { allChequeSerials } = useSelector((state) => state?.chequeBook);
  const { getSingleVoucher } = useSelector((state) => state?.voucherEntries);
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  // Check if the login user has permission to manual voucher entry
  const voucherEntryPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/manual-voucher-entry"
      )
    : true;

  const location = useLocation();
  const voucherId = location.state?.voucherId || params?.id;
  const obj = {
    "Journal Voucher": 1,
    "Payment Voucher": 2,
    "Reciept Voucher": 3,
    // "Sale Invoice": 4,
  };
  const objUpdate = {
    1: "Journal Voucher",
    2: "Payment Voucher",
    3: "Reciept Voucher",
    // 4: "Sale Invoice",
  };
  useEffect(() => {
    dispatch(asyncGetCurrencyData());
    dispatch(asyncGetAccountData());
    dispatch(asyncGetBankDetailData());
    dispatch(handleAccSearchData());
  }, [dispatch, voucherId]);

  useEffect(() => {
    let id = voucherId;
    dispatch(AsyncGetSingleVoucherEntries({ id }));
  }, [dispatch, voucherId]);
  // filter creditor account (only banks) from acc table
  const creadetorAccData = accountData?.filter(
    (it) => it.acc_parent === 8 || it.acc_parent === 11
  );
  // set checque number to description
  const allSerials = (setFieldValue, values) => {
    const chequeNo = allChequeSerials?.find(
      (it) =>
        it.chq_status === "Not Used" && it?.bank_id === values?.bank?.bank_id
    );
    chequeNo &&
      setFieldValue(
        "currencyDetail[0].description",
        `Chq# ${chequeNo?.chq_serial_id} - `
      );
    ///previous was chequeNo?.chq_numbers now  chequeNo?.chq_serial_id
    values?.bank?.bank_id && chequeNo
      ? setFieldValue("SerialError", false)
      : setFieldValue("SerialError", true);
  };
  const updateSingleVoucher = getSingleVoucher?.data?.data;
  // console.log("pv", updateSingleVoucher?.voucher_no?.split("-")?.at(0) === "PV");
  // value={`${item.acc_id} - ${item.name}-${item.account_iban_no}`}
  const validationSchema = yup.object().shape({
    Description: yup.string().when([], {
      is: (value) => location?.state?.postVoucher === true,
      then: yup.string().required("Description is required "),
    }),
  });
  const bankName =
    updateSingleVoucher?.acc_voucher_trans?.at(0)?.acc_account?.acc_id +
    "-" +
    updateSingleVoucher?.acc_voucher_trans?.at(0)?.acc_account?.acc_title;
  const bankid = bankDetails?.find(
    (bank) =>
      bank.name === bankName?.split("-")?.at(1) &&
      ~~bankName?.split("-")?.at(0) === ~~bank.acc_id
  )?.acc_id;
  //  value={`${item.acc_id}-${item.name}-${item.account_iban_no}`}
  const bankNameBydefuilt =
    bankDetails?.at(0)?.acc_id +
    "-" +
    bankDetails?.at(0)?.name +
    "-" +
    bankDetails?.at(0)?.account_iban_no;
  const [initialValues, setInitialValues] = useState({
    voucherType: "Journal Voucher",
    agent: null,
    Description: "",
    voucher_date: new Date(),
    remarks: "",
    currency: "",
    creditor_account: "",
    bank: "",
    pay_type: null,
    reciep_pay_type: "",
    bank_name: "",
    SerialError: false,
    acc_drp: false,
    currencyDetail: [
      {
        account_no: "",
        agent_roe: "",
        agent_curr: "",
        agent_curr_code: "",
        acc_parent: "",
        acc_title: "",
        description: "",
        currency: "",
        curr_name: "PKR",
        roe: "1",
        currency_debit: "",
        currency_credit: "",
        debitor: "",
        creditor: "",
      },
      {
        account_no: "",
        agent_roe: "",
        agent_curr: "",
        agent_curr_code: "",
        acc_parent: "",
        acc_title: "",
        description: "",
        currency: "",
        curr_name: "PKR",
        roe: "1",
        currency_debit: "",
        currency_credit: "",
        debitor: "",
        creditor: "",
      },
    ],
  });

  useEffect(() => {
    if (updateSingleVoucher?.voucher_id === "null") {
      setInitialValues({
        voucherType: "Journal Voucher",
        agent: null,
        Description: "",
        voucher_date: new Date(),
        remarks: "",
        currency: "",
        creditor_account: "",
        bank: "",
        pay_type: null,
        reciep_pay_type: "",
        bank_name: "",
        SerialError: false,
        acc_drp: false,
        currencyDetail: [
          {
            account_no: "",
            agent_roe: "",
            agent_curr: "",
            agent_curr_code: "",
            acc_parent: "",
            acc_title: "",
            description: "",
            currency: "",
            curr_name: "PKR",
            roe: "1",
            currency_debit: "",
            currency_credit: "",
            debitor: "",
            creditor: "",
          },
          {
            account_no: "",
            agent_roe: "",
            agent_curr: "",
            agent_curr_code: "",
            acc_parent: "",
            acc_title: "",
            description: "",
            currency: "",
            curr_name: "PKR",
            roe: "1",
            currency_debit: "",
            currency_credit: "",
            debitor: "",
            creditor: "",
          },
        ],
      });
    } else {
      setInitialValues({
        voucherType:
          objUpdate[updateSingleVoucher?.voucher_type_id] || "Journal Voucher",
        agent: null,
        Description: "",
        voucher_date: updateSingleVoucher?.approved_by
          ? moment(updateSingleVoucher?.approved_date || new Date()).toDate()
          : moment(updateSingleVoucher?.saved_date || new Date()).toDate(),
        remarks: updateSingleVoucher?.voucher_description || "",
        currency: "",
        creditor_account:
          updateSingleVoucher?.acc_voucher_trans?.at(
            updateSingleVoucher?.voucher_no?.split("-")?.at(0) === "PV" ? 0 : 1
          ).acc_account?.acc_id +
          "-" +
          updateSingleVoucher?.acc_voucher_trans?.at(
            updateSingleVoucher?.voucher_no?.split("-")?.at(0) === "PV" ? 0 : 1
          )?.acc_account?.acc_title,
        bank: "",
        pay_type: bankid
          ? updateSingleVoucher?.acc_voucher_trans
              ?.at(0)
              ?.trans_description?.split("#")
              ?.at(0) === "CHQ"
            ? "cheque"
            : "ibft"
          : null,
        reciep_pay_type:
          updateSingleVoucher?.acc_voucher_trans.at(1)?.acc_account
            ?.acc_title === "CASH IN HAND"
            ? "CASH IN HAND"
            : "Bank" || "",
        bank_name: updateSingleVoucher?.voucher_id
          ? `${
              updateSingleVoucher?.acc_voucher_trans.at(1)?.acc_account?.acc_id
            }-${updateSingleVoucher?.acc_voucher_trans
              .at(1)
              ?.acc_account?.acc_title?.split("-")
              ?.at(0)}-${updateSingleVoucher?.acc_voucher_trans
              .at(1)
              ?.acc_account?.acc_title?.split("-")
              ?.at(1)}`
          : bankNameBydefuilt,

        SerialError: false,
        acc_drp: false,
        currencyDetail: updateSingleVoucher?.voucher_id
          ? updateSingleVoucher?.acc_voucher_trans?.map((item) => ({
              trans_id: item?.trans_id,
              accountId:
                item?.acc_account?.acc_id + "-" + item?.acc_account?.acc_title,
              account_no:
                item?.acc_account?.acc_id + "-" + item?.acc_account?.acc_title,
              acc_title:
                item?.acc_account?.acc_id + "-" + item?.acc_account?.acc_title,
              description: item?.trans_description,
              currency: "",
              curr_name: item?.cur,
              roe: item?.roe,
              currency_debit: Number(item?.s_cur_debit),
              currency_credit: Number(item?.s_cur_credit),
              debitor: Number(item?.d_cur_debit),
              creditor: Number(item?.d_cur_credit),
            }))
          : [
              {
                account_no: "",
                agent_roe: "",
                agent_curr: "",
                agent_curr_code: "",
                acc_parent: "",
                acc_title: "",
                description: "",
                currency: "",
                curr_name: "PKR",
                roe: "1",
                currency_debit: "",
                currency_credit: "",
                debitor: "",
                creditor: "",
              },
              {
                account_no: "",
                agent_roe: "",
                agent_curr: "",
                agent_curr_code: "",
                acc_parent: "",
                acc_title: "",
                description: "",
                currency: "",
                curr_name: "PKR",
                roe: "1",
                currency_debit: "",
                currency_credit: "",
                debitor: "",
                creditor: "",
              },
            ],
      });
    }
  }, [updateSingleVoucher]);

  // [updateSingleVoucher, bankid, bankNameBydefuilt, voucherId, objUpdate];
  const [message, setMessage] = useState(false);
  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      // enableReinitialize={voucherId ? true : false}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        const formData = new FormData();
        formData.append("currencyId", 1);
        formData.append("Description", values?.Description);
        formData.append("voucherType", obj[values?.voucherType]);
        formData.append("pay_type", values?.pay_type);

        formData.append(
          "ttlDebitDefultCur",
          values?.currencyDetail?.reduce(
            (total, item) => total + ~~item.debitor,
            0
          )
        );
        formData.append(
          "ttlCreditDefultCur",
          values?.currencyDetail?.reduce(
            (total, item) => total + ~~item.creditor,
            0
          )
        );
        formData.append("voucherDescription", values.remarks);
        formData.append("savedDate", values.voucher_date);
        formData.append("image", values.image);
        const filteredData = values?.currencyDetail?.filter((flt) => {
          return flt?.agent_roe !== "" && flt?.agent_roe !== 0;
        });
        const transactionDetailsArray = values?.currencyDetail?.map(
          (item) => (
            console.log("values item", item),
            {
              trans_id: item?.trans_id,
              accountId: ~~item?.account_no?.split("-")[0],
              cur: updateSingleVoucher
                ? item?.curr_name
                : item?.agent_curr === Infinity || !item?.agent_curr
                ? item?.curr_name
                : item?.agent_curr_code,
              // ? item?.curr_name
              // : filteredData?.length
              // ? filteredData[0]?.agent_curr_code
              // : item?.curr_name,
              roe: updateSingleVoucher
                ? item?.roe?.toString()
                : (item?.agent_curr === Infinity) || !item?.agent_curr
                ? item?.roe?.toString()
                : item?.agent_roe?.toString(),
              // updateSingleVoucher
              //   ? item?.roe?.toString()
              //   : filteredData?.length
              //   ? filteredData[0]?.agent_roe?.toString()
              //   : item?.roe?.toString(),

              transctionDescription: item?.description,
              s_cur_debit: updateSingleVoucher
                ? Number(item?.currency_debit)
                : values?.voucherType === "Journal Voucher" &&
                  filteredData[0]?.agent_curr
                ? TwoDecimalPoint(Number(item?.debitor)) > 0
                  ? TwoDecimalPoint(
                      Number(filteredData[0]?.agent_curr?.toString())
                    )
                  : Number(item?.currency_debit)
                : Number(item?.currency_debit),
              s_cur_credit: updateSingleVoucher
                ? Number(item?.currency_credit)
                : values?.voucherType === "Journal Voucher" &&
                  filteredData[0]?.agent_curr
                ? TwoDecimalPoint(Number(item?.creditor)) > 0
                  ? TwoDecimalPoint(
                      Number(filteredData[0]?.agent_curr?.toString())
                    )
                  : Number(item?.currency_credit)
                : Number(item?.currency_credit),

              d_cur_debit: TwoDecimalPoint(Number(item?.debitor)),
              d_cur_credit: TwoDecimalPoint(Number(item?.creditor)),
            }
          )
        );

        formData.append(
          "transactionDetails",
          JSON.stringify(transactionDetailsArray)
        );
        for (const [key, value] of formData.entries()) {
          console.log("jj", `${key}:`, value);
        }
        setMessage(true);
        setTimeout(() => {
          const data = updateSingleVoucher
            ? dispatch(
                AsyncUpdateSingleVoucherEntries({
                  id: voucherId,
                  body: formData,
                })
              )
                .then((response) => {
                  // Handle the response here if needed
                  setMessage(false);
                  resetForm();
                  if (location?.state?.postVoucher) {
                    navigate("/post-voucher");
                  } else {
                    navigate("/save-voucher");
                  }
                })
                .catch((error) => {
                  // Handle the error here
                  console.error("Error from AsyncCreatVoucherEntries:", error);
                })
            : dispatch(AsyncCreatVoucherEntries({ body: formData }))
                .then((response) => {
                  // Handle the response here if needed
                  setMessage(false);
                  resetForm();
                  navigate("/save-voucher");
                })
                .catch((error) => {
                  // Handle the error here
                  console.error("Error from AsyncCreatVoucherEntries:", error);
                });
        }, 3000);
      }}
    >
      {({ values, setFieldValue, handleChange, resetForm, errors }) => {
        const totalDebitor = values?.currencyDetail?.reduce(
          (total, item) => total + ~~item.debitor,
          0
        );
        const totalCreditor = values?.currencyDetail?.reduce(
          (total, item) => total + ~~item.creditor,
          0
        );
        const totalDebit = values?.currencyDetail?.reduce(
          (total, item) => total + ~~item.currency_debit,
          0
        );
        const totalCredit = values?.currencyDetail?.reduce(
          (total, item) => total + ~~item.currency_credit,
          0
        );

        return (
          <Form>
            <div className="row p-2 pt-md-5 px-md-4 align-items-end">
              <div className="col-6 col-md-2">
                {updateSingleVoucher?.voucher_no && (
                  <p className="text-primary">
                    Voucher No:{updateSingleVoucher?.voucher_no}
                  </p>
                )}
                <FormControl variant="standard" className="w-100" required>
                  <InputLabel id="voucherType">Voucher Type</InputLabel>

                  <Select
                    labelId="voucherType"
                    id="voucherType"
                    name="voucherType"
                    value={values?.voucherType}
                    inputProps={{ readOnly: voucherId }}
                    onChange={(e) => {
                      let value = e.target.value;
                      resetForm({ voucherType: values });
                      setFieldValue("voucherType", value);
                      dispatch(handleVoucherType(value?.title));
                      setFieldValue("SerialError", false);
                      dispatch(handleAccSearchData());
                    }}
                    MenuProps={dropMenuProp}
                  >
                    {voucherType?.slice(0, 3).map((item, index) => (
                      <MenuItem
                        value={item?.title}
                        sx={{ bgcolor: "white", m: 1 }}
                        key={index}
                        onClick={(e) => {
                          if (item?.title === "Sale Invoice") {
                            navigate("/manual-invoice");
                          }
                        }}
                      >
                        {item?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {values?.voucherType === "Payment Voucher" && (
                <div className="col-6 col-md-2">
                  <FormControl variant="standard" className="w-100" required>
                    <InputLabel id="Select_Creditor_Account">
                      PV Creditor Account
                    </InputLabel>
                    <Select
                      labelId="Select_Creditor_Account"
                      id="Select_Creditor_Account"
                      name="creditor_account"
                      value={values?.creditor_account}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue("creditor_account", value);
                        setFieldValue("SerialError", false);
                        const bank = bankDetails?.find((bank) => {
                          return (
                            bank.name === value?.split("-")?.at(1) &&
                            ~~value?.split("-")?.at(0) === ~~bank.acc_id
                          );
                        });
                        setFieldValue("pay_type", bank?.acc_id ? "ibft" : null);
                        setFieldValue("bank", bank);
                        setFieldValue(`currencyDetail[0].acc_title`, value);
                        setFieldValue(`currencyDetail[0].account_no`, value);

                        // setFieldValue(`pay_type`, "");
                        setFieldValue(`currencyDetail[0].description`, "");

                        dispatch(asyncGetAllChequeSerials(bank?.bank_id));
                        dispatch(handleAccSearchData());
                      }}
                      MenuProps={dropMenuProp}
                    >
                      {creadetorAccData?.map((item, index) => (
                        <MenuItem
                          value={`${item.acc_id}-${item.acc_title}`}
                          sx={{ bgcolor: "white", m: 1 }}
                          key={index}
                        >
                          {item.acc_id}-{item.acc_title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}

              <div className="col-6 col-md-2">
                <ReactDatePicker
                  placeholderText="Voucher Date"
                  name="voucher_date"
                  selected={values.voucher_date}
                  onChange={(date) => {
                    setFieldValue("voucher_date", date); // Use date, not e.target.value
                  }}
                  maxDate={new Date()}
                  className="date_picker"
                  dateFormat="MMM dd, yyyy"
                  required
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
              <div className="col-6 col-md-2">
                <TextField
                  label="Remarks"
                  variant="standard"
                  className="w-100"
                  name="remarks"
                  value={values?.remarks?.toUpperCase()}
                  onChange={handleChange}
                />
              </div>
              <div className="col-2">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="mb-2">
                    Attach Image
                  </label>
                  <input
                    id="image"
                    name="image"
                    type="file"
                    onChange={(event) =>
                      setFieldValue("image", event.target.files[0])
                    }
                  />
                </div>
              </div>
              {voucherId && (
                <div className="col-6 col-md-2 text-end">
                  <button
                    className="button_style w-50"
                    onClick={() =>
                      location?.state?.postVoucher
                        ? navigate("/post-voucher")
                        : navigate("/save-voucher")
                    }
                  >
                    Back
                  </button>
                </div>
              )}
            </div>
            {values?.voucherType === "Payment Voucher" && (
              <div className="pt-5">
                {values?.creditor_account !== "CASH IN HAND" ? (
                  <div className="d-flex align-items-end mt-4 mt-md-0">
                    <label
                      className="form-control mx-4"
                      onClick={() => {
                        allSerials(setFieldValue, values);
                      }}
                    >
                      <input
                        type="radio"
                        name="pay_type"
                        checked={values.pay_type === "cheque"}
                        onChange={(e) => {
                          let isChecked = e.target.checked;
                          isChecked && setFieldValue("pay_type", "cheque");
                        }}
                      />
                      <span className="">Cheque</span>
                    </label>
                    <label className="form-control">
                      <input
                        type="radio"
                        name="pay_type"
                        checked={values.pay_type === "ibft"}
                        onChange={(e) => {
                          let isChecked = e.target.checked;
                          isChecked &&
                            setFieldValue("currencyDetail[0].description", "");
                          setFieldValue("pay_type", "ibft");
                          setFieldValue("SerialError", false);
                        }}
                      />
                      <span className="">IBFT</span>
                    </label>
                    <ErrorMessage
                      component={"div"}
                      name="reciep_pay_type"
                      className="text-danger mx-3"
                    />

                    {values.SerialError ? (
                      <div className="ms-3 text-danger">
                        Chq Book not inserted or may used. Add a new Chq book
                        then try again.
                      </div>
                    ) : null}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            {values?.voucherType === "Reciept Voucher" &&
              (console.log("values", values),
              (
                <div className=" pt-5">
                  <div className="d-flex align-items-end mt-4 mt-md-0">
                    <div className="d-flex">
                      <label className="form-control mx-4">
                        <input
                          type="radio"
                          name="reciep_pay_type"
                          checked={values?.reciep_pay_type === "CASH IN HAND"}
                          value={values.reciep_pay_type}
                          onChange={(e) => {
                            let cashAcc = creadetorAccData?.find(
                              (it) => it.acc_title === "CASH IN HAND"
                            );
                            setFieldValue(
                              "reciep_pay_type",
                              `${cashAcc.acc_id} - ${cashAcc.acc_title}`
                            );
                            setFieldValue(
                              `currencyDetail[1].acc_title`,
                              `${cashAcc.acc_id} - ${cashAcc.acc_title}`
                            );
                            setFieldValue(
                              `currencyDetail[1].account_no`,
                              `${cashAcc.acc_id} - ${cashAcc.acc_title}`
                            );
                            setFieldValue("bank_name", "");
                            setFieldValue("reciep_pay_type", "CASH IN HAND");
                          }}
                        />
                        <span className="">Cash In Hand</span>
                      </label>
                      <label className="form-control">
                        <input
                          type="radio"
                          name="reciep_pay_type"
                          checked={values?.reciep_pay_type === "Bank"}
                          value={values.reciep_pay_type}
                          onChange={(e) => {
                            setFieldValue("reciep_pay_type", "Bank");
                            setFieldValue("bank_name", "");
                          }}
                        />
                        <span className="">Bank</span>
                      </label>
                      <ErrorMessage
                        component={"div"}
                        name="reciep_pay_type"
                        className="text-danger mx-3"
                      />
                    </div>
                    {values?.reciep_pay_type === "Bank" && (
                      <div className="col-6 col-md-2 ms-5">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="bank">Select Bank</InputLabel>
                          <Select
                            labelId="bank"
                            id="bank"
                            name="bank_name"
                            value={values?.bank_name}
                            // onChange={(e) => {
                            //   let value = e.target.value;
                            //   setFieldValue("bank_name", value);
                            //   setFieldValue(`currencyDetail[1].acc_title`, value);
                            //   setFieldValue(
                            //     `currencyDetail[1].account_no`,
                            //     value
                            //   );
                            // }}
                            MenuProps={dropMenuProp}
                          >
                            {bankDetails?.map((item, index) => (
                              <MenuItem
                                value={`${item.acc_id}-${item.name}-${item.account_iban_no}`}
                                sx={{ bgcolor: "white", m: 1 }}
                                key={item.acc_id}
                                onClick={(e) => {
                                  setFieldValue(
                                    "bank_name",
                                    `${item.acc_id}-${item.name}-${item.account_iban_no}`
                                  );
                                  setFieldValue(
                                    `currencyDetail[1].acc_title`,
                                    `${item.acc_id}-${item.name}-${item.account_iban_no}`
                                  );
                                  setFieldValue(
                                    `currencyDetail[1].account_no`,
                                    `${item.acc_id}-${item.name}-${item.account_iban_no}`
                                  );
                                }}
                              >
                                {item.acc_id} - {item.name}-
                                {item.account_iban_no}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            <FieldArray
              name="currencyDetail"
              render={(arrayHelpers) => (
                <div className="py-3">
                  {values?.currencyDetail?.map((currency, index) => {
                    return (
                      <div
                        className="row px-2 px-md-4 pt-4 pb-4 align-items-end"
                        key={index}
                      >
                        <div className="col-10 col-md-2 position-relative">
                          <TextField
                            variant="standard"
                            label="Account No/Account Title"
                            placeholder="Enter Acount Title"
                            autoComplete="off"
                            className="w-100"
                            name={`currencyDetail[${index}].acc_title`}
                            value={currency.acc_title}
                            onClick={(e) =>
                              (index === 0 &&
                                values.voucherType === "Payment Voucher") ||
                              (index === 1 &&
                                values.voucherType === "Reciept Voucher")
                                ? null
                                : setFieldValue("acc_drp", index)
                            }
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("acc_drp", index);
                              setFieldValue(
                                `currencyDetail[${index}].acc_title`,
                                value
                              );

                              value.length !== 0
                                ? dispatch(asyncSearchAccountData(value))
                                : dispatch(handleAccSearchData());
                            }}
                            onFocus={(e) => {
                              e.target.select();
                            }}
                            autoFocus={values.acc_drp === index}
                            inputProps={{
                              readOnly:
                                (index === 0 &&
                                  values.voucherType === "Payment Voucher") ||
                                (index === 1 &&
                                  values.voucherType === "Reciept Voucher")
                                  ? true
                                  : false,
                            }}
                            required
                          />
                          {values.acc_drp === index ? (
                            <ClickAwayListener
                              onClickAway={() =>
                                setFieldValue("acc_drp", false)
                              }
                            >
                              <div className="agent_dropdown">
                                {accSearchData?.length > 0 ? (
                                  accSearchData
                                    // ?.filter(
                                    //   (flt) => {
                                    //     const parent =
                                    //     values.currencyDetail[index].acc_parent;
                                    //     const is_agent =
                                    //       !parent ||
                                    //       !flt?.acc_parent !== parent;
                                    //     return is_agent;
                                    //   }

                                    // )
                                    ?.map((item) => {
                                      console.log("item", item);
                                      return (
                                        <div
                                          className="p-3"
                                          onClick={(e) => {
                                            setFieldValue(
                                              `currencyDetail[${index}].agent_roe`,
                                              item?.agents_comp?.length
                                                ? ~~item?.agents_comp?.at(0)
                                                    ?.agent_group?.currency?.roe
                                                : ~~item?.supplier_lists?.at(0)
                                                    ?.currency.roe
                                            );

                                            setFieldValue(
                                              `currencyDetail[${index}].agent_curr_code`,
                                              item?.agents_comp?.length
                                                ? item?.agents_comp?.at(0)
                                                    ?.agent_group?.currency
                                                    ?.curr_code
                                                : item?.supplier_lists?.at(0)
                                                    ?.currency?.curr_code
                                            );

                                            setFieldValue(
                                              `currencyDetail[${index}].acc_parent`,
                                              item?.acc_parent
                                            );

                                            setFieldValue(`agent`, item.acc_id);
                                            setFieldValue(
                                              `currencyDetail[${index}].account_no`,
                                              `${item.acc_id} - ${item.acc_title}`
                                            );
                                            setFieldValue(
                                              `currencyDetail[${index}].acc_title`,
                                              `${item.acc_id} - ${item.acc_title}`
                                            );

                                            if (
                                              (values.voucherType ===
                                                "Payment Voucher" ||
                                                values.voucherType ===
                                                  "Reciept Voucher") &&
                                              (item?.agents_comp?.length ||
                                                item?.supplier_lists?.length)
                                            ) {
                                              values?.currencyDetail.forEach(
                                                (_, i) => {
                                                  setFieldValue(
                                                    `currencyDetail[${i}].curr_name`,
                                                    item?.agents_comp?.length
                                                      ? item?.agents_comp?.at(0)
                                                          ?.agent_group
                                                          ?.currency?.curr_code
                                                      : item?.supplier_lists?.at(
                                                          0
                                                        )?.currency?.curr_code
                                                  );

                                                  setFieldValue(
                                                    `currencyDetail[${i}].roe`,
                                                    item?.agents_comp?.length
                                                      ? ~~item?.agents_comp?.at(
                                                          0
                                                        )?.agent_group?.currency
                                                          ?.roe
                                                      : ~~item?.supplier_lists?.at(
                                                          0
                                                        )?.currency.roe
                                                  );
                                                }
                                              );
                                            }
                                            // else {
                                            //   values?.currencyDetail.forEach(
                                            //     (_, i) => {
                                            //       setFieldValue(
                                            //         `currencyDetail[${i}].curr_name`,
                                            //         "PKR"
                                            //       );
                                            //       setFieldValue(
                                            //         `currencyDetail[${i}].roe`,
                                            //        1
                                            //       );
                                            //     }
                                            //   );
                                            // }

                                            // setFieldValue(
                                            //   `currencyDetail[${index}].curr_name`,
                                            //   item?.agents_comp[0]?.agent_group
                                            //     ?.currency?.curr_code3
                                            // );
                                            // setFieldValue(
                                            //   `currencyDetail[${index}].roe`,
                                            //   ~~item?.agents_comp[0]
                                            //     ?.agent_group?.currency?.roe
                                            // );

                                            setFieldValue("acc_drp", false);
                                            dispatch(handleAccSearchData());
                                          }}
                                          style={{ cursor: "pointer" }}
                                          key={item?.acc_id}
                                        >
                                          {item.acc_id} - {item.acc_title}
                                        </div>
                                      );
                                    })
                                ) : (
                                  <div className="p-3">
                                    No record available.
                                  </div>
                                )}
                              </div>
                            </ClickAwayListener>
                          ) : null}
                          {/* /////////////////////////////////////////////Agent currency/////////////////////////////////// */}
                          <div className="d-flex  gap-2 position-absolute mt-2 fs-6 ">
                            {values?.voucherType === "Journal Voucher" &&
                              values.currencyDetail[index].agent_curr !==
                                Infinity &&
                              values.currencyDetail[index].agent_curr > 0 && (
                                <>
                                  <p className="text-primary ">
                                    {(values.currencyDetail[index].debitor &&
                                      values.currencyDetail[index]
                                        .agent_curr_code +
                                        " " +
                                        "(Dr)" +
                                        " =") ||
                                      (values.currencyDetail[index].creditor &&
                                        values.currencyDetail[index]
                                          .agent_curr_code +
                                          " " +
                                          "(Cr)" +
                                          " =")}
                                  </p>
                                  <p className="text-primary ">
                                    {typeof values.currencyDetail[index]
                                      .agent_curr === "number"
                                      ? values.currencyDetail[
                                          index
                                        ].agent_curr.toFixed(2)
                                      : null}
                                  </p>
                                </>
                              )}
                          </div>
                        </div>
                        <div className="col-4 col-md-2 mt-3 mt-md-0">
                          <TextField
                            label="Description (char limit 100)"
                            variant="standard"
                            autoComplete="off"
                            // multiline
                            rows={2}
                            className="w-100"
                            name={`currencyDetail[${index}].description`}
                            value={currency.description?.toUpperCase()}
                            onChange={(e) =>
                              setFieldValue(
                                `currencyDetail[${index}].description`,
                                e.target.value.toUpperCase()
                              )
                            }
                            required
                          />
                        </div>
                        <div className="col-4 col-md-2 mt-3 mt-md-0">
                          <FormControl
                            variant="standard"
                            className="w-100"
                            required
                          >
                            <InputLabel id="currency">Currency</InputLabel>
                            <Select
                              labelId="currency"
                              id="currency"
                              name={`currencyDetail[${index}].curr_name`}
                              value={currency.curr_name}
                              onChange={(e) => {
                                const value = e.target.value;
                                const selectedCurrencyData = currencyData?.find(
                                  (item) => item.curr_code === value
                                );

                                // Update all elements in the currencyDetail array
                                const updatedCurrencyDetail =
                                  values.currencyDetail?.map((item, idx) => ({
                                    ...item,
                                    currency: selectedCurrencyData,
                                    roe: selectedCurrencyData.roe,
                                    curr_name: value,
                                    currency_debit: "",
                                    currency_credit: "",
                                    debitor: "",
                                    creditor: "",
                                  }));
                                setFieldValue(
                                  `currencyDetail`,
                                  updatedCurrencyDetail
                                );
                              }}
                              MenuProps={dropMenuProp}
                              disabled={voucherId}
                              inputProps={{
                                readOnly: index !== 0,
                              }}
                            >
                              {currencyData?.map((item, index) => (
                                <MenuItem
                                  value={item.curr_code} ///this value assign above select value
                                  sx={{ bgcolor: "white" }}
                                  key={index}
                                >
                                  {item.curr_name} - {item.curr_code}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          <TextField
                            label="ROE"
                            variant="standard"
                            className="w-100"
                            autoComplete="off"
                            type={"number"}
                            name={`currencyDetail[${index}].roe`}
                            value={currency.roe}
                            onChange={(e) => {
                              setFieldValue(
                                `currencyDetail[${index}].roe`,
                                e.target.value
                              );
                              setFieldValue(
                                `currencyDetail[${index}].debitor`,
                                (
                                  e.target.value * currency.currency_debit
                                ).toFixed(2)
                              );
                              setFieldValue(
                                `currencyDetail[${index}].creditor`,
                                (
                                  e.target.value * currency.currency_credit
                                ).toFixed(2)
                              );
                            }}
                            disabled={currency.curr_name === "PKR"}
                            required
                          />
                        </div>
                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          <TextField
                            label={`${
                              currency?.currency
                                ? currency?.currency?.curr_code
                                : ""
                            } Debit`}
                            variant="standard"
                            autoComplete="off"
                            className="w-100"
                            type={"number"}
                            name={`currencyDetail[${index}].currency_debit`}
                            value={currency.currency_debit}
                            onChange={(e) => {
                              const value = e.target.value;

                              setFieldValue(
                                `currencyDetail[${index}].currency_debit`,
                                value
                              );
                              setFieldValue(
                                `currencyDetail[${index}].debitor`,
                                (value * currency.roe).toFixed(2)
                              );

                              const agentRoe =
                                values.currencyDetail[index].agent_roe; // Corrected

                              setFieldValue(
                                `currencyDetail[${index}].agent_curr`,
                                (value * currency.roe).toFixed(2) / ~~agentRoe
                              );
                            }}
                            disabled={
                              (values.voucherType === "Payment Voucher" ||
                                values.voucherType === "Reciept Voucher") &&
                              index === 0
                                ? true
                                : currency?.currency_credit ||
                                  currency.curr_name === "PKR"
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          <TextField
                            label={`${
                              currency?.currency
                                ? currency?.currency?.curr_code
                                : ""
                            } Credit`}
                            variant="standard"
                            autoComplete="off"
                            className="w-100"
                            type={"number"}
                            name={`currencyDetail[${index}].currency_credit`}
                            value={currency.currency_credit}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFieldValue(
                                `currencyDetail[${index}].currency_credit`,
                                value
                              );
                              setFieldValue(
                                `currencyDetail[${index}].creditor`,
                                (value * currency.roe).toFixed(2)
                              );

                              const agentRoe =
                                values.currencyDetail[index].agent_roe; // Corrected

                              setFieldValue(
                                `currencyDetail[${index}].agent_curr`,
                                (value * currency.roe).toFixed(2) / ~~agentRoe
                              );
                              //  if(values.voucherType === "Payment Voucher" ||
                              //         values.voucherType === "Reciept Voucher")
                              //     {
                              //        setFieldValue(
                              //          `currencyDetail[${index}].agent_curr`,
                              //          (value * currency.roe).toFixed(2) /
                              //            ~~agentRoe
                              //        );
                              //     }else{
                              //         setFieldValue(
                              //           `currencyDetail[${index}].agent_curr`,
                              //           (value * currency.roe).toFixed(2) /
                              //             ~~agentRoe
                              //         );
                              //     }

                              if (
                                (values.voucherType === "Payment Voucher" ||
                                  values.voucherType === "Reciept Voucher") &&
                                index === 0
                              ) {
                                setFieldValue(
                                  `currencyDetail[1].currency_debit`,
                                  value
                                );
                                setFieldValue(
                                  `currencyDetail[1].debitor`,
                                  (value * currency.roe).toFixed(2)
                                );
                              }
                            }}
                            disabled={
                              (values.voucherType === "Payment Voucher" ||
                                values.voucherType === "Reciept Voucher") &&
                              index === 1
                                ? true
                                : currency?.currency_debit ||
                                  currency.curr_name === "PKR"
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          <TextField
                            label="PKR Debitor"
                            variant="standard"
                            className="w-100"
                            autoComplete="off"
                            type={"number"}
                            name={`currencyDetail[${index}].debitor`}
                            value={currency.debitor}
                            onChange={(e) => {
                              setFieldValue(
                                `currencyDetail[${index}].debitor`,
                                e.target.value
                              );
                              const agentRoe =
                                values.currencyDetail[index].agent_roe; // Corrected
                              setFieldValue(
                                `currencyDetail[${index}].agent_curr`,
                                e.target.value / ~~agentRoe
                              );

                              //  const agentRoe =
                              //    values.currencyDetail[index].agent_roe; // Corrected
                              //  setFieldValue(
                              //    `currencyDetail[${index}].agent_curr`,
                              //    values.currencyDetail[index].debitor /
                              //      ~~agentRoe
                              //  );
                            }}
                            disabled={
                              ((values.voucherType === "Payment Voucher" ||
                                values.voucherType === "Reciept Voucher") &&
                                index === 0) ||
                              currency.curr_name !== "PKR"
                                ? true
                                : currency?.currency_credit
                                ? true
                                : currency?.creditor
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          <TextField
                            label="PKR Creditor"
                            variant="standard"
                            className="w-100"
                            autoComplete="off"
                            type={"number"}
                            name={`currencyDetail[${index}].creditor`}
                            value={currency.creditor}
                            onChange={(e) => {
                              setFieldValue(
                                `currencyDetail[${index}].creditor`,
                                e.target.value
                              );
                              const agentRoe =
                                values.currencyDetail[index].agent_roe; // Corrected
                              setFieldValue(
                                `currencyDetail[${index}].agent_curr`,
                                e.target.value / ~~agentRoe
                              );
                              if (
                                (values.voucherType === "Payment Voucher" ||
                                  values.voucherType === "Reciept Voucher") &&
                                index === 0
                              ) {
                                setFieldValue(
                                  "currencyDetail[1].debitor",
                                  e.target.value
                                );
                              }
                            }}
                            disabled={
                              ((values.voucherType === "Payment Voucher" ||
                                values.voucherType === "Reciept Voucher") &&
                                index === 1) ||
                              currency.curr_name !== "PKR"
                                ? true
                                : currency?.currency_debit
                                ? true
                                : currency?.debitor
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="col-4 col-md-1 mt-3 mt-md-0">
                          {index === 0 ? (
                            <button
                              className="button_style w-100"
                              onClick={() => {
                                arrayHelpers.push({
                                  account_no: "",
                                  description: "",
                                  currency: currency?.currency,
                                  curr_name: currency.curr_name,
                                  roe: currency?.roe,
                                  currency_debit: "",
                                  currency_credit: "",
                                  debitor: "",
                                  creditor: "",
                                });
                              }}
                              type="button"
                              disabled={
                                (values.voucherType !== "Journal Voucher" &&
                                  values.currencyDetail.length > 1) ||
                                !voucherEntryPermission
                              }
                            >
                              Add
                            </button>
                          ) : (
                            <button
                              className="button_style bg-danger w-100"
                              onClick={() => arrayHelpers.remove(index)}
                              type="button"
                              disabled={
                                values.currencyDetail.length < 3 ||
                                !voucherEntryPermission
                              }
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            />

            <Totals
              data={{
                totalCredit: totalCredit && totalCredit,
                totalDebit: totalDebit && totalDebit,
                totalCreditor: totalCreditor && totalCreditor,
                totalDebitor: totalDebitor && totalDebitor,
                values: values,
                voucherId: voucherId,
              }}
            />
            {location?.state?.postVoucher && (
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column gap-4  text-center justify-content-center align-items-center w-50">
                  <div className="d-flex gap-4" style={{ width: "100%" }}>
                    <div className="fs-5 mt-4 ">Action:</div>
                    <div className=" col-10">
                      <TextField
                        label="Description"
                        variant="standard"
                        name="Description"
                        className="w-100"
                        value={values.Description}
                        autoComplete="off"
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("Description", value.toUpperCase());
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    {errors.Description ? (
                      <div className="text-danger">{errors.Description}</div>
                    ) : null}{" "}
                  </div>
                </div>
              </div>
            )}
            {updateSingleVoucher?.voucher_no ? (
              <div className="text-center py-4">
                <button
                  className="button_style px-4"
                  type="submit"
                  disabled={
                    totalDebitor === totalCreditor &&
                    totalDebitor !== 0 &&
                    totalCreditor !== 0
                      ? false
                      : true
                  }
                >
                  {message ? (
                    <BtnLoader />
                  ) : location?.state?.postVoucher ? (
                    "Edit"
                  ) : (
                    "Post"
                  )}
                </button>
              </div>
            ) : (
              <div className="text-center py-4">
                <button
                  className="button_style px-4"
                  type="submit"
                  disabled={
                    (totalDebitor === totalCreditor &&
                    totalDebitor !== 0 &&
                    totalCreditor !== 0
                      ? false
                      : true) || !voucherEntryPermission
                  }
                >
                  {message ? <BtnLoader /> : "Submit"}
                </button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default Voucher;
