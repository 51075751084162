import React, { useEffect, useLayoutEffect, useState } from "react";
import Filter from "../MultiCitySearchResult/FIlter";
import AirSial from "./AirSial";
import Typography from "@mui/material/Typography";
import Serene from "./Serene";
import Pia from "./pia";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncFlightsSearchResults,
  clearFlightResult,
  setExpandFlightDetails,
} from "../../../../redux/features/home/FlightSearchSlice";
import FlightSkeleton from "./FlightSkeleton";
import FlightsTab from "../../FlightsTab";
import moment from "moment";
import { isSereneSectors, isSialSectors } from "../../../../Utils/FlightSector";
import {
  asyncGetAddAirlinesData,
  asyncGetAirlinePricing,
} from "../../../../redux/features/setupRestPages/Price_AirLine_SuppRole";
import { asyncGetCurrencyData } from "../../../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { asyncGetAgentGroup } from "../../../../redux/features/setupRestPages/agentsSlice";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SelectedFlight from "./SelectedFlight";
import ChangeAvailabiltyDate from "./ChangeAvailabiltyDate";
import MultiAmadeus from "./multiAmadeus/MultiAmadeus";
import { asyncGetNewSupplier } from "../../../../redux/features/supplier/AddNewSupplier";
import Sidebar from "../OneWaySearchResult/Sidebar";
import Timer from "../../checkout/Timer";
import ChangeAvailabilityDate from "./ChangeAvailabiltyDate";

function MultiCitySearchResult() {
  const dispatch = useDispatch();
  const [accordion, setAccordion] = useState(null);

  //use selector redux
  const {
    searchValues,
    loading,
    serene,
    sial,
    pia,
    amadeus,
    newDepDate,
    newArrivalDate,
    selectedFlight,
    expandFlightDetail,
  } = useSelector((state) => state.FlightSearch);
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const { agentGroup } = useSelector((state) => state.agentsCategories);
  // get values from local storage
  const localsearchValues = JSON.parse(
    localStorage.getItem("flightSearchData")
  );

  //agent group
  const agent_grp = agentGroup?.find(
    (item) => item.agent_grp_id === localsearchValues?.agent?.agent_grp_id
  );
  // currency of the selected agent group
  const currency = currencyData?.find(
    (it) => it.currency_id === agent_grp?.currency_id
  );
  //set local storage
  localStorage.setItem(
    "currency",
    JSON.stringify(currency !== undefined ? currency : "")
  );
  //total travelor
  const totalTravelor =
    localsearchValues?.adultsCount +
    localsearchValues?.childCount +
    localsearchValues?.infantNo;

  let locDepCode = localsearchValues?.locationDep?.split("-")[0];
  let locArrCode = localsearchValues?.locationArrival?.split("-")[0];

  const gdsArray = localsearchValues?.gds?.filter((it) => {
    if (it === "Air sial" && isSialSectors(locDepCode, locArrCode)) {
      return "Air Sial"; // Filter out "Air Sial"
    } else if (it === "Serene" && isSereneSectors(locDepCode, locArrCode)) {
      return "Serene"; // Filter out "Serene"
    } else if (it === "HITIT") {
      return "HITIT";
    } else if (it === "Amadeus") {
      return "Amadeus";
    }
    // Keep all other items
    return false;
  });

  const setGds = ["Air sial", "Serene", "Air Blue", "HITIT", "Fly Jinnah", "Amadeus"].some(
    (airline) => gdsArray?.includes(airline)
  );

  const [duration, setDuration] = useState(setGds ? 5 : 10);
  const [cancel, setCancel] = useState(false);
  const [timer, setTimer] = useState("");
  useEffect(() => {
    if (timer?.minutes <= 0 && timer?.seconds <= 0) {
      setCancel(!cancel);
    }
  }, [timer]);

  // set origin and des name
  const getOriginDesName = (loc) => {
    let code = loc.split("-")[0];
    let airport = loc.split("-")[1];
    return `${code}-${airport}`;
  };

  const toggleDetail = (index) => {
    if (expandFlightDetail === index) {
      // If the clicked item is already expanded, collapse it
      dispatch(setExpandFlightDetails(null));
    } else {
      // Expand the clicked item
      dispatch(setExpandFlightDetails(index));
    }
  };

  const getCityName = (loc) => {
    let city = loc?.split("-")[2];
    return city;
  };

  //uselayout effect
  useLayoutEffect(() => {
    dispatch(asyncGetAgentGroup());
    dispatch(asyncGetAirlinePricing(3));
    dispatch(asyncGetCurrencyData());
    dispatch(asyncGetNewSupplier());
    dispatch(asyncGetAddAirlinesData());
  }, [dispatch, searchValues]);
  //use effect
  // flight search api call
  useEffect(() => {
    dispatch(clearFlightResult());
    dispatch(setExpandFlightDetails(null));
    setAccordion(false);

    gdsArray?.forEach((element) => {
      let data = {
        departingOn: localsearchValues?.departingOn,
        locationDep: getOriginDesName(localsearchValues?.locationDep),
        locationArrival: getOriginDesName(localsearchValues?.locationArrival),
        ReturningOn: localsearchValues?.ReturningOn,
        multi_des_from: localsearchValues?.multi_des_from,
        multi_des_to: localsearchValues?.multi_des_to,
        multi_des_from_date: localsearchValues?.multi_des_from_date,
        multi_des_ticket_class: localsearchValues?.multi_des_ticket_class,
        adultsCount: localsearchValues?.adultsCount,
        adult_ages: localsearchValues?.adult_ages,
        childCount: localsearchValues?.childCount,
        children_ages: localsearchValues?.children_ages,
        infantNo: localsearchValues?.infantNo,
        infant_ages: localsearchValues?.infant_ages,
        trip_type: localsearchValues?.trip_type,
        gds: element.replace(/\s/g, ""),
        ticket_class: localsearchValues?.ticket_class,
      };
      let tm = Date.now();
      localStorage.setItem("timer", JSON.stringify(tm));
      dispatch(asyncFlightsSearchResults(data));
    });
  }, [dispatch, searchValues, newDepDate, newArrivalDate, cancel]);


  const from = localsearchValues.locationDep?.split("-")?.at(0);
  const to = localsearchValues.locationArrival?.split("-")?.at(0);
  const date = moment(localsearchValues.departingOn, "DD-MM-YYYY").format("Do MMM");

  return (
    <div className="col-11 mx-auto py-5">
      <div className="pb-4">
        <div className="d-flex">
          {/* search engin *****************************/}
          <div className="col-12 bg-white shadow-sm">
            <Accordion
              expanded={accordion}
              onChange={(event, isExpanded) =>
                setAccordion(isExpanded ? true : false)
              }
            >
              <div
                className="accordion_heading"
                onClick={() => setAccordion(!accordion)}
              >
                <Typography>Edit Search</Typography>
                <ExpandMoreIcon />
              </div>
              <AccordionDetails className="px-5">
                <FlightsTab searchData={localsearchValues} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row mt-2 mt-md-5 gap-4">
          {/* filters *******************************/}
          <div className="search_engin_filter bg-white">
            {/* <Typography className="px-3 pt-2">Filters</Typography>
            <Filter Loading={loading} /> */}
            <Sidebar />
          </div>
          {/* search results */}
          <div className="search_data">
            <div className="py-3 d-flex justify-content-between align-items-top">
              <div className="w-50">
                <div>
                  <div className="fs-4 fw-bold mb-3">Select your Flights</div>

                  {/* Single Segment Flight */}
                  <div className="d-flex align-items-center mb-3">
                    <h6 className="fs-6 pe-3" style={{ width: "40%" }}>
                      {`${from} to ${to} : ${date}`}
                    </h6>
                    <div style={{ width: "60%" }}>
                      <ChangeAvailabilityDate
                        dates={localsearchValues?.departingOn}
                        index={""}
                        segmentType="single_segment"
                      />
                    </div>
                  </div>

                  {/* Multi-Destination Segments */}
                  {localsearchValues?.multi_des_from?.map((seg, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-3">
                      <h6 className="fs-6" style={{ width: "40%" }}>
                        {`${seg?.split("-")?.at(0)} to ${localsearchValues?.multi_des_to?.[idx]?.split("-")?.at(0)} : ${moment(localsearchValues?.multi_des_from_date?.[idx], "DD-MM-YYYY").format("Do MMM")}`}
                      </h6>
                      <div style={{ width: "60%" }}>
                        <ChangeAvailabilityDate
                          dates={localsearchValues?.multi_des_from_date?.[idx]}
                          index={idx}
                          segmentType="multi_des_from"
                        />
                      </div>
                    </div>
                  ))}

                  <div className="d-flex align-items-center gap-2 mt-3">
                    <h6 className="fs-6">{totalTravelor} Traveler(s)</h6>
                    <h6>
                      {`${localsearchValues?.adultsCount} ${localsearchValues?.adultsCount > 1 ? "Adults" : "Adult"}`}
                    </h6>
                    {localsearchValues?.childCount > 0 && (
                      <h6>
                        {`${localsearchValues?.childCount} ${localsearchValues?.childCount > 1 ? "Children" : "Child"}`}
                      </h6>
                    )}
                    {localsearchValues?.infantNo > 0 && (
                      <h6>
                        {`${localsearchValues?.infantNo} ${localsearchValues?.infantNo > 1 ? "Infants" : "Infant"}`}
                      </h6>
                    )}
                  </div>

                </div>
              </div>

              {/* ///////////// change availabilty date///////// */}
              <div className="w-25">
                <Timer
                  duration={duration}
                  availabilityTime={JSON.parse(localStorage.getItem("timer"))}
                  setTimer={setTimer}
                  timer={timer}
                />
              </div>
            </div>

            <div>
              {loading ? (
                <FlightSkeleton />
              ) : (
                amadeus?.outbound?.map((item, index) => (
                  <MultiAmadeus
                    amadeusItem={item}
                    key={index}
                    index={"Amadeus" + index}
                    expandFlightDetail={expandFlightDetail}
                    toggleDetail={toggleDetail}
                  />
                ))
              )}
            </div>

            <div
              className={`${selectedFlight?.logo !== "Serene" ? "order-1" : ""
                }`}
            >
              {serene === null && gdsArray?.includes("Serene") ? (
                <FlightSkeleton />
              ) : (
                serene?.routes[
                  serene?.routes[1] && selectedFlight ? 1 : 0
                ]?.flights?.map((item, index) => (
                  <Serene
                    serene={item}
                    key={index}
                    index={"serene" + index}
                    isExpanded={expandFlightDetail === "serene" + index}
                    toggleDetail={toggleDetail}
                  />
                ))
              )}
            </div>
            <div
              className={`${selectedFlight?.logo !== "Sial" ? "order-1" : ""}`}
            >
              {sial === null && gdsArray?.includes("Air sial") ? (
                <FlightSkeleton />
              ) : (
                (selectedFlight && sial?.inbound
                  ? sial?.inbound
                  : sial?.outbound
                )?.map((item, index) => (
                  <AirSial
                    data={item}
                    key={index}
                    index={"sial" + index}
                    isExpanded={expandFlightDetail === "sial" + index}
                    toggleDetail={toggleDetail}
                  />
                ))
              )}
            </div>

            <div
              className={`${selectedFlight?.logo !== "Pia" ? "order-1" : ""}`}
            >
              {pia === null && gdsArray?.includes("HITIT") ? (
                <FlightSkeleton />
              ) : (
                // pia?.length &&
                // [
                //   ...new Map(
                //     pia?.map((item) => [
                //       item?.fareComponentGroupList?.boundList[0]
                //         ?.availFlightSegmentList?.flightSegment?.flightNumber,
                //       item,
                //     ])
                //   ).values(),
                // ]

                pia?.map((piaData, index) => (
                  <Pia
                    piadata={piaData}
                    key={index}
                    index={index}
                    isExpanded={expandFlightDetail === index}
                    toggleDetail={toggleDetail}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiCitySearchResult;
