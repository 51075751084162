import React, { useEffect } from "react";
import AirSial from "../../../../assets/images/airlineLogo/PF.png";
import moment from "moment";

import {
  MdOutlineRestaurant,
  MdSwapHoriz,
  MdCheck,
  MdRemoveRedEye,
  MdOutlineFlightTakeoff,
  MdOutlineFlightLand,
} from "react-icons/md";
import { BsBagCheck, BsPerson, BsHandbag } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { useDispatch, useSelector } from "react-redux";
import { LightTooltip } from "./LightTooltip";
import {
  asyncPostSialPnr,
  setPnrData,
  set_Airline_filter_drp,
  set_All_Prices_Array,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { ExclusiveSetors } from "../../../../Utils/exclusiveSector";
import BtnLoader from "../../../AppForm/BtnLoader";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";

/////////////// Sial /////////////
function AirsialData({ data, index, isExpanded, toggleDetail, selectedFare }) {


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  const { selectedFlight, sialpnrData, pnrLoading } = useSelector(
    (state) => state.FlightSearch
  );

  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  //local storage data
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const user = JSON.parse(localStorage.getItem("loginUser"));

  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "PF"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "PF");

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  // calculate the lowest fare price
  const lowestFarePrice = (fare) => {
    let newFare =
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.ADULT?.BASIC_FARE)),
        Number(CurrencyExchange(fare?.ADULT?.TOTAL - fare?.ADULT?.BASIC_FARE)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.CHILD?.BASIC_FARE)),
        Number(CurrencyExchange(fare?.CHILD?.TOTAL - fare?.CHILD?.BASIC_FARE)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.INFANT?.BASIC_FARE)),
        Number(
          CurrencyExchange(fare?.INFANT?.TOTAL - fare?.INFANT?.BASIC_FARE)
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.infantNo || 0);

    return `${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
  };

  //discount calculation
  const DiscountPrice = (fare) => {
    let newDiscount =
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.ADULT?.BASIC_FARE)),
        Number(CurrencyExchange(fare?.ADULT?.TOTAL - fare?.ADULT?.BASIC_FARE)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.CHILD?.BASIC_FARE)),
        Number(CurrencyExchange(fare?.CHILD?.TOTAL - fare?.CHILD?.BASIC_FARE)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.INFANT?.BASIC_FARE)),
        Number(
          CurrencyExchange(fare?.INFANT?.TOTAL - fare?.INFANT?.BASIC_FARE)
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.infantNo || 0);

    if (newDiscount > 0) {
      return `Off ${CurrencyCode()} ${TwoDecimalPoint(newDiscount)}`;
    } else {
      return "";
    }
    //  return `Off ${CurrencyCode()} ${TwoDecimalPoint(newDiscount)}`;
  };

  // calculate the supplier fare price
  const supplierPrice = (fare) => {
    let newFare =
      fare?.ADULT?.TOTAL * searchValues?.adultsCount +
      fare?.CHILD?.TOTAL * searchValues?.childCount +
      fare?.INFANT?.TOTAL * searchValues?.infantNo;
    return TwoDecimalPoint(newFare);
  };
  //////////////// get single pax fare
  const singlePaxFare = (fare, pax) => {
    let newFare =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.BASIC_FARE)),
        Number(CurrencyExchange(fare?.TOTAL - fare?.BASIC_FARE)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * pax || 0;
    return `${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
  };

  const getPaxSupplierPrice = (amount, pax) => (pax > 0 ? amount : 0);
  //////////// convert time to required formate
  function formatDuration(inputDuration) {
    // Use a custom regular expression to extract hours and minutes
    const regex = /(\d+)h (\d+)m/;
    const match = inputDuration?.match(regex);

    if (match) {
      const hours = parseInt(match[1]);
      const minutes = parseInt(match[2]);

      // Calculate the duration using Moment.js
      const duration = moment.duration({ hours, minutes });

      // Format the duration as 'hh:mm'
      const formattedDuration = `${duration
        .hours()
        .toString()
        .padStart(2, "0")}:${duration.minutes().toString().padStart(2, "0")}`;

      return formattedDuration;
    } else {
      return "Invalid input format"; // Handle invalid input
    }
  }
  // get city name
  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };
  ///////////////////
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }
  /// USE EFFECT
  useEffect(() => {
    dispatch(set_Airline_filter_drp("PF"));
    dispatch(
      set_All_Prices_Array(
        lowestFarePrice(data?.BAGGAGE_FARE[0]?.FARE_PAX_WISE)?.split(" ")[1]
      )
    );
  }, [dispatch]);

  return (
    <>
      <div
        className="search_engin_result_box flight_card_shadow bg-white"
        id={`sial${index}`}
      >
        <div
          className="shadow cursorpointer"
          onClick={() => {
            toggleDetail(index);
            setTimeout(() => {
              !isExpanded && scrollToElement(`sial${index}`);
            }, 200);
          }}
        >
          <div className="row align-items-center">
            <div className="col-2">
              <div className="px-2">
                <div className="text-center">
                  <img
                    src={"/NEW_LOGO/PF.png"}
                    alt="airsial"
                    style={{ width: "100px", height: "80px" }}
                  />
                </div>
                <p className="text-center">{data?.FLIGHT_NO}</p>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightTakeoff
                      size={30}
                      className="color_primary"
                    />
                  </div>
                  <div>
                    <h6>
                      {moment(data?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                        "ddd DD-MMM-YY"
                      )}
                    </h6>
                    <h6 className="my-3">{data?.DEPARTURE_TIME}</h6>
                    <h6>
                      {data?.ORGN} - {getCity(locDeparture)}
                    </h6>
                  </div>
                </div>
                <div>
                  <h6 className="text-center">
                    {formatDuration(data?.DURATION)}
                  </h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">Non-Stop</h6>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightLand size={30} className="color_primary" />
                  </div>

                  <div>
                    <h6>
                      {moment(data?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                        "ddd DD-MMM-YY"
                      )}
                    </h6>
                    <h6 className="my-3">{data?.ARRIVAL_TIME}</h6>
                    <h6>
                      {data?.DEST} - {getCity(locArrival)}
                    </h6>
                  </div>
                </div>
              </div>{" "}
            </div>

            <div className="col-3">
              <div className="shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body">

                {selectedFare ? (
                  <HiEye className="color_primary" size={40} />
                ) : (
                  <>
                    <h6 className="text-danger">
                      {
                        DiscountPrice(selectedFare
                          ? selectedFlight?.fare?.FARE_PAX_WISE
                          : data?.BAGGAGE_FARE[0]?.FARE_PAX_WISE)
                      }
                    </h6>
                    <div className="d-flex align-items-end gap-3">

                      <h4>
                        {lowestFarePrice(
                          selectedFare
                            ? selectedFlight?.fare?.FARE_PAX_WISE
                            : data?.BAGGAGE_FARE[0]?.FARE_PAX_WISE
                        )}

                      </h4>
                      <img src={AirSial} alt="" width={40} height={40} />
                    </div>
                    {user?.agents_comp?.agent_grp_show ? (
                      <div className="fw-bold fs-5">{data?.agent_name}</div>
                    ) : null}

                    <button
                      onClick={() => toggleDetail(index)}
                      className="button_style w-75 my-3"
                      type="button"
                    >
                      <div>
                        <MenuOpenIcon className="me-1" />
                        Flight Detail
                      </div>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {isExpanded ? (
          <div className="p-3">
            <div className="search_engin_result_box py-3 bg-white h-auto">
              <div
                className={`d-flex ${selectedFare
                  ? "justify-content-start"
                  : "justify-content-between"
                  } align-items-start px-5`}
              >
                {/* headings */}
                <div className={`${selectedFare ? "me-5 pe-5" : ""}`}>
                  <div className="mb-4 pb-3"></div>
                  <div className="flight-detail-headings">
                    <BsHandbag size={25} className="color_primary" />
                    <span>Hand Carry Bags</span>
                  </div>
                  <div className="flight-detail-headings">
                    <BsBagCheck size={25} className="color_primary" />
                    <span>Checked Bags</span>
                  </div>
                  <div className="flight-detail-headings">
                    <MdOutlineRestaurant size={25} className="color_primary" />
                    <span>Meals</span>
                  </div>
                  <div className="flight-detail-headings">
                    <BsPerson size={25} className="color_primary" />
                    <span>Seat Selection</span>
                  </div>
                  <div className="flight-detail-headings">
                    <MdSwapHoriz size={25} className="color_primary" />
                    <span>Refund & Exchanges</span>
                  </div>
                </div>

                {data?.BAGGAGE_FARE?.filter((item) =>
                  selectedFare ? item.sub_class_desc === selectedFare : item
                )?.map((fare, index) => {

                  return (
                    <div key={index}>
                      <div className="fs-4 theme-text-color mb-3 fw-bold">
                        {fare?.sub_class_desc}
                      </div>
                      <div className="flight-detail-headings">
                        <MdCheck size={25} color="green" />
                        <span>Upto 7 kg</span>
                      </div>
                      <div className="flight-detail-headings">
                        <>
                          <MdCheck size={25} color="green" />
                          <span>
                            {fare?.weight} kg ({fare?.piece} Pcs)
                          </span>
                        </>
                      </div>
                      <div className="flight-detail-headings">
                        <MdCheck size={25} color="green" />
                        <span>Allowed</span>
                      </div>
                      <div className="flight-detail-headings">
                        <CgMenuGridR size={25} color="#38025c" />
                        <span>System Allocated</span>
                      </div>
                      {/* refund and exchange dialog  */}
                      <div className="flight-detail-headings">
                        <LightTooltip
                          title={
                            <div className="p-3">
                              <h4>Refund & Exchange</h4>
                              <h6>
                                Penalty will be charged per passenger segment.
                              </h6>
                              <div className="text-danger font-italic">
                                for further clarification contact with Airline
                                Or Call our support team.
                              </div>
                              <div className="text-danger font-italic mt-2">
                                After 25 days of departure ticket is
                                Non-Refundable.
                              </div>

                              <h6 className="mt-3">
                                contact with airline or call our support team
                              </h6>

                              {/* <div className="d-flex justify-content-between align-item-start w-fit mt-4">
                                <div className="me-5">
                                  <h4>Exchange</h4>
                                  <h6>
                                    Before 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      1500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      2500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 24 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      3500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 03 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      4500
                                    )}`}
                                  </h6>
                                </div>
                                <div>
                                  <h4>Refund</h4>
                                  <h6>
                                    Before 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      1500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 48 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      2500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 24 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      3500
                                    )}`}
                                  </h6>
                                  <h6>
                                    Within 03 Hours:{" "}
                                    {`${CurrencyCode()} ${CurrencyExchange(
                                      4500
                                    )}`}
                                  </h6>
                                </div>
                              </div> */}
                            </div>
                          }
                          placement="top-start"
                        >
                          <div style={{ cursor: "pointer" }}>
                            <MdRemoveRedEye size={25} color="#262829" />
                            <span>View</span>
                          </div>
                        </LightTooltip>
                      </div>
                      {/* ///////////// fare button and tooltip */}
                      {!selectedFare ? (
                        <div className="pt-3 px-2">
                          <LightTooltip
                            title={
                              <div className="p-3">
                                <div className="d-flex justify-content-between align-items-end">
                                  <div className="d-flex me-4">
                                    <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                    <h5>* {searchValues?.adultsCount}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {singlePaxFare(
                                      fare?.FARE_PAX_WISE?.ADULT,
                                      1
                                    )}
                                  </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end my-2">
                                  <div className="d-flex">
                                    <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                    <h5>* {searchValues?.childCount}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {singlePaxFare(
                                      fare?.FARE_PAX_WISE?.CHILD,
                                      searchValues?.childCount > 0 && 1
                                    )}
                                  </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end">
                                  <div className="d-flex">
                                    <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                    <h5>* {searchValues?.infantNo}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {singlePaxFare(
                                      fare?.FARE_PAX_WISE?.INFANT,
                                      searchValues?.infantNo > 0 && 1
                                    )}
                                  </h6>
                                </div>
                              </div>
                            }
                            placement="top"
                          >

                            <button
                              type="button"
                              className="button_style px-4"
                              onClick={() => {
                                dispatch(setPnrData(null));

                                let pnrBody = {
                                  isReturnFlight: false,
                                  origin_journey_code: data?.JOURNEY_CODE,
                                  origin_fare_type: fare?.sub_class_id,
                                  origin_class: data?.CLASS_CODE,
                                  origin_flight_code: data?.FLIGHT_NO,
                                  dest_journey_code: "",
                                  dest_fare_type: "",
                                  dest_class: "",
                                  dest_flight_code: "",
                                  origin: data?.ORGN,
                                  destination: data?.DEST,
                                  infant: searchValues?.infantNo,
                                  child: searchValues?.childCount,
                                  adults: searchValues?.adultsCount,
                                  agent_name: data?.agent_name,
                                };
                                dispatch(
                                  asyncPostSialPnr({
                                    body: pnrBody,
                                    navigate: navigate,
                                  })
                                );
                                dispatch(
                                  setselectedFlight({
                                    flight: data,
                                    fare: fare,
                                    price: lowestFarePrice(fare?.FARE_PAX_WISE),
                                    ttl_agent_fare: TwoDecimalPoint(
                                      CurrencyExchangeToPKR(
                                        Number(
                                          lowestFarePrice(fare?.FARE_PAX_WISE)
                                            ?.split(" ")
                                            ?.at(1) || 0
                                        )
                                      )
                                    ),
                                    ttlagent_curr_fare: Number(
                                      lowestFarePrice(fare?.FARE_PAX_WISE)
                                        ?.split(" ")
                                        ?.at(1) || 0
                                    ),
                                    ttl_supplier_fare: supplierPrice(
                                      fare?.FARE_PAX_WISE
                                    ),
                                    ttlsup_curr_fare: TwoDecimalPoint(
                                      CurrencyExchange(
                                        supplierPrice(fare?.FARE_PAX_WISE)
                                      )
                                    ),

                                    adultBaseFare: getPaxSupplierPrice(
                                      fare?.FARE_PAX_WISE?.ADULT?.BASIC_FARE,
                                      searchValues?.adultsCount
                                    ),
                                    adlt_ttl_d_curr: CurrencyExchangeToPKR(
                                      Number(
                                        singlePaxFare(
                                          fare?.FARE_PAX_WISE?.ADULT,
                                          1
                                        )
                                          ?.split(" ")
                                          ?.at(1)
                                      )
                                    ),
                                    chld_ttl_d_curr: CurrencyExchangeToPKR(
                                      Number(
                                        singlePaxFare(
                                          fare?.FARE_PAX_WISE?.CHILD,
                                          1
                                        )
                                          ?.split(" ")
                                          ?.at(1)
                                      )
                                    ),
                                    inft_ttl_d_curr: CurrencyExchangeToPKR(
                                      Number(
                                        singlePaxFare(
                                          fare?.FARE_PAX_WISE?.INFANT,
                                          1
                                        )
                                          ?.split(" ")
                                          ?.at(1)
                                      )
                                    ),
                                    adultTaxes:
                                      fare?.FARE_PAX_WISE?.ADULT?.TOTAL -
                                      fare?.FARE_PAX_WISE?.ADULT?.BASIC_FARE,
                                    ADLT_TAXES: [
                                      {
                                        code: "XT",
                                        amount: fare?.FARE_PAX_WISE?.ADULT?.TAX,
                                      },
                                      {
                                        code: "XP",
                                        amount:
                                          fare?.FARE_PAX_WISE?.ADULT?.SURCHARGE,
                                      },
                                      {
                                        code: "XP",
                                        amount:
                                          fare?.FARE_PAX_WISE?.ADULT?.FEES,
                                      },
                                    ],
                                    CHLD_TAXES: searchValues?.childCount === 0 ? [] : [
                                      {
                                        code: "XT",
                                        amount: fare?.FARE_PAX_WISE?.CHILD?.TAX,
                                      },
                                      {
                                        code: "XP",
                                        amount:
                                          fare?.FARE_PAX_WISE?.CHILD?.SURCHARGE,
                                      },
                                      {
                                        code: "XP",
                                        amount:
                                          fare?.FARE_PAX_WISE?.CHILD?.FEES,
                                      },
                                    ],
                                    INFT_TAXES: searchValues?.INFANT === 0 ? [] : [
                                      {
                                        code: "XT",
                                        amount:
                                          fare?.FARE_PAX_WISE?.INFANT?.TAX,
                                      },
                                      {
                                        code: "XP",
                                        amount:
                                          fare?.FARE_PAX_WISE?.INFANT
                                            ?.SURCHARGE,
                                      },
                                      {
                                        code: "XP",
                                        amount:
                                          fare?.FARE_PAX_WISE?.INFANT?.FEES,
                                      },
                                    ],
                                    childBaseFare: getPaxSupplierPrice(
                                      fare?.FARE_PAX_WISE?.CHILD?.BASIC_FARE,
                                      searchValues?.childCount
                                    ),
                                    childTaxes: getPaxSupplierPrice(
                                      fare?.FARE_PAX_WISE?.CHILD?.TOTAL -
                                      fare?.FARE_PAX_WISE?.CHILD?.BASIC_FARE,
                                      searchValues?.childCount
                                    ),
                                    infantBaseFare: getPaxSupplierPrice(
                                      fare?.FARE_PAX_WISE?.INFANT?.BASIC_FARE,
                                      searchValues?.infantNo
                                    ),
                                    infantTaxes: getPaxSupplierPrice(
                                      fare?.FARE_PAX_WISE?.INFANT?.TOTAL -
                                      fare?.FARE_PAX_WISE?.INFANT?.BASIC_FARE,
                                      searchValues?.infantNo
                                    ),

                                    baggageName: fare?.sub_class_desc,
                                    flightNumber: data?.FLIGHT_NO,
                                    depDate: moment(
                                      data?.DEPARTURE_DATE,
                                      "DD,MM,YYYY"
                                    ).format("DD-MMM-YY"),
                                    depTime: data?.DEPARTURE_TIME,
                                    arrDate: moment(
                                      data?.ARRIVAL_UTC?.split(" ")[0],
                                      "DD,MM,YYYY"
                                    ).format("DD-MMM-YY"),
                                    arrTime: data?.ARRIVAL_TIME,
                                    flightDuration: formatDuration(
                                      data?.DURATION
                                    ),
                                    logo: "Sial",
                                    code: "PF",
                                    booking: {
                                      gds: "AIRSIAL", // should be gds code
                                      pnr: sialpnrData?.Data, // returned in pricing response
                                      agent_name: data?.agent_name,

                                      segments: [
                                        {
                                          departure_date: moment(
                                            data?.DEPARTURE_DATE,
                                            "DD,MM,YYYY"
                                          ).format("YYYY-MM-DD"),
                                          baggage: `${fare?.weight} KG (${fare?.piece} Pcs)`,
                                          departure_time: data?.DEPARTURE_TIME,
                                          arrival_date: moment(
                                            data?.ARRIVAL_UTC?.split(" ")[0],
                                            "DD,MM,YYYY"
                                          ).format("YYYY-MM-DD"),
                                          arrival_time: data?.ARRIVAL_TIME,
                                          origin_terminal: "",
                                          desti_terminal: "",
                                          flight_number:
                                            data?.FLIGHT_NO?.split("F")?.at(1),
                                          number_in_party: "",
                                          res_book_desig_code: "",
                                          status: "NN",
                                          des_location:
                                            searchValues?.locationArrival,
                                          dest_code: data?.DEST,
                                          marketing_airline: "PF",
                                          orig_location:
                                            searchValues?.locationDep,
                                          orig_code: data?.ORGN,
                                          marriage_grp: "",
                                          cabin_class:
                                            searchValues?.ticket_class,
                                          segment_type: getSectorsName(
                                            locDeparture,
                                            locArrival
                                          ), // dom | siti | soto | group
                                        },
                                      ],
                                      tp_ur_code: "",
                                      platting_carrier: "PF",
                                      tp_ssr_verssion: 0,
                                      tp_trace_id: "",
                                    },
                                  })
                                );
                              }}
                            >
                              {pnrLoading ? (
                                <BtnLoader />
                              ) : (
                                lowestFarePrice(fare?.FARE_PAX_WISE)
                              )}
                            </button>
                          </LightTooltip>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default AirsialData;
