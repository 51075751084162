import React, { useState } from "react";
import AmadeusReturnCard from "./AmadeusReturnCard";
import ConnectedFlight from "./ConnectedFlight";
import moment from "moment";
import {
  asyncAmadeusBrandFare,
  setBrandFareError,
  setExpandFlightDetails,
  setpricingErrorNull,
} from "../../../../../redux/features/home/FlightSearchSlice";
import { useDispatch, useSelector } from "react-redux";
import BrandFareSkeleton from "./BrandFareSkeleton";
import AmadeusRetunBrandedFare from "./AmadeusRetunBrandedFare";
import AmadeusRetrunNoBrandFare from "./AmadeusRetrunNoBrandFare";
import {
  CurrencyExchange,
  CurrencyCode,
} from "../../../../../Utils/currencyExchange";
import { getSectorsName } from "../../../../../Utils/FlightSector";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AmadeusLogo from "../../../../../assets/images/airlineLogo/amadeusRounded.png";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import {
  codeShareAmadeus,
  ExclusiveSetors,
} from "../../../../../Utils/exclusiveSector";
import { pricingairline } from "../../../../../Utils/airlinePricing";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";

const AmadeusReturn = ({
  amadeusItem,
  selectedFlight,
  index,
  toggleDetail,
  isExpanded,
  setShowOtherOption,
  showOtherOption,
}) => {
  const dispatch = useDispatch();
  const { amadeusBrandFare, amadeusloading, brandFareError } = useSelector(
    (state) => state.FlightSearch
  );

  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha ===
      amadeusItem?.validatingAirlineCodes?.at(0)
  );

  //airline
  const airline = addAirLinesData?.find(
    (it) =>
      it.code_alpha === amadeusItem?.validatingAirlineCodes?.at(0)
  );

  const fligthSegment = amadeusItem?.itineraries?.flatMap(({ segments }) => [
    ...segments,
  ]);

  const calculatePassangerTotal = (fare) => {
    const price = pricingairline(
      airline,
      pricing,
      CurrencyExchange(Number(fare?.price?.base)),
      CurrencyExchange(Number(fare?.price?.total) - Number(fare?.price?.base)),
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        fare?.fareDetailsBySegment[0]?.class
      ),
      codeShareAmadeus(fligthSegment),
      fare?.fareDetailsBySegment[0]?.cabin === "ECONOMY",
      fligthSegment?.length,
      fare?.travelerType !== "HELD_INFANT" 
    );
    return price;
  };

  // calculate the total fare price
  const totalFarePrice = (fare) => {
    let total = 0;
    fare?.travelerPricings.forEach((travler) => {
      let travelerFare = calculatePassangerTotal(travler);
      total += travelerFare?.total_fare;
    })
    return TwoDecimalPoint(total);
  };

  // calculate the totalDiscount
  const totalDiscount = (fare) => {
    let total = 0;
    fare?.travelerPricings.forEach((traveler) => {
      let travelerFare = calculatePassangerTotal(traveler);
      total += travelerFare?.sp;
    });
    if (total > 0) {
      return `OFF ${CurrencyCode()} ${TwoDecimalPoint(total)}`;
    }
  };

  const singPaxFareTotal = (fare, pax, travelerType) => {
    const travelerPricing = fare?.travelerPricings?.find(
      (tp) => tp?.travelerType === travelerType
    );
    if (!travelerPricing) return 0;

    const baseFare = Number(CurrencyExchange(travelerPricing?.price?.base));
    const tax = Number(
      CurrencyExchange(
        travelerPricing?.price?.total - travelerPricing?.price?.base
      )
    );
    const totalFare = ~~pricingairline(
      airline,
      pricing,
      baseFare,
      tax,
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        travelerPricing?.fareDetailsBySegment[0]?.class
      ),
      codeShareAmadeus(fligthSegment),
      fare?.travelerPricings[0]?.fareDetailsBySegment[0]?.cabin === "ECONOMY",
      fligthSegment?.length,
      travelerPricing?.travelerType !== "HELD_INFANT"
    )?.total_fare;

    return TwoDecimalPoint(totalFare * pax);
  };

  const singPaxTotalFare = (fare, travelerType) => {
    const travelerPricing = fare?.travelerPricings?.find(
      (tp) => tp?.travelerType === travelerType
    );
    if (!travelerPricing) return 0;

    const baseFare = Number(CurrencyExchange(travelerPricing?.price?.base));
    const tax = Number(
      CurrencyExchange(
        travelerPricing?.price?.total - travelerPricing?.price?.base
      )
    );
    const totalFare = ~~pricingairline(
      airline,
      pricing,
      baseFare,
      tax,
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        travelerPricing?.fareDetailsBySegment[0]?.class
      ),
      codeShareAmadeus(fligthSegment),
      fare?.travelerPricings[0]?.fareDetailsBySegment[0]?.cabin === "ECONOMY",
      fligthSegment?.length,
      travelerPricing?.travelerType !== "HELD_INFANT"
    )?.total_fare;

    return TwoDecimalPoint(totalFare);
  };

  const [selectedTab, setSelectedTab] = useState("Fare Options");
  // calculate duration
  const timeDuration = (time1, time2) => {
    if (!time1 || !time2) {
      return null;
    }
    const depMoment = moment(time1);
    const arrMoment = moment(time2);
    const duration = moment.duration(arrMoment.diff(depMoment));
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };
  /////////////////// scroll to show detail
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }

  const handleBrandFare = (amadeusItem) => {
    const { agent_name } = amadeusItem;
    dispatch(
      asyncAmadeusBrandFare({
        agent_name: agent_name,
        flightOffers: amadeusItem,
      })
    );
    dispatch(setBrandFareError(null));
  };

  return (
    <div className="search_engin_result_box flight_card_shadow bg-white">
      <div
        className="shadow cursorpointer d-flex justify-content-center align-items-center"
        onClick={() => {
          toggleDetail(index);
          dispatch(setpricingErrorNull(null));
          if (!isExpanded && !selectedFlight) {
            handleBrandFare(amadeusItem);
          }
        }}
      >
        <div className="col-9">
          {amadeusItem?.itineraries?.map((itinery, index, array) => {
            return (
              <div
                key={index}
                style={{
                  borderBottom:
                    index === array?.length - 1
                      ? "none"
                      : "1px solid lightgray",
                }}
              >
                <AmadeusReturnCard
                  totalDiscount={totalDiscount}
                  timeDuration={timeDuration}
                  segments={itinery?.segments}
                  amadeusItem={amadeusItem}
                  index={index}
                  scrollToElement={scrollToElement}
                  showOtherOption={showOtherOption}
                  setShowOtherOption={setShowOtherOption}
                  isExpanded={isExpanded}
                  toggleDetail={toggleDetail}
                  totalFarePrice={totalFarePrice}
                  selectedFlight={selectedFlight}
                />
              </div>
            );
          })}
        </div>

        <div className="col-3 left-shadow text-center align-self-stretch pt-5">
          <div
            className={`d-flex flex-column align-items-center justify-content-center result_box_body pt-4`}
          >
            <>
              <div className="d-flex align-items-center gap-3">
                <div className="h4">
                  <h6 className=" text-danger">{totalDiscount(amadeusItem)}</h6>
                  {`${CurrencyCode()} ${totalFarePrice(amadeusItem)}`}
                </div>
                <img
                  src={AmadeusLogo}
                  alt=""
                  width={40}
                  height={40}
                  className="mb-2"
                />
              </div>
              <button
                onClick={(e) => {
                  toggleDetail(index);
                }}
                className="button_style w-75 mb-2 cursorpointer mt-2"
                type="button"
              >
                <div>
                  <MenuOpenIcon className="me-1" />
                  Flight Detail
                </div>
              </button>
            </>

            {!selectedFlight && (
              <>
                <>
                  {amadeusItem?.otherOptions?.length > 0 && (
                    <button
                      className="bg-white color_primary border_primary p-2 px-4 fs-5 rounded-2 cursorpointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setExpandFlightDetails(null));
                        setShowOtherOption(!showOtherOption);
                      }}
                    >
                      <div>
                        <UnfoldMoreIcon className="me-1" />
                        {showOtherOption
                          ? "Hide Options"
                          : `${amadeusItem?.otherOptions?.length} More Options`}
                      </div>
                    </button>
                  )}
                </>
              </>
            )}
          </div>
        </div>
      </div>

      {isExpanded && (
        <>
          {amadeusloading ? (
            <BrandFareSkeleton />
          ) : (
            <div className="p-3">
              <div className="d-flex mb-4 mt-2 flight_details_tabs">
                {["Fare Options", "Flight Details"].map((item, index) => {
                  const active = selectedTab === item;
                  return (
                    <button
                      key={index}
                      onClick={() => setSelectedTab(item)}
                      className={active ? "active" : ""}
                    >
                      {item}
                    </button>
                  );
                })}
              </div>

              <div>
                {selectedTab === "Flight Details" && (
                  <div className="row rounded-2 search_engin_result_box bg-white p-4">
                    {amadeusItem?.itineraries?.map((itinery, index) => {
                      return (
                        <div
                          key={index}
                          className={`${index === 0 ? "border-bottom pb-4" : "pt-4"
                            }`}
                        >
                          {itinery?.segments?.map((segment, idx, array) => {
                            return (
                              <div key={index}>
                                <ConnectedFlight
                                  timeDuration={timeDuration}
                                  amadeusItem={segment}
                                  inx={idx}
                                  flightSegment={array}
                                  segment="return"
                                />
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <div>
                {selectedTab === "Fare Options" && (
                  <div className="row rounded-2 search_engin_result_box bg-white p-4">
                    {brandFareError?.response?.data?.message ===
                      "UNABLE TO RETRIEVE UPSELL OFFER" ||
                      brandFareError?.response?.data?.message ===
                      "SYSTEM ERROR HAS OCCURRED" ? (
                      <>
                        <AmadeusRetrunNoBrandFare
                          amadeusItem={amadeusItem}
                          selectedFlight={selectedFlight}
                          totalFarePrice={totalFarePrice}
                          pricing={pricing}
                          airline={airline}
                          singPaxFareTotal={singPaxFareTotal}
                          singPaxTotalFare={singPaxTotalFare}
                          timeDuration={timeDuration}
                          isCodeShare={codeShareAmadeus(fligthSegment)}
                          isEconomy={amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.cabin === "ECONOMY"}
                          segmentLength={fligthSegment?.length}
                          isExclusiveSector={ExclusiveSetors(searchValues, pricing, amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.class)}
                        />
                      </>
                    ) : (
                      <>
                        <AmadeusRetunBrandedFare
                          amadeusBrandFare={
                            amadeusBrandFare &&
                            [...amadeusBrandFare?.data]?.reverse()
                          }
                          totalDiscount={totalDiscount}
                          amadeusItem={amadeusItem}
                          selectedFlight={selectedFlight}
                          totalFarePrice={totalFarePrice}
                          pricing={pricing}
                          airline={airline}
                          singPaxFareTotal={singPaxFareTotal}
                          singPaxTotalFare={singPaxTotalFare}
                          timeDuration={timeDuration}
                          isCodeShare={codeShareAmadeus(fligthSegment)}
                          isEconomy={amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.cabin === "ECONOMY"}
                          segmentLength={fligthSegment?.length}
                          isExclusiveSector={ExclusiveSetors(searchValues, pricing, amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.class)}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AmadeusReturn;
