import React, { useState, useEffect, useLayoutEffect } from "react";
import MUIDataTable from "mui-datatables";
import * as yup from "yup";
import BtnLoader from "../AppForm/BtnLoader";
import { FormControlLabel, Switch, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAgentGroup } from "../../redux/features/setupRestPages/agentsSlice";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import {
  asyncDeleteAirlinePricing,
  asyncEditAirlinePricing,
  asyncGetAddAirlinesData,
  asyncGetAirlinePricing,
  asyncPostAirlinePricing,
  asyncUpdateAirlinePricing,
  editAirlinePricing_Null,
} from "../../redux/features/setupRestPages/Price_AirLine_SuppRole";
import DeleteModal from "../DeleteModal";
import { FormHelperText } from "@mui/material";
import { asyncGetNewAgencyReg } from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import ExclusiveRegion from "./Pricing/ExclusiveRegion";
import { menuProps, optionArray, options, typeArray } from "./Pricing/helper";
import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";
import { CgLayoutGrid } from "react-icons/cg";

const Pricing = () => {
  const dispatch = useDispatch();

  // useSelector from redux
  const { agentGroup } = useSelector((state) => state?.agentsCategories);
  const { addAirLinesData, airlinePricing, loading, editPricingData } =
    useSelector((state) => state?.price_airlines_suppRoleSlice);
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  const { newAgencyRegData } = useSelector((state) => state.newAgency);
  //  use states react

  const [journeyType, setJourneyType] = useState(1);
  const [ShowModel, setShowModel] = useState(false);
  const [pricingId, setPricingId] = useState();
  const [serviceType, setServiceType] = useState("");

  const [existingAirline, setExistingAirline] = useState(false);
  const [existingService, setExistingService] = useState(false);
  const [agentGrpId, setAgentGroupId] = useState();
  const [agentGrpCurr, setAgentGrpCurr] = useState("");
  const [loginUserId, setLoginUserId] = useState(1);
  const [tabs, setTabs] = useState("");

  //agent company of login user
  const agentCompany = newAgencyRegData?.find(
    (agent) => agent.agent_id === user?.agent_id
  );
  //  buyer id, table data,
  const buyerId = agentGroup?.at(0)?.agent_grp_id;
  const agentGroupPricing = airlinePricing?.filter((it) =>
    agentGrpId !== undefined
      ? it?.bg_id === agentGrpId
      : it?.bg_id === agentCompany?.agent_grp_id
  );
  const airlineIntlwht = addAirLinesData?.find(
    (it) => it?.arr_id === editPricingData?.arr_id
  );
  const bgCurrCode = agentGroup?.at(0)?.currency?.curr_code;

  // sort arrays airline
  const sortedData = addAirLinesData
    ?.slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (agentGroup?.length > 0 && !tabs) {
      setTabs(agentGroup[0]?.agent_grp_name);
    }
  }, [agentGroup]);

  //use Layout effect
  useLayoutEffect(() => {
    dispatch(asyncGetAgentGroup());
    dispatch(asyncGetNewAgencyReg());
  }, [dispatch]);
  // use Effect React
  useEffect(() => {
    dispatch(asyncGetAgentGroup());
    dispatch(asyncGetAddAirlinesData());
    dispatch(asyncGetAirlinePricing());
    setServiceType("");
  }, [dispatch, editPricingData]);

  useEffect(() => {
    setLoginUserId(user?.user_id);
  }, [user]);

  const handleServices = () => {
    let data = {
      service_type: serviceType,
      bg_id: agentGrpId ? agentGrpId : buyerId,
      journey_id: journeyType
    };
    dispatch(asyncPostAirlinePricing(data));
    setServiceType("");
  };

  //if the user has permission to Add new Price
  const PostPermissions = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
      (it) =>
        it?.user_id === user?.user_id &&
        it?.page_level.permission_url === "/post-pricing"
    )
    : true;
  // if the login user has permissions to edit the price
  const editPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
      (it) =>
        it?.user_id === user?.user_id &&
        it?.page_level.permission_url === "/update-pricing"
    )
    : true;
  // if the user has permission to delete the pricing
  const deletePermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
      (it) =>
        it?.user_id === user?.user_id &&
        it?.page_level.permission_url === "/delete-pricing"
    )
    : true;

  // table columns
  const columns = [
    {
      // index:0
      name: "bg_id",
      label: "agent_id",
      options: {
        display: false,
      },
    },
    {
      // index:1
      name: "pricing_id",
      label: "Pricing ID",
      options: {
        display: false,
      },
    },
    {
      // index:2
      name: "com_intl_type",
      label: "com intl type",
      options: {
        display: false,
      },
    },
    {
      // index:3
      name: "com_dom_type",
      label: "",
      options: {
        display: false,
      },
    },
    {
      // index:4
      name: "wop_type",
      label: "",
      options: {
        display: false,
      },
    },
    {
      // index:5
      name: "com_soto_type",
      label: "",
      options: {
        display: false,
      },
    },
    {
      // index:6
      name: "service_type",
      label: "Services",
    },
    {
      // index:7
      name: "arr_id",
      label: "Airline",
      options: {
        customBodyRender: (value, tableMeta) => {
          let airline = addAirLinesData?.filter((d) => d.arr_id === value);
          return <span>{airline?.at(0)?.name}</span>;
        },
      },
    },
    {
      // index:8
      name: "com_intl_eco",
      label: "Intl Eco PSF/Disc",
      options: {
        customBodyRender: (value, tableMeta) => {
          let curr = agentGrpCurr.length !== 0 ? agentGrpCurr : bgCurrCode;
          let lb = tableMeta?.rowData[9];
          let am = tableMeta?.rowData[2] ? curr : "%";
          return tableMeta.rowData[7] ? (
            <span>
              {lb === "Net Net"
                ? lb + " / " + value
                : lb + " / " + value + " " + am}
            </span>
          ) : (
            ""
          );
        },
      },
    },
    {
      // index  9
      name: "com_intl_option_eco",
      options: {
        display: false,
      },
    },
    {
      // index: 10
      name: "com_intl_b_f",
      label: "Intl B/F PSF/Disc",
      options: {
        customBodyRender: (value, tableMeta) => {
          let curr = agentGrpCurr.length !== 0 ? agentGrpCurr : bgCurrCode;
          let lb = tableMeta?.rowData[11];
          let am = tableMeta?.rowData[2] ? curr : "%";
          return tableMeta.rowData[7] ? (
            <span>
              {lb === "Net Net"
                ? lb + " / " + value
                : lb + " / " + value + " " + am}
            </span>
          ) : (
            ""
          );
        },
      },
    },
    {
      // index  11
      name: "com_intl_option_b_f",
      options: {
        display: false,
      },
    },
    {
      // index:12
      name: "com_dom_eco",
      label: "Dom Eco PSF/Disc",
      options: {
        customBodyRender: (value, tableMeta) => {
          let curr = agentGrpCurr.length !== 0 ? agentGrpCurr : bgCurrCode;
          let lb = tableMeta?.rowData[13];
          let am = tableMeta?.rowData[3] ? curr : "%";
          return (
            tableMeta.rowData[7] && (
              <span>
                {lb === "Net Net"
                  ? lb + " / " + value
                  : lb + " / " + value + " " + am}
              </span>
            )
          );
        },
      },
    },
    {
      // index  13
      name: "com_dom_option_eco",
      options: {
        display: false,
      },
    },
    {
      // index:14
      name: "com_dom_b_f",
      label: "Dom B/F PSF/Disc",
      options: {
        customBodyRender: (value, tableMeta) => {
          let curr = agentGrpCurr.length !== 0 ? agentGrpCurr : bgCurrCode;
          let lb = tableMeta?.rowData[15];
          let am = tableMeta?.rowData[3] ? curr : "%";
          return (
            tableMeta.rowData[7] && (
              <span>
                {lb === "Net Net"
                  ? lb + " / " + value
                  : lb + " / " + value + " " + am}
              </span>
            )
          );
        },
      },
    },
    {
      // index  15
      name: "com_dom_option_b_f",
      options: {
        display: false,
      },
    },
    {
      // index:16
      name: "com_soto_eco",
      label: "SOTO Eco PSF/Disc",
      options: {
        customBodyRender: (value, tableMeta) => {
          let curr = agentGrpCurr.length !== 0 ? agentGrpCurr : bgCurrCode;
          let lb = tableMeta?.rowData[17];
          let am = tableMeta?.rowData[5] ? curr : "%";
          return (
            tableMeta.rowData[7] && (
              <span>
                {lb === "Net Net"
                  ? lb + " / " + value
                  : lb + " / " + value + " " + am}
              </span>
            )
          );
        },
      },
    },
    {
      // index  17
      name: "com_soto_option_eco",
      options: {
        display: false,
      },
    },
    {
      // index:18
      name: "com_soto_b_f",
      label: "SOTO B/F PSF/Disc",
      options: {
        customBodyRender: (value, tableMeta) => {
          let curr = agentGrpCurr.length !== 0 ? agentGrpCurr : bgCurrCode;
          let lb = tableMeta?.rowData[19];
          let am = tableMeta?.rowData[5] ? curr : "%";
          return (
            tableMeta.rowData[7] && (
              <span>
                {lb === "Net Net"
                  ? lb + " / " + value
                  : lb + " / " + value + " " + am}
              </span>
            )
          );
        },
      },
    },
    {
      // index  19
      name: "com_soto_option_b_f",
      options: {
        display: false,
      },
    },
    {
      // index:20
      name: "is_segment_wise",
      label: "Subsidary",
      options: {
        customBodyRender: (value, tableMeta) => {
          let amount = tableMeta?.rowData[22];
          let lb = tableMeta?.rowData[21];
          const journey = Number(value) === 0 ? "Journey" : "Segment"
          return (
            tableMeta.rowData[7] && (
              <span>
                {lb === "Net Net"
                  ? lb + " / " + value
                  : journey + " / " + lb + " / " + amount}
              </span>
            )
          );
        },
      },
    },
    {
      // index  21
      name: "subsidary_option",
      options: {
        display: false,
      },
    },
    {
      // index  22
      name: "subsidary_amount",
      options: {
        display: false,
      },
    },
    {
      // index:16
      name: "wht",
      label: "WHT",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowData[7] && (value === 0 ? value : value + " %");
        },
      },
    },
    {
      // index:17
      name: "gst",
      label: "GST",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowData[7] && (value === 0 ? value : value + " %");
        },
      },
    },
    // {
    //   // index:18
    //   name: "pax_type",
    //   label: "Pax Type",
    // },
    {
      // index:19
      name: "void",
      label: "Void",
      options: {
        customBodyRender: (value, tableMeta) => {
          let curr = agentGrpCurr.length > 0 ? agentGrpCurr : bgCurrCode;
          return (
            tableMeta.rowData[7] && (value === 0 ? value : value + " " + curr)
          );
        },
      },
    },
    {
      // index:20
      name: "refund",
      label: "Refund",
      options: {
        customBodyRender: (value, tableMeta) => {
          let curr = agentGrpCurr.length > 0 ? agentGrpCurr : bgCurrCode;
          return (
            tableMeta.rowData[7] && (value === 0 ? value : value + " " + curr)
          );
        },
      },
    },
    {
      // index:21
      label: "Actions",
      name: "actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const pricing_id = tableMeta?.rowData[1];
          return (
            <div className="d-flex">
              {(editPermission !== undefined || user?.user_id === 1) &&
                tableMeta?.rowData[6] === "Flight" ? (
                <button
                  className="btn-success rounded edit-delete-icon-button me-1"
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                    dispatch(asyncEditAirlinePricing(pricing_id));
                  }}
                >
                  <i className="fa fa-thin fa-pencil"></i>
                </button>
              ) : null}
              {deletePermission !== undefined || user?.user_id === 1 ? (
                <button
                  className="btn-danger rounded edit-delete-icon-button "
                  onClick={() => {
                    setShowModel(true);
                    setPricingId(pricing_id);
                  }}
                >
                  <i className="fa fa-thin fa-trash"></i>
                </button>
              ) : null}
            </div>
          );
        },
      },
    },
  ];
  // initial values for formic
  const initialValues = {
    airline_Item: "",
    journey_id: editPricingData?.journey_id || journeyType,
    pricing_id: editPricingData?.pricing_id || "",
    bg_id: editPricingData?.bg_id || "", // buyer group id or agent group id
    arr_id: editPricingData?.arr_id || "", // airline id
    service_type: editPricingData?.service_type || "",

    wht: editPricingData?.wht || "",
    gst: editPricingData?.gst || "",
    pax_type: editPricingData?.pax_type || "Passenger",
    void: editPricingData?.void || "",
    refund: editPricingData?.refund || "",


    com_intl_type: editPricingData?.com_intl_type || false, // boolean
    com_intl_eco: editPricingData?.com_intl_eco || 0.0,
    com_intl_option_eco: editPricingData?.com_intl_option_eco || "Net Net",
    com_intl_b_f: editPricingData?.com_intl_b_f || 0.0,
    com_intl_option_b_f: editPricingData?.com_intl_option_b_f || "Net Net",
    code_share_intl: editPricingData?.code_share_intl || false,
    codeshare_com_intl_eco: editPricingData?.codeshare_com_intl_eco || 0.0,
    codeshare_com_intl_option_eco: editPricingData?.codeshare_com_intl_option_eco || "Net Net",
    codeshare_com_intl_b_f: editPricingData?.codeshare_com_intl_b_f || 0.0,
    codeshare_com_intl_option_b_f: editPricingData?.codeshare_com_intl_option_b_f || "Net Net",

    com_dom_type: editPricingData?.com_dom_type || false, //boolean
    com_dom_eco: editPricingData?.com_dom_eco || 0, //commition domestic
    com_dom_option_eco: editPricingData?.com_dom_option_eco || "Net Net",
    com_dom_b_f: editPricingData?.com_dom_b_f || 0, //commition domestic
    com_dom_option_b_f: editPricingData?.com_dom_option_b_f || "Net Net",

    code_share_soto: editPricingData?.code_share_soto || false, // boolean
    com_soto_type: editPricingData?.com_soto_type || false, // boolean
    com_soto_eco: editPricingData?.com_soto_eco || 0.0,
    com_soto_option_eco: editPricingData?.com_soto_option_eco || "Net Net",
    com_soto_b_f: editPricingData?.com_soto_b_f || 0.0,
    com_soto_option_b_f: editPricingData?.com_soto_option_b_f || "Net Net",
    codeshare_com_soto_eco: editPricingData?.codeshare_com_soto_eco || 0.0,
    codeshare_com_soto_option_eco: editPricingData?.codeshare_com_soto_option_eco || "Net Net",
    codeshare_com_soto_b_f: editPricingData?.codeshare_com_soto_b_f || 0.0,
    codeshare_com_soto_option_b_f: editPricingData?.codeshare_com_soto_option_b_f || "Net Net",

    is_segment_wise: editPricingData?.is_segment_wise || false, //boolean
    subsidary_amount: editPricingData?.subsidary_amount || 0, //commition domestic
    subsidary_option: editPricingData?.subsidary_option || "Net Net",


    from: editPricingData?.ApiPricingSectorModels?.at(0)?.from
      ? editPricingData?.ApiPricingSectorModels?.at(0)?.from?.split(",")
      : "" || [],
    to: editPricingData?.ApiPricingSectorModels?.at(0)?.to
      ? editPricingData?.ApiPricingSectorModels?.at(0)?.to?.split(",")
      : "" || [],
    rbd: editPricingData?.ApiPricingSectorModels?.at(0)?.rbd
      ? editPricingData?.ApiPricingSectorModels?.at(0)?.rbd?.split(",")
      : "" || [],
    travel_date: editPricingData?.ApiPricingSectorModels?.at(0)?.travel_date
      ? new Date(editPricingData?.ApiPricingSectorModels?.at(0)?.travel_date)
      : new Date(),
    price_option:
      editPricingData?.ApiPricingSectorModels?.at(0)?.price_option || "Net Net",
    price_amount: editPricingData?.ApiPricingSectorModels?.at(0)?.price || 0,
  };
  // yup validation schema
  const validationSchema = yup.object().shape({
    wht: yup
      .number()
      .max(
        airlineIntlwht?.wht,
        `wht must be less or equal to ${airlineIntlwht?.wht}`
      ),
  });

  const journeys = {
    1: "ONE WAY",
    2: "ROUND TRIP",
    3: "MULTI CITY"
  }


  return (
    <div className="col-12 mt-5 pb-5 ">
      <div className="pricing-container rounded shadow-sm">
        {/* Agent Group tabs  */}
        {loginUserId === 1 && (
          <div className="pricing-navigation-tabs mx-auto">
            {agentGroup?.map((tab, ind) => (
              <button
                className={`h6  ${ind === 0 && !tabs
                  ? "pricing_active_link"
                  : tabs === tab?.agent_grp_name
                    ? "pricing_active_link"
                    : `pricing_details_links pricing_tab ${!editPricingData && "pricing_details_links_hover"
                    }`
                  }`}
                onClick={() => {
                  setTabs(tab?.agent_grp_name);
                  setAgentGroupId(tab?.agent_grp_id);
                  setAgentGrpCurr(tab?.currency?.curr_code);
                }}
                key={tab.agent_grp_id}
                disabled={editPricingData ? true : false}
              >
                {tab?.agent_grp_name}
              </button>
            ))}
          </div>
        )}
        <Formik
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            let editPricing = {
              ...values,
              wht: TwoDecimalPoint(values?.wht) || 0,
              gst: TwoDecimalPoint(values?.gst) || 0,
              void: TwoDecimalPoint(values?.void) || 0,
              refund: TwoDecimalPoint(values?.refund) || 0,
              price: Number(values.price_amount)
            };

            dispatch(asyncUpdateAirlinePricing(editPricing));
            dispatch(editAirlinePricing_Null());
            resetForm();
          }}

        >
          {({ values, handleChange, setFieldValue, resetForm }) => {
            return (
              <Form className=" px-4 my-4">
                <div className="row my-5">
                  <div className="col-1">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="demo-simple-select-standard-label">
                        Journey Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name="journey_id"
                        value={values.journey_id}
                        onChange={(e) => {
                          handleChange(e);
                          setJourneyType(e.target.value);
                        }}
                        MenuProps={menuProps}
                      >
                        <MenuItem value={"1"}>ONE WAY</MenuItem>
                        <MenuItem value={"2"}>ROUND TRIP</MenuItem>
                        <MenuItem value={"3"}>MULTI CITY</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-2" style={{ height: "5rem" }}>
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="demo-simple-select-standard-label">
                        Select Airline
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name="arr_id"
                        value={values?.arr_id}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("arr_id", value);
                          let ai = addAirLinesData?.find(
                            (airline) => airline.arr_id === value
                          );
                          // setAirlineItem(ai);
                          setFieldValue("airline_Item", ai);
                          let bgif = agentGroupPricing?.find((it) => {
                            return (it?.arr_id === value && Number(it?.journey_id) === Number(journeyType));
                          });
                          bgif && value !== editPricingData?.arr_id
                            ? setExistingAirline(true)
                            : setExistingAirline(false);
                        }}
                        MenuProps={menuProps}
                        inputProps={{
                          readOnly:
                            editPricingData && editPricingData.arr_id === null
                              ? false
                              : true,
                        }}
                      >
                        {sortedData?.map((airline) => (
                          <MenuItem
                            key={airline?.code_alpha}
                            value={airline?.arr_id}
                          >
                            {airline?.code_alpha} : {airline?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {existingAirline && (
                        <FormHelperText sx={{ color: "red" }}>
                          This airline is already Created
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <div className="col-2">
                    <TextField
                      name="wht"
                      onChange={handleChange}
                      label="Customer WHT %"
                      autoComplete="off"
                      className="w-100"
                      type="number"
                      variant="standard"
                      value={values?.wht}
                      onWheel={(e) => e.target.blur()}
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="wht"
                    />
                  </div>
                  <div className="col-2">
                    <TextField
                      name="gst"
                      onChange={handleChange}
                      label="Customer GST %"
                      autoComplete="off"
                      className="w-100"
                      type="number"
                      variant="standard"
                      value={values?.gst}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                  <div className="col-1">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="demo-simple-select-standard-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name="pax_type"
                        value={values.pax_type}
                        onChange={handleChange}
                        MenuProps={menuProps}
                      >
                        <MenuItem value={"Passenger"}>Passenger</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-1">
                    <TextField
                      label="Void Charges"
                      className="w-100"
                      variant="standard"
                      autoComplete="off"
                      type="number"
                      name="void"
                      value={values?.void}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                  <div className="col-2">
                    <TextField
                      className="w-100"
                      autoComplete="off"
                      label="Refund Charges"
                      variant="standard"
                      type="number"
                      name="refund"
                      value={values?.refund}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </div>
                <div className="mt-3 pt-3 d-flex gap-2 pe-4">
                  {/* International psf discount */}
                  <div className="col-sm-3 black_border rounded p-3 pt-2">
                    <div className="text-center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values?.com_intl_type}
                            onChange={handleChange}
                            name="com_intl_type"
                          />
                        }
                        label={`${values?.com_intl_type ? "Fixed Amount" : "In Percent %"
                          }`}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="mt-4">International PSF/Discount %</h6>
                      <div className="checkbox-rect">
                        <input
                          value={values.code_share_intl}
                          checked={values.code_share_intl}
                          type="checkbox"
                          id={"code_share_intl"}
                          onChange={handleChange}
                        />
                        <label htmlFor={"code_share_intl"}>Code Share</label>
                      </div>
                    </div>
                    <div className="p-3 px-2 pt-1">
                      <div className="d-flex gap-4 align-items-end mt-2">
                        <div className="w-50">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="demo-simple-select-standard-label">
                              Eco Option
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="com_intl_option_eco"
                              value={values?.com_intl_option_eco}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("com_intl_option_eco", value);
                                if (value === "Net Net") {
                                  setFieldValue("com_intl_eco", 0);
                                } else {
                                  setFieldValue("com_intl_eco", 1);
                                }
                              }}
                              MenuProps={menuProps}
                            >
                              {optionArray?.map((op, ind) => (
                                <MenuItem value={op} key={ind}>
                                  {op}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="w-50">
                          <TextField
                            label={`${values?.com_intl_type
                              ? `Fixed Amount in ${agentGrpCurr}`
                              : "In percent %"
                              }`}
                            autoComplete="off"
                            className="w-100"
                            variant="standard"
                            name="com_intl_eco"
                            value={values?.com_intl_eco}
                            type="number"
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            disabled={
                              values?.com_intl_option_eco === "Net Net"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-4 align-items-end mt-2">
                        <div className="w-50">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="demo-simple-select-standard-label">
                              B/F Option
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="com_intl_option_eco"
                              value={values?.com_intl_option_b_f}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("com_intl_option_b_f", value);
                                if (value === "Net Net") {
                                  setFieldValue("com_intl_b_f", 0);
                                } else {
                                  setFieldValue("com_intl_b_f", 1);
                                }
                              }}
                              MenuProps={menuProps}
                            >
                              {optionArray?.map((op, ind) => (
                                <MenuItem value={op} key={ind}>
                                  {op}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="w-50">
                          <TextField
                            label={`${values?.com_intl_type
                              ? `Fixed Amount in ${agentGrpCurr}`
                              : "In percent %"
                              }`}
                            autoComplete="off"
                            className="w-100"
                            variant="standard"
                            name="com_intl_b_f"
                            value={values?.com_intl_b_f}
                            type="number"
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            disabled={
                              values?.com_intl_option_b_f === "Net Net"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-4 align-items-end mt-3">
                        <div className="w-50">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="codeshare_com_intl_option_eco">
                              Code Share Eco Option
                            </InputLabel>
                            <Select
                              labelId="codeshare_com_intl_option_eco"
                              id="codeshare_com_intl_option_eco"
                              name="codeshare_com_intl_option_eco"
                              value={values?.codeshare_com_intl_option_eco}
                              disabled={
                                !values?.code_share_intl
                              }
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("codeshare_com_intl_option_eco", value);
                                if (value === "Net Net") {
                                  setFieldValue("codeshare_com_intl_eco", 0);
                                } else {
                                  setFieldValue("codeshare_com_intl_eco", 1);
                                }
                              }}
                              MenuProps={menuProps}
                            >
                              {optionArray?.map((op, ind) => (
                                <MenuItem value={op} key={ind}>
                                  {op}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="w-50">
                          <TextField
                            label={`${values.com_intl_type
                              ? `Code Share Fixed Amount in ${agentGrpCurr}`
                              : "Code Share in percent %"
                              }`}
                            autoComplete="off"
                            className="w-100"
                            variant="standard"
                            name="codeshare_com_intl_eco"
                            value={values?.codeshare_com_intl_eco}
                            type="number"
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            disabled={
                              values?.codeshare_com_intl_option_eco === "Net Net" || !values?.code_share_intl
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-4 align-items-end mt-3">
                        <div className="w-50">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="codeshare_com_intl_option_eco">
                              Code Share B/F Option
                            </InputLabel>
                            <Select
                              labelId="codeshare_com_intl_option_b_f"
                              id="codeshare_com_intl_option_b_f"
                              name="codeshare_com_intl_option_b_f"
                              value={values?.codeshare_com_intl_option_b_f}
                              disabled={
                                !values?.code_share_intl
                              }
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("codeshare_com_intl_option_b_f", value);
                                if (value === "Net Net") {
                                  setFieldValue("codeshare_com_intl_b_f", 0);
                                } else {
                                  setFieldValue("codeshare_com_intl_b_f", 1);
                                }
                              }}
                              MenuProps={menuProps}
                            >
                              {optionArray?.map((op, ind) => (
                                <MenuItem value={op} key={ind}>
                                  {op}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="w-50">
                          <TextField
                            label={`${values.com_intl_type
                              ? `Code Share Fixed Amount in ${agentGrpCurr}`
                              : "Code Share in percent %"
                              }`}
                            autoComplete="off"
                            className="w-100"
                            variant="standard"
                            name="codeshare_com_intl_b_f"
                            value={values?.codeshare_com_intl_b_f}
                            type="number"
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            disabled={
                              !values?.code_share_intl || values?.codeshare_com_intl_option_b_f === "Net Net"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* Domestic psf discount */}
                  <div className="col-sm-3 black_border rounded p-3 pt-2">
                    <div className="text-center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.com_dom_type}
                            onChange={handleChange}
                            name="com_dom_type"
                          />
                        }
                        label={`${values?.com_dom_type ? "Fixed Amount" : "In Percent %"
                          }`}
                      />
                    </div>
                    <h6 className="mt-4">Domestic PSF/Discount %</h6>
                    <div className="p-3 px-2 pt-1">
                      <div className="d-flex gap-4 align-items-end mt-2">
                        <div className="w-50">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="demo-simple-select-standard-label">
                              Eco Option
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="com_dom_option_eco"
                              value={values.com_dom_option_eco}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("com_dom_option_eco", value);
                                if (value === "Net Net") {
                                  setFieldValue("com_dom_eco", 0);
                                } else {
                                  setFieldValue("com_dom_eco", 1);
                                }
                              }}
                              MenuProps={menuProps}
                            >
                              {optionArray?.map((op, ind) => (
                                <MenuItem value={op} key={ind}>
                                  {op}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="w-50">
                          <TextField
                            variant="standard"
                            label={`${values.com_dom_type
                              ? `Fixed Amount in ${agentGrpCurr}`
                              : "In percent %"
                              }`}
                            className="w-100"
                            autoComplete="off"
                            type="number"
                            name="com_dom_eco"
                            value={values?.com_dom_eco}
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            disabled={
                              values?.com_dom_option_eco === "Net Net"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-4 align-items-end mt-2">
                        <div className="w-50">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="demo-simple-select-standard-label">
                              B/F Option
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="com_dom_option_b_f"
                              value={values.com_dom_option_b_f}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("com_dom_option_b_f", value);
                                if (value === "Net Net") {
                                  setFieldValue("com_dom_b_f", 0);
                                } else {
                                  setFieldValue("com_dom_b_f", 1);
                                }
                              }}
                              MenuProps={menuProps}
                            >
                              {optionArray?.map((op, ind) => (
                                <MenuItem value={op} key={ind}>
                                  {op}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="w-50">
                          <TextField
                            variant="standard"
                            label={`${values.com_dom_type
                              ? `Fixed Amount in ${agentGrpCurr}`
                              : "In percent %"
                              }`}
                            className="w-100"
                            autoComplete="off"
                            type="number"
                            name="com_dom_b_f"
                            value={values?.com_dom_b_f}
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            disabled={
                              values?.com_dom_option_b_f === "Net Net"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* soto psf discount */}
                  <div className="col-sm-3 black_border rounded p-3 pt-2">
                    <div className="text-center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values?.com_soto_type}
                            onChange={handleChange}
                            name="com_soto_type"
                          />
                        }
                        label={`${values?.com_soto_type ? "Fixed Amount" : "In Percent %"
                          }`}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="mt-4">Soto PSF/Discount %</h6>
                      <div className="checkbox-rect">
                        <input
                          value={values.code_share_soto}
                          checked={values.code_share_soto}
                          type="checkbox"
                          id={"code_share_soto"}
                          onChange={handleChange}
                        />
                        <label htmlFor={"code_share_soto"}>Code Share</label>
                      </div>
                    </div>
                    <div className="p-3 px-2 pt-1">
                      <div className="d-flex gap-4 align-items-end mt-2">
                        <div className="w-50">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="demo-simple-select-standard-label">
                              Eco Option
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="com_soto_option_eco"
                              value={values?.com_soto_option_eco}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("com_soto_option_eco", value);
                                if (value === "Net Net") {
                                  setFieldValue("com_soto_eco", 0);
                                } else {
                                  setFieldValue("com_soto_eco", 1);
                                }
                              }}
                              MenuProps={menuProps}
                            >
                              {optionArray?.map((op, ind) => (
                                <MenuItem value={op} key={ind}>
                                  {op}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="w-50">
                          <TextField
                            label={`${values?.com_soto_type
                              ? `Fixed Amount in ${agentGrpCurr}`
                              : "In percent %"
                              }`}
                            autoComplete="off"
                            className="w-100"
                            variant="standard"
                            name="com_soto_eco"
                            value={values?.com_soto_eco}
                            type="number"
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            disabled={
                              values?.com_soto_option_eco === "Net Net"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-4 align-items-end mt-2">
                        <div className="w-50">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="demo-simple-select-standard-label">
                              B/F Option
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="com_soto_option_eco"
                              value={values?.com_soto_option_b_f}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("com_soto_option_b_f", value);
                                if (value === "Net Net") {
                                  setFieldValue("com_soto_b_f", 0);
                                } else {
                                  setFieldValue("com_soto_b_f", 1);
                                }
                              }}
                              MenuProps={menuProps}
                            >
                              {optionArray?.map((op, ind) => (
                                <MenuItem value={op} key={ind}>
                                  {op}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="w-50">
                          <TextField
                            label={`${values?.com_soto_type
                              ? `Fixed Amount in ${agentGrpCurr}`
                              : "In percent %"
                              }`}
                            autoComplete="off"
                            className="w-100"
                            variant="standard"
                            name="com_soto_b_f"
                            value={values?.com_soto_b_f}
                            type="number"
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            disabled={
                              values?.com_soto_option_b_f === "Net Net"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-4 align-items-end mt-3">
                        <div className="w-50">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="codeshare_com_soto_option_eco">
                              Code Share Eco Option
                            </InputLabel>
                            <Select
                              labelId="codeshare_com_soto_option_eco"
                              id="codeshare_com_soto_option_eco"
                              name="codeshare_com_soto_option_eco"
                              value={values?.codeshare_com_soto_option_eco}
                              disabled={
                                !values?.code_share_soto
                              }
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("codeshare_com_soto_option_eco", value);
                                if (value === "Net Net") {
                                  setFieldValue("codeshare_com_soto_eco", 0);
                                } else {
                                  setFieldValue("codeshare_com_soto_eco", 1);
                                }
                              }}
                              MenuProps={menuProps}
                            >
                              {optionArray?.map((op, ind) => (
                                <MenuItem value={op} key={ind}>
                                  {op}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="w-50">
                          <TextField
                            label={`${values.com_soto_type
                              ? `Code Share Fixed Amount in ${agentGrpCurr}`
                              : "Code Share in percent %"
                              }`}
                            autoComplete="off"
                            className="w-100"
                            variant="standard"
                            name="codeshare_com_soto_eco"
                            value={values?.codeshare_com_soto_eco}
                            type="number"
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            disabled={
                              values?.codeshare_com_soto_option_eco === "Net Net" || !values?.code_share_soto
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-4 align-items-end mt-3">
                        <div className="w-50">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="codeshare_com_soto_option_eco">
                              Code Share B/F Option
                            </InputLabel>
                            <Select
                              labelId="codeshare_com_soto_option_b_f"
                              id="codeshare_com_soto_option_b_f"
                              name="codeshare_com_soto_option_b_f"
                              value={values?.codeshare_com_soto_option_b_f}
                              disabled={
                                !values?.code_share_soto
                              }
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("codeshare_com_soto_option_b_f", value);
                                if (value === "Net Net") {
                                  setFieldValue("codeshare_com_soto_b_f", 0);
                                } else {
                                  setFieldValue("codeshare_com_soto_b_f", 1);
                                }
                              }}
                              MenuProps={menuProps}
                            >
                              {optionArray?.map((op, ind) => (
                                <MenuItem value={op} key={ind}>
                                  {op}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="w-50">
                          <TextField
                            label={`${values.com_soto_type
                              ? `Code Share Fixed Amount in ${agentGrpCurr}`
                              : "Code Share in percent %"
                              }`}
                            autoComplete="off"
                            className="w-100"
                            variant="standard"
                            name="codeshare_com_soto_b_f"
                            value={values?.codeshare_com_soto_b_f}
                            type="number"
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            disabled={
                              !values?.code_share_soto || values?.codeshare_com_soto_option_b_f === "Net Net"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Subsidary Options */}
                  <div className="col-sm-3 black_border rounded p-3 pt-2">
                    <div className="text-center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.is_segment_wise}
                            onChange={handleChange}
                            name="is_segment_wise"
                          />
                        }
                        label={`${values?.is_segment_wise ? "Segment Wise" : "Journey Wise"
                          }`}
                      />
                    </div>
                    <h6 className="mt-4">Journey / Segment Wise</h6>
                    <div className="p-3 px-2 pt-1">
                      <div className="d-flex gap-4 align-items-end mt-2">
                        <div className="w-50">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="demo-simple-select-standard-label">
                              Option
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="subsidary_option"
                              value={values.subsidary_option}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("subsidary_option", value);
                                if (value === "Net Net") {
                                  setFieldValue("subsidary_amount", 0);
                                } else {
                                  setFieldValue("subsidary_amount", 1);
                                }
                              }}
                              MenuProps={menuProps}
                            >
                              {optionArray?.map((op, ind) => (
                                <MenuItem value={op} key={ind}>
                                  {op}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="w-50">
                          <TextField
                            variant="standard"
                            label={`Fixed Amount in PKR`}
                            className="w-100"
                            autoComplete="off"
                            type="number"
                            name="subsidary_amount"
                            value={values?.subsidary_amount}
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            disabled={
                              values?.subsidary_option === "Net Net"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* exclusive */}
                <ExclusiveRegion
                  setFieldValue={setFieldValue}
                  values={values}
                />

                <div className="text-center my-5">
                  {editPricingData && (
                    <button
                      className="button_style bg-danger px-5 me-2"
                      onClick={(e) => {
                        setFieldValue("airline_Item", "");
                        dispatch(editAirlinePricing_Null());
                        setExistingAirline(false);
                        resetForm();
                      }}
                    >
                      Cancel
                    </button>
                  )}
                  <button
                    className="button_style px-5"
                    type="submit"
                    disabled={
                      !editPricingData || existingAirline ? true : false
                    }
                  >
                    {loading ? <BtnLoader /> : "Update"}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <div className="pricing-container rounded shadow-sm pb-2">
        <div className="d-flex flex-column px-4 pb-4">
          {PostPermissions !== undefined || user?.user_id === 1 ? (
            <div className="col-12 col-sm-4 mt-5 mb-4 ">
              <div className="d-flex justify-content-between h-2rem">
                <FormControl variant="standard" className="w-50">
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Service Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="serviceType"
                    value={serviceType}
                    onChange={(e) => {
                      let value = e.target.value;
                      setServiceType(value);
                      let exSer = agentGroupPricing?.filter(
                        (lt) =>
                          lt.service_type === value &&
                          lt.service_type !== "Flight"
                      );
                      exSer.length
                        ? setExistingService(true)
                        : setExistingService(false);
                    }}
                    MenuProps={menuProps}
                    inputProps={{
                      readOnly: editPricingData ? true : false,
                    }}
                  >
                    {typeArray?.map((type) => (
                      <MenuItem
                        disabled={
                          type.id !== 1 && type.id !== 2 && type.id !== 5
                        }
                        value={type.name}
                        key={type.id}
                      >
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {existingService && (
                    <FormHelperText sx={{ color: "red" }}>
                      This Service can only be created once
                    </FormHelperText>
                  )}
                </FormControl>
                <button
                  className="button_style px-5"
                  onClick={handleServices}
                  disabled={editPricingData || existingService || !serviceType}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}
          <div className="col-12 mt-3 pt-3">
            <DeleteModal
              show={ShowModel}
              setShow={setShowModel}
              onClick={(e) => {
                dispatch(asyncDeleteAirlinePricing(pricingId));
                setShowModel(false);
                setServiceType("");
                setExistingService(false);
              }}
            />
            <MUIDataTable
              className="muidatatable bordered_table"
              title={`${tabs} (${journeys[journeyType]})`}
              data={agentGroupPricing?.filter((it) => Number(it?.journey_id) === Number(journeyType))}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
