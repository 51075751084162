import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../ApiConfig";

const initialState = {
  accountData: [],
  loading: false,
  error: null,
  editAccountData: null,
  accSearchData: [],
  mainAcountData: [],
  subAcount: [],
  allBankAndCash: [],
  changestatement: "form",
  getBankandCashtStamentData:[]
};

// getting account data
export const asyncGetAccountData = createAsyncThunk(
  "asyncGetAccountData/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/account/chart-acount`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Posting account data
export const asyncPostAccountData = createAsyncThunk(
  "asyncPostAccountData/post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(
        `/api/v1/b2b/account/chart-acount`,
        data
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Geting single account data
export const asyncEditAccountData = createAsyncThunk(
  "asyncPostAccountData/get",
  async (acc_id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/account/chart-acount/${acc_id}`
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Update account data
export const asyncUpdateAccountData = createAsyncThunk(
  "asyncPostAccountData/patch",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/account/chart-acount/${data?.acc_id}`,
        data
      );
      dispatch(asyncGetAccountData());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// search account data
export const asyncSearchAccountData = createAsyncThunk(
  "asyncSearchAccountData/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/account/search-chart-acount/?search=${data}`
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get all main account acc_parent===0
export const asyncGetMainAccountData = createAsyncThunk(
  "asyncGetMainAccountData/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/account/chart-acount-by-zero`
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// sub acount data
export const asyncGetSubAccountData = createAsyncThunk(
  "asyncGetSubAccountData/get",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/account/getAlljournalLedger?acc_id=${data}`
      );
      // console.log("response.data", response.data);
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get all bank and cash data
export const asyncGetAllBankandCashData = createAsyncThunk(
  "asyncGetAllBankandCashData/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/account/get-all-bank-cash-data`
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// bank and cash statemenet

export const asyncGetBankAndCashStament = createAsyncThunk(
  "asyncGetBankAndCashStament/get",
  async (data, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams(data).toString();
      const response = await NODEURL.get(
        `/api/v1/b2b/get-bank-cash-statement?${queryParams}`
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const Chat_of_Account = createSlice({
  name: "Chat_of_Account",
  initialState,
  reducers: {
    editAccount_null(state) {
      state.editAccountData = null;
    },
    handleAccSearchData(state) {
      state.accSearchData = null;
    },

    handleEmptysubAcountData(state) {
      state.subAcount = [];
    },
    setChangeBankandCash(state, action) {
      state.changestatement = action.payload; // Set visibility based on the passed value (true/false)
    },
  },

  extraReducers: (builder) => {
    // getting account data
    builder.addCase(asyncGetAccountData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAccountData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.accountData = payload;
    });
    builder.addCase(asyncGetAccountData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // posting account data
    builder.addCase(asyncPostAccountData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostAccountData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.accountData = [...state.accountData, payload];
    });
    builder.addCase(asyncPostAccountData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Edit account data
    builder.addCase(asyncEditAccountData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditAccountData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editAccountData = payload;
    });
    builder.addCase(asyncEditAccountData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Update account data
    builder.addCase(asyncUpdateAccountData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateAccountData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateAccountData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // search account data
    builder.addCase(asyncSearchAccountData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncSearchAccountData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.accSearchData = payload?.data?.searchResult;
    });
    builder.addCase(asyncSearchAccountData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // getting main account data
    builder.addCase(asyncGetMainAccountData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetMainAccountData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.mainAcountData = payload;
    });
    builder.addCase(asyncGetMainAccountData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // get all sun account
    builder.addCase(asyncGetSubAccountData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetSubAccountData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.subAcount = payload;
    });
    builder.addCase(asyncGetSubAccountData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // get all  bank and cash data
    builder.addCase(
      asyncGetAllBankandCashData.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetAllBankandCashData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.allBankAndCash = payload;
      }
    );
    builder.addCase(
      asyncGetAllBankandCashData.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // get all  bank and cash statemnt data
    builder.addCase(
      asyncGetBankAndCashStament.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetBankAndCashStament.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.getBankandCashtStamentData = payload;
      }
    );
    builder.addCase(
      asyncGetBankAndCashStament.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});



export const {
  editAccount_null,
  handleAccSearchData,
  handleEmptysubAcountData,
  setChangeBankandCash,
} = Chat_of_Account.actions;

export default Chat_of_Account.reducer;
