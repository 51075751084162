import React, { useEffect, useState } from "react";
import General from "../components/BookingDetails/General";
import Contact from "../components/BookingDetails/Contact";
import Pricing from "../components/BookingDetails/Pricing";
import Invoice from "../components/BookingDetails/Invoice";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "../Styles/bookingdetails.css";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGenerateOtpcode,
  cancelBooking,
  getAsyncBookingDetails,
} from "../redux/features/booking/bookingDetailsSlice";
import { asyncGetNewSupplier } from "../redux/features/supplier/AddNewSupplier";
import AddEditModal from "../components/BookingDetails/Add/EditModal";

import ReIssueTicketModal from "../components/BookingDetails/Requests";
import RequestChangeModal from "../components/BookingDetails/RequestChangeModel";
import { asyncGetLoginData } from "../redux/features/userLogin/profileHeaderSlice";
import {
  asyncGetFlightGroupData,
  asyncupdateFlightGroupBookedSeats,
} from "../redux/features/promotion/FlightGroupsSlice";
import {
  asyncGetBankDetailData,
  asyncGetCurrencyData,
} from "../redux/features/setupRestPages/Bank_curr_RoleSlice";
import {
  asyncGetNewAgencyReg,
  asyncSearchAgencyData,
} from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { asyncGetFlightRequestsData } from "../redux/features/requests/flightrequest";
import { asyncGetAgentGroup } from "../redux/features/setupRestPages/agentsSlice";
import { asyncGetSupplierRule } from "../redux/features/setupRestPages/Price_AirLine_SuppRole";
import FlightPdf from "../components/Pdf/FlightPdf";
import { BlobProvider } from "@react-pdf/renderer";
import { asyncupdateMianBookingStatus } from "../redux/features/booking/bookingDetailsSlice";
import RequestTab from "../components/BookingDetails/RequestTab";
import BtnLoader from "../components/AppForm/BtnLoader";
import GenerateOtp from "../components/BookingDetails/GenerateOtp";
import { asyncGetAgencyPermissions } from "../redux/features/agency/agencyPermissionsSlice";
import moment from "moment";
import PaxTicketIssue from "../components/BookingDetails/PaxTicketIssue";
import CancelBookingPerPessenger from "../components/BookingDetails/CancelBookingPerPessenger";
import UpdateSegments from "../components/BookingDetails/Add/UpdateSegments";
import { baseURL } from "../Utils/configUrl";
import axios from "axios";
import { URL2 } from "../redux/features/ApiConfig";
import Chat from "../components/BookingDetails/Chat";
import CancelBooking from "../components/BookingDetails/CancelBooking";
import VoidTicket from "../components/BookingDetails/VoidTicket";
import { fetchBookingUpdate } from "../redux/features/booking/updateSlice";
import { voidTicket } from "../redux/features/booking/voidTicketSlice";

function BookingDetails() {
  const [toggle, setToggle] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [cancellToggle, setCancellToggle] = useState(false);
  const [paxes, setPaxes] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const booking = useSelector((state) => state?.booking);
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const { searchAgency, newAgencyRegData } = useSelector(
    (state) => state.newAgency
  );
  const [paxData, setPaxData] = useState([]);

  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const { flightgetData } = useSelector((state) => state?.flightrequest);
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const dispatch = useDispatch();
  const [active_tab, setActive_Tab] = useState(
    window.location.pathname?.includes("active") ? "Requests" : "Details"
  );
  const { flightGroup } = useSelector((state) => state.flightGroup);
  const { bankDetails, editBankDetails, loading } = useSelector(
    (state) => state?.bank_curr_roleSlice
  );
  const extractDepartureDates = (booking) => {
    const currentDate = moment().format("YYYY-MM-DD"); 
  
    if (booking?.FlightBookingModels && booking?.FlightBookingModels.length > 0) {

      const seg=booking?.FlightBookingModels[booking?.FlightBookingModels.length - 1]
      const segments = seg?.FlightSegmentModels;
  
        if (segments && segments?.length > 0) {
          const lastSegmentDepDate = segments[segments.length - 1].dep_date;
          const date = moment(lastSegmentDepDate).format("YYYY-MM-DD");
          return moment(date).isBefore(currentDate, 'day'); 
        }
    }
  
    return false;
  };
  
  
  
  const departureDates = extractDepartureDates(booking.booking);
 
  const [isLoading, setIsLoading] = useState(false);

  const { booking_id } = useParams();

  const { agentGroup } = useSelector((state) => state.agentsCategories);
  const { agencyPermissions } = useSelector((state) => state.agencyPermissions);

  const [message, setMessage] = useState(false);
  const [messageToggle, setMessageToggle] = useState("");
  const [otp, setOtp] = useState("");
  useEffect(() => {
    let id = location?.state?.id
      ? location?.state?.id
      : location?.state?.upDateflightGroupId || booking_id;
    dispatch(getAsyncBookingDetails({ id }));
    // dispatch(getAllAsyncBookings())
    dispatch(asyncGetAgentGroup());
    dispatch(asyncGetLoginData());
    dispatch(asyncGetNewAgencyReg());
    dispatch(asyncSearchAgencyData());
    dispatch(asyncGetBankDetailData());
    dispatch(asyncGetNewSupplier());
    dispatch(asyncGetFlightRequestsData());
    dispatch(asyncGetCurrencyData());
    dispatch(asyncGetSupplierRule());
  }, [
    location?.state?.upDateflightGroupId,
    location?.state?.id,
    message,
    active_tab,
    cancellToggle,
    messageToggle,
  ]);

  useEffect(() => {
    dispatch(asyncGetFlightGroupData());
  }, [dispatch, messageToggle, location?.state?.upDateflightGroupId]);

  // Get Agency permission assingned by admin from Agency page
  useEffect(() => {
    dispatch(asyncGetAgencyPermissions());
  }, [dispatch]);


  // useEffect(() => {
  //   if (messageToggle==="Cancel Booking") {
  //     dispatch(cancelBooking({id,gds}));
  //   }
  // }, [dispatch,messageToggle]);

  const { supplierRulesData } = useSelector(
    (state) => state.price_airlines_suppRoleSlice
  );

  const agencyDataId = newAgencyRegData?.find(
    (ag) =>
      ag?.agent_id === booking?.booking?.agent_acc_id ||
      ag?.acc_id === booking?.booking?.agent_acc_id
  );
  const agent_grp = agentGroup?.find(
    (item) => item.agent_grp_id === agencyDataId?.agent_grp_id
  );

  // // currency of the selected agent group
  const currency = currencyData?.find(
    (it) => it.currency_id === agent_grp?.currency_id
  );

  const { adminAgencyData } = useSelector((state) => state.agentsCategories);

  const logo = adminAgencyData?.logo;
  const firstColor = adminAgencyData?.first_color;

  // //set local storage
  localStorage.setItem(
    "currency",
    JSON.stringify(currency !== undefined ? currency : "")
  );

  const [showTicketModal, setShowTicketModal] = useState(true);
  const [requestError, setRequestError] = useState("");

  // Function to Check if the login user has permission to issue ticket
  const getTicketIssuePermissions = () => {
    if (user?.role_id !== 1 && user?.agent_id === 1) {
      return userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/issue_ticket"
      );
    } else if ([6, 7].includes(user?.role_id)) {
      if (agencyPermissions?.tkt_auth === 1) {
        let deptDate = moment(
          booking?.booking?.FlightBookingModels?.at(0)?.FlightSegmentModels[0]
        );
        let resultDate = deptDate.add(72, "hours");
        let currentDate = moment();
        if (agencyPermissions?.hours_72 === 1) {
          return resultDate.isAfter(currentDate) && user?.agent_id !== 1;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  // Check if the login user has permission to make request
  const requestPermission = ![1, 6, 7].includes(user?.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/request"
      )
    : true;
  // Check if the login user has permission to re-issue ticket
  const reIssueTicketPermission = ![1, 6, 7].includes(user?.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/re_issue_ticket"
      )
    : true;

  // Check if the login user has permission to void ticket
  const voidTicketPermission = ![1, 6, 7].includes(user?.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/void_ticket"
      )
    : true;

  const FarePermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id && it?.page_level.permission_url === "/fare"
  );

  const array = [
    {
      title: "Details",
      hasPermission: true,
    },
    {
      title: "Contact",
      hasPermission: true,
    },
    {
      title: "Fare",
      hasPermission: true,
    },
    {
      title: "Invoice",
      hasPermission: true,
    },
    // {
    //   title: "Requests",
    //   hasPermission: true,
    // },
    // {
    //   title: "Make Request",
    //   hasPermission: requestPermission !== undefined,
    //   request: {
    //     requestflag:
    //       "You can not create another request unitil you previouse request against this  booking is not solved from admin side.",
    //     docStatus:
    //       "You can only make a request if your document status is Pending or Issued.",
    //     booKStatus:
    //       "You can not make a request if your booking status is cancelled.",
    //   },
    // },
    {
      // title: "Ticket Issue",
      title: booking?.booking?.doc_status === "Issued" &&
      booking?.booking?.book_status === "Confirmed"
        ? "Void Ticket"
        : "Ticket Issue",
      hasPermission: getTicketIssuePermissions(),
      Ticket: [{ name: "Are you sure you want to issue a ticket?" }],
    },
    // {
    //   title: "Re-Issue Tickets",
    //   hasPermission: reIssueTicketPermission !== undefined,
    // },
    {
      title: "View Ticket",
      hasPermission: true,
      pax:
        booking?.booking?.FlightBookingModels &&
        booking?.booking?.FlightBookingModels[0]?.pax_tables?.map((p, i) => {
          return {
            name: p?.fName + " " + p?.lname,
            dob: p?.dob,
            pspt_cnic: p?.pspt_cnic,
            tkt_number: p?.tkt_number,
            pax_id: p?.pax_id,
          };
        }),
    },
    { title: "Chat", hasPermission: true },
    { title: "Update", hasPermission: true },

    {
      title: "Cancel Booking",
      hasPermission: true,
      can: [{ name: "Are you sure, you want to cancel this  booking." }],
    },
    // { title: "Add/Edit SSR", hasPermission: true },

    {
      title: "Back",
      hasPermission: true,
    },
  ];
  const arrayOfData = array.filter((arr) => {
    if (user?.agents_comp?.tkt_auth === 0 && user?.agent_id !== 1) {
      return arr.title !== "Ticket Issue";
    } else if (booking?.booking?.book_type === "flight-group") {
      // Filter out "Update Segment" and "Add/Edit SSRt" when book_type is "flight-group"====>for flight group these would not be show for other it shold be show
      return arr.title !== "Update" && arr.title !== "Add/Edit SSRt";
    } else if(user.agent_id === 1 && user.user_id !== 1) {
        return getTicketIssuePermissions();
    }
    return true;
  });

  const [state, setState] = useState();
  useEffect(() => {
    setState(
      booking?.booking?.book_type === "flight-group" ? "Manual" : "Auto"
    );
  }, [booking?.booking?.id]);

  useEffect(() => {
    // const queryParams = new URLSearchParams(location.search);
    const tab = location.pathname.includes('chat') ? 'Chat' : null;

    if (tab) {
        setActive_Tab(tab); // Set the active tab to 'Chat'
    }
}, [location]);
  
useEffect(() => {
  const gds = booking?.booking?.FlightBookingModels?.at(0)?.gds;
  const id = booking?.booking?.id;
  if (active_tab === "Update" && booking?.booking?.book_status !== "Cancelled" && !departureDates) {
    dispatch(fetchBookingUpdate({ gds, id}));
  }
}, [active_tab,messageToggle]);

useEffect(() => {
  const gds = booking?.booking?.FlightBookingModels?.at(0)?.gds;
  const id = booking?.booking?.id;
  if (active_tab === "Void Ticket" && booking?.booking?.doc_status === "Issued" &&
  booking?.booking?.book_status === "Confirmed" && !departureDates) {
    dispatch(voidTicket({id,gds}));
  }
}, [active_tab,messageToggle]);


  return (
    <div className="">
      {/* booking_details_container */}

      <div className="row d-flex m-5">
        <div className="col-sm-2 rounded ">
          <div className="me-1 bg-white p-3 rounded-3 sticky-sidebar">
            {arrayOfData?.map((item) =>
              item?.hasPermission ? (
                <>
                  <div
                    className={`h5 booking-tab ${
                      item?.title === active_tab
                        ? "booking-tab-link "
                        : "bookings_details_links"
                    }`}
                    onClick={() => {
                      setActive_Tab(
                        item?.title === "Make Request" ? " " : item?.title
                      );
                      setMessageToggle(
                        item?.title === messageToggle ? "" : item?.title
                      );

                      // Make Request
                      if (item?.title === "Make Request") {
                        if (booking?.booking?.book_status !== "Cancelled") {
                          if (
                            ["Pending", "Issued"].includes(
                              booking?.booking?.doc_status
                            )
                          ) {
                            if (
                              booking?.booking?.FlightBookingModels[0]
                                ?.request_flag === null
                            ) {
                              setActive_Tab(item?.title);
                            } else {
                              setRequestError("requestflag");
                              setActive_Tab("Details");
                            }
                          } else {
                            setRequestError("docStatus");
                            setActive_Tab("Details");
                          }
                        } else {
                          setRequestError("booKStatus");
                          setActive_Tab("Details");
                        }
                      }

                      item?.title === "Back" && navigate("/booking");
                    }}
                  >
                    {item?.title}
                  </div>
                  {booking?.booking?.doc_status !== "Issued" && booking?.booking?.book_status !== "Cancelled" &&
                    messageToggle === "Ticket Issue" &&
                    item?.Ticket?.map((ticket) => (
                      <>
                        <PaxTicketIssue
                          ticket={ticket}
                          booking_id={booking?.booking?.id}
                          active_tab={active_tab}
                          setMessageToggle={setMessageToggle}
                          setOtp={setOtp}
                          user={user}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          setPaxData={setPaxData}
                          paxData={paxData}
                          auto_manual={booking?.booking?.auto_manual}
                          booking={
                            booking?.booking?.FlightBookingModels &&
                            booking?.booking?.FlightBookingModels
                          }
                          paxs={
                            booking?.booking?.FlightBookingModels &&
                            booking?.booking?.FlightBookingModels?.at(0)
                              ?.pax_tables
                          }
                          state={state}
                          setState={setState}
                        />
                      </>
                    ))}

                  <div>
                    {messageToggle === "View Ticket" && booking?.booking?.book_status !== "Cancelled" &&
                      item?.pax?.map((p) => {
                        return (
                          <p
                            className={`h6 booking-tab ps-4  ${
                              p?.name === paxes?.name
                                ? "booking-tab-link "
                                : "bookings_details_links "
                            }`}
                            onClick={() => {
                              setActive_Tab("paxespdf");
                              setPaxes(p);
                            }}
                          >
                            {p?.name}
                          </p>
                        );
                      })}
                  </div>
                  {/* cnacel booking */}
                  {/* booking?.booking?.book_type === "flight-group"
                    ? */}
                  {
                   
                      messageToggle === "Cancel Booking" &&
                        booking?.booking?.book_status === "Confirmed" && booking?.booking?.book_status !== "Cancelled" &&
                        item?.can?.map((cancel, index) => (
                          <>
                            {booking?.booking?.book_type === "flight-group" ?  <div>
                            <CancelBookingPerPessenger
                              setMessageToggle={setMessageToggle}
                              booking={booking}
                              setMessage={setMessage}
                              message={message}
                              cancel={cancel}
                              active_tab={active_tab}
                              paxs={
                                booking?.booking?.FlightBookingModels &&
                                booking?.booking?.FlightBookingModels?.at(0)
                                  ?.pax_tables
                              }
                              auto_manual={booking?.booking?.auto_manual}
                              booking_id={booking?.booking?.id}
                            />
                          </div>: 
                          <div>
                            <CancelBooking booking={booking}/>
                          </div>}
                          </>
                         
                        ))
                      
                    }
                  {/* save request  */}
                  {messageToggle === "Make Request" && booking?.booking?.book_status !== "Cancelled" &&
                    item?.request &&
                    ["booKStatus", "docStatus", "requestflag"].includes(
                      requestError
                    ) && (
                      <div>
                        <p className={`h6  ps-4 text-danger`}>
                          {item?.request[requestError]}
                        </p>
                      </div>
                    )}
                </>
              ) : null
            )}
          </div>
        </div>
        {/* booking_status != cancel ? (document_status === "pending" || document_status === "issued") ? flight_booking.requestflag = null ? "oprn model" : "reqflag" : "doc" : "cnacel" */}
        <div className="col-sm-10 bg-white rounded-3 mb-5">
          {otp === "otp" ? (
            <GenerateOtp
              setActive_Tab={setActive_Tab}
              id={booking?.booking?.id}
              auto_manual={booking?.booking?.auto_manual}
              setOtp={setOtp}
              paxData={paxData}
              booking={
                booking?.booking?.FlightBookingModels &&
                booking?.booking?.FlightBookingModels
              }
              state={state}
            />
          ) : (
            [
              "Details",
              "View Ticket",
              "Cancel Booking",
              "Ticket Issue",
            ].includes(active_tab) && (
              <General
                setActive_Tab={setActive_Tab}
                booking={booking?.booking?.id && booking}
                agencyData={newAgencyRegData?.find(
                  (ag) => ag?.acc_id === booking?.booking?.agent_acc_id
                )}
                user={user}
                supplierRulesData={supplierRulesData}
                id={booking?.booking?.id}
              />
            )
          )}
          {active_tab === "Contact" && (
            <Contact
              user={booking?.booking?.booked_by}
              agencyData={newAgencyRegData?.find(
                (ag) => ag?.acc_id === booking?.booking?.agent_acc_id
              )}
            />
          )}
          {active_tab === "Void Ticket" && (
                      <VoidTicket booking={booking} user={user} active_tab={active_tab} />
                    )}
          {active_tab === "Fare" && booking?.booking?.book_status !== "Cancelled" && (
            <Pricing
              FarePermission={FarePermission}
              booking={booking}
              user={user}
              agentGroup={agentGroup}
              PaxData={booking?.booking?.FlightBookingModels?.at(0)?.pax_tables}
              mainBooking={booking?.booking}
              agencyData={newAgencyRegData?.find(
                (ag) => ag?.acc_id === booking?.booking?.agent_acc_id
              )}
              setActive_Tab={setActive_Tab}
            />
          )}
          {active_tab === "Invoice" && booking?.booking?.book_status !== "Cancelled" && (
            <Invoice
              booking={booking}
              agentGroup={agentGroup}
              user={user}
              agencyData={newAgencyRegData?.find(
                (ag) => ag?.acc_id === booking?.booking?.agent_acc_id
              )}
              bankDetails={bankDetails}
              newAgencyRegData={newAgencyRegData}
              supplierRulesData={supplierRulesData}
            />
          )}
          <div>
            {active_tab === "paxespdf" && booking?.booking?.book_status !== "Cancelled" && (
              <FlightPdf
                pax={paxes}
                firstColor={firstColor}
                baggage={booking?.booking?.FlightBookingModels?.at(
                  0
                )?.flight_pax_baggages?.filter(
                  ({ pax_id }) => pax_id === paxes?.pax_id
                )}
                flight={
                  booking?.booking?.FlightBookingModels?.length &&
                  booking?.booking
                }
                logo={logo}
              />
            )}
          </div>
         
          {active_tab === "Update" && booking?.booking?.book_status !== "Cancelled" && !departureDates && <UpdateSegments />}

          {active_tab === "Requests" && booking?.booking?.book_status !== "Cancelled" && (
            <RequestTab
              data={booking}
              agencyData={newAgencyRegData?.find(
                (ag) => ag?.acc_id === booking?.booking?.agent_acc_id
              )}
            />
          )}
          {active_tab === "Chat" &&  booking?.booking?.book_status !== "Cancelled" &&(
            <Chat
              data={booking}
              agencyData={newAgencyRegData?.find(
                (ag) => ag?.acc_id === booking?.booking?.agent_acc_id
              )}
            />
          )}
          {active_tab === "Make Request" && booking?.booking?.book_status !== "Cancelled" && (
            <RequestChangeModal
              booking={booking}
              type={"Issue"}
              show={active_tab === "Make Request"}
              setShow={setShowTicketModal}
              setActive_Tab={setActive_Tab}
              agencyData={newAgencyRegData?.find(
                (ag) => ag?.acc_id === booking?.booking?.agent_acc_id
              )}
              message={message}
              setMessage={setMessage}
            />
          )}
          {active_tab === "Re-Issue Tickets" && booking?.booking?.book_status !== "Cancelled" && (
            <ReIssueTicketModal
              show={active_tab === "Re-Issue Tickets"}
              setShow={setShowTicketModal}
              setActive_Tab={setActive_Tab}
            />
          )}
          {active_tab === "Add/Edit SSRt" && booking?.booking?.book_status !== "Cancelled" &&(
            <AddEditModal
              show={active_tab === "Add/Edit SSRt"}
              setShow={setShowTicketModal}
              setActive_Tab={setActive_Tab}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default BookingDetails;
