import React, { useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { apiUrl } from "../../../../Utils/configUrl";
import iata from "../../../../assets/images/iata.png";
import "../../../../Styles/index.css"
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAdmin } from "../../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { useNavigate } from "react-router-dom";
import { setOpenTrailBalance } from "../../../../redux/features/booking/accStructure";
const TrialBalannceTable = ({
  dropdownValue,
  activityTotals,
  closingTotals,
  openingTotals,
  isSubmitted,
  groupedAccounts,
  accountData,
  getIndentationLevel,
  calculateClosing,
  fromDate,
  toDate,
  setChangeComp,
}) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);
  const { searchAgency, getAdminData } = useSelector(
    (state) => state.newAgency
  );
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const firstColor = adminAgencyData?.first_color;
  useEffect(() => {
    dispatch(asyncGetAdmin({ id: 1 }));
  }, [dispatch]);
  return (
    <div
      //  className="w-100"
      className="d-flex  flex-column gap-4 bg-white "
      style={{ width: "100%" }}
    >
      <div className="p-4 fs-4 no-print border border-secondry ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginRight: "100px", marginLeft: "100px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button className="bg_primary px-4 py-1 text-white">Print</button>
            )}
            content={() => componentRef.current}
          />

          <button
            onClick={(e) => dispatch(setOpenTrailBalance("form"))}
            className="bg_primary px-4 py-1 rounded text-white"
          >
            Back
          </button>
        </div>
      </div>
      <div className="d-flex flex-column gap-3  mt-4" ref={componentRef}>
        <div className="d-flex justify-content-around align-items-center">
          <div style={{ width: "300px" }}>
            <img
              src={`${apiUrl}/${getAdminData?.logo}`}
              alt="logo"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
          <div
            className="d-flex fw-bold fs-6  flex-column gap-2 align-items-center"
            style={{ width: "1200px" }}
          >
            <span className=" text-black">
              {" "}
              {/* {userData?.agents_comp?.agent_name} */}
            </span>
            <span className=" text-black">
              {" "}
              {userData?.agents_comp?.agent_name}
            </span>
            <span className="text-black">
              {" "}
              {userData?.agents_comp?.address
                ?.split("]")
                ?.slice(0, 3)
                ?.join(" ")}{" "}
            </span>
            <span className=" text-black"> Email: {userData?.email}</span>
            <span className=" text-black"> Contact:{userData?.mobile_no}</span>
            <span className=" text-black">
              {" "}
              From: {moment(fromDate)?.format("DD-MMM-YY").toUpperCase()}
            </span>
            <span className=" text-black">
              {" "}
              To: {moment(toDate)?.format("DD-MMM-YY").toUpperCase()}
            </span>
          </div>
          <div style={{ width: "200px" }}>
            <img
              src={iata}
              alt="iata"
              style={{ width: "70%", height: "auto" }}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column gap-1 justify-content-center mx-4 align-items-center"
          style={{ backgroundColor: firstColor }}
        >
          <span className="fs-3 text-white fw-bold my-2"> Trial Balance</span>
        </div>
        {/* {isSubmitted && ( */}
        <div className="d-flex justify-content-center align-items-center">
          <div
            //   className="container d-flex justify-content-end mt-1"
            className="d-flex flex-column gap-3  mt-4 align-items-center"
            style={{ width: "97%", height: "20%" }}
          >
            <table
              className="table table-bordered  table-sm table-striped"
              style={{ width: "100%", height: "20%", fontSize: "0.8rem" }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      //   border: "1px solid black",
                      padding: "8px",
                      textAlign: "center",
                      width: "300px",
                    }}
                  >
                    Account
                  </th>
                 
                  {(dropdownValue === "OPENING" || dropdownValue==="All Type") &&  dropdownValue !== "CLOSING" && dropdownValue !== "ACTIVITY"  && (
                    <>
                      <th
                        colSpan={2}
                        style={{
                          //   border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        Opening
                      </th>
                    </>
                  )}
                  {(dropdownValue === "ACTIVITY" || dropdownValue==="All Type") &&  dropdownValue !== "OPENING" && dropdownValue !== "CLOSING"  && (
                    <>
                      <th
                        colSpan={2}
                        style={{
                          //   border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        Activity
                      </th>
                    </>
                  )}
                  {(dropdownValue === "CLOSING" || dropdownValue==="All Type") &&  dropdownValue !== "OPENING" && dropdownValue !== "ACTIVITY"  && (
                    <>
                      <th
                        colSpan={2}
                        style={{
                          //   border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        Closing
                      </th>
                    </>
                  )}
                </tr>
                <tr className="align-item-center">
                  <th
                    style={{
                      //   border: "1px solid black",
                      padding: "8px",
                      width: "400px",
                    }}
                  >
                    Name
                  </th>
                  
                  {(dropdownValue === "OPENING" || dropdownValue==="All Type" ) &&  dropdownValue !== "OPENING" && dropdownValue !== "ACTIVITY" && (
                    <>
                      <th
                        style={{
                          //   border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                          width: "200px",
                        }}
                      >
                        Debit
                      </th>
                      <th
                        style={{
                          //   border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                          width: "200px",
                        }}
                      >
                        Credit
                      </th>
                    </>
                  )}
                  {(dropdownValue === "ACTIVITY" || dropdownValue==="All Type") &&  dropdownValue !== "OPENING" && dropdownValue !== "CLOSING" && (
                    <>
                      <th
                        style={{
                          //   border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                          width: "200px",
                        }}
                      >
                        Debit
                      </th>
                      <th
                        style={{
                          //   border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                          width: "200px",
                        }}
                      >
                        Credit
                      </th>
                    </>
                  )}
                  {(dropdownValue === "CLOSING" || dropdownValue==="All Type") &&  dropdownValue !== "OPENING" && dropdownValue !== "ACTIVITY" && (
                    <>
                      <th
                        style={{
                          //   border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                          width: "200px",
                        }}
                      >
                        Debit
                      </th>
                      <th
                        style={{
                          //   border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                          width: "200px",
                        }}
                      >
                        Credit
                      </th>
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {groupedAccounts(accountData?.accountTotals).map(
                  (account, index) => {
                    const subs=account.acc_nature === "SUBSIDIARY"

                    return (
                      <tr key={index}>
                        <td
                          style={{
                            // border: "1px solid black",
                            paddingLeft: `${getIndentationLevel(account)}px`,
                            fontWeight: subs ? "normal" : "bold",
                          }}
                          
                        >
                          {account.acc_title}
                        </td>

                        {/* Opening Debit and Credit */}
                        {((dropdownValue === "OPENING" || dropdownValue==="All Type") && dropdownValue !== "CLOSING" && dropdownValue !== "ACTIVITY" ) && (
                          <>
                            <td
                              style={{
                                // border: "1px solid black",
                                padding: "2px",
                                textAlign: "right",
                                width: "50px",
                                fontWeight: subs ? "normal" : "bold",
                              }}
                             
                            >
                              {account.debitAmount}
                            </td>
                            <td
                              style={{
                                // border: "1px solid black",
                                padding: "2px",
                                textAlign: "right",
                                width: "50px",
                                fontWeight: subs ? "normal" : "bold",
                              }}
                            >
                              {account.creditAmount}
                            </td>
                          </>
                        )}

                        {/* Activity Debit and Credit */}
                        {((dropdownValue === "ACTIVITY" || dropdownValue==="All Type") && dropdownValue !== "CLOSING" && dropdownValue !== "OPENING") && (
                          <>
                            <td
                              style={{
                                // border: "1px solid black",
                                padding: "2px",
                                textAlign: "right",
                                width: "50px",
                                fontWeight: subs ? "normal" : "bold",
                              }}
                            >
                              {account.activityDebitAmount}
                            </td>
                            <td
                              style={{
                                // border: "1px solid black",
                                padding: "2px",
                                textAlign: "right",
                                width: "50px",
                                fontWeight: subs ? "normal" : "bold",
                              }}
                            >
                              {account.activityCreditAmount}
                            </td>
                          </>
                        )}

                        {/* Closing Debit and Credit */}
                        {(dropdownValue === "CLOSING" || dropdownValue==="All Type") && dropdownValue !== "ACTIVITY" && dropdownValue !== "OPENING" && (
                          <>
                            <td
                              style={{
                                // border: "1px solid black",
                                padding: "2px",
                                textAlign: "right",
                                width: "100px",
                                fontWeight: subs ? "normal" : "bold",
                              }}
                            >
                              {account.closingDebitAmount}
                            </td>
                            <td
                              style={{
                                // border: "1px solid black",
                                padding: "2px",
                                textAlign: "right",
                                width: "100px",
                                fontWeight: subs ? "normal" : "bold",
                              }}
                            >
                              {account.closingCreditAmount}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  }
                )}
                {/* Totals Row */}
                <tr>
                  <td
                    style={{
                      //   border: "1px solid black",
                      textAlign: "left",
                      fontWeight: "bold",
                      width: "100px",fontSize: "0.9rem"
                    }}
                  >
                    TOTAL
                  </td>

                  {/* Opening Totals */}
                  {((dropdownValue === "OPENING" || dropdownValue==="All Type") && dropdownValue !== "CLOSING" && dropdownValue !== "ACTIVITY" ) && (
                    <>
                      <td
                        style={{
                          // border: "1px solid black",
                          padding: "2px",
                          textAlign: "right",
                          width: "100px",
                          fontWeight:"bold",fontSize: "0.9rem"
                        }}
                      >
                        {openingTotals?.debit}
                      </td>
                      <td
                        style={{
                          //  border: "1px solid black",
                          padding: "2px",
                          textAlign: "right",
                          width: "100px",
                          fontWeight:"bold",fontSize: "0.9rem"
                        }}
                      >
                        {openingTotals?.credit}
                      </td>
                    </>
                  )}

                  {/* Activity Totals */}
                  {((dropdownValue === "ACTIVITY" || dropdownValue==="All Type") && dropdownValue !== "CLOSING" && dropdownValue !== "OPENING") && (
                    <>
                      <td
                        style={{
                          // border: "1px solid black",
                          padding: "2px",
                          width: "100px",
                          textAlign:"right",
                          fontWeight:"bold",fontSize: "0.9rem"
                        }}
                      >
                        {activityTotals?.debit}
                      </td>
                      <td
                        style={{
                          // border: "1px solid black",
                          padding: "2px",
                          width: "100px",
                          textAlign:"right",
                          fontWeight:"bold",fontSize: "0.9rem"
                        }}
                      >
                        {activityTotals?.credit}
                      </td>
                    </>
                  )}

                  {/* Closing Totals */}
                  {(dropdownValue === "CLOSING" || dropdownValue==="All Type") && dropdownValue !== "ACTIVITY" && dropdownValue !== "OPENING"  && (
                    <>
                      <td
                        style={{
                          padding: "2px",
                          width: "100px",
                          textAlign:"right",
                          fontWeight:"bold",fontSize: "0.9rem"
                        }}
                      >
                        {closingTotals?.debit}
                      </td>
                      <td
                        style={{
                          padding: "2px",
                          width: "100px",
                          textAlign:"right",
                          fontWeight:"bold",fontSize: "0.9rem"
                        }}
                      >
                        {closingTotals?.credit}
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialBalannceTable;
