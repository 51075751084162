import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  cancelBooking,
  getAsyncBookingDetails,
} from "../../redux/features/booking/bookingDetailsSlice";
import { Formik, Form, Field } from "formik";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import BtnLoader from "../AppForm/BtnLoader";

const CancelBooking = ({ booking }) => {
  const [confirming, setConfirming] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("loginUser"));

  const id = booking?.booking?.id;
  const gds = "AMADEUS";

  const initialValues = {
    reason: "",
  };

  const handleCancelClick = () => {
    setConfirming(true);
  };

  // Function to hide dropdown
  const handleDropdownClose = () => {
    setIsDropdownVisible(false);
  };

  return (
    <>
      {isDropdownVisible && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            const text = `${user.user_id}_${user.f_name} ${
              user.l_name
            }_${moment().format("DD-MMM-YY HH:MM")}_${values.reason}`;
            dispatch(cancelBooking({ id, gds, text }))
              .unwrap()
              .then((response) => {
                dispatch(getAsyncBookingDetails({ id }));
                setTimeout(() => {
                  setSubmitting(false);
                  navigate("/booking");
                }, 2000);
              })
              .catch((err) => {
                console.log("error", err);
              });
          }}
        >
  
          {({ isSubmitting }) => (
            <Form>
              <div>
                <Field
                  as="textarea"
                  name="reason"
                  className="py-1 px-1"
                  rows="5"
                  cols="27"
                  placeholder="Please enter the reason here"
                  style={{
                    border: "2px solid #cccccc",
                    outline: "none",
                  }}
                  maxLength="100"
                />
              </div>

              <div className="py-2 d-flex justify-content-around">
                <button
                  className="px-3 py-2 btn btn-danger"
                  type="button"
                  onClick={handleDropdownClose}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 selectbtn_style"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div className="text-center">
                      <button className="button_style">
                        <BtnLoader />
                      </button>
                    </div>
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default CancelBooking;
