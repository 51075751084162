import { configureStore } from "@reduxjs/toolkit";
import MakePaymentsSlice from "./features/Payments/MakePaymentsSlice";
import HotelSlice from "./features/umrah/HotelSlice";
import TransferSlice from "./features/umrah/TransferSlice";
import VisaSupplierSlice from "./features/umrah/VisaSupplierSlice";
import CountrySlice from "./features/visa/CountrySlice";
import VisaRateSlice from "./features/visa/RateSlice";
import VisaTermSlice from "./features/visa/TermsAndConditionsSlice";
import VisaTypeSlice from "./features/visa/VisaTypeSlice";
import AddFlashNewsSlice from "./features/promotion/AddFlashNewsSlice";
import PromotionSlice from "./features/promotion/PromotionSlice";
import PaymentsAllSlices from "./features/Payments/PaymentsAllSlices";
import CircularSlice from "./features/promotion/CircularSlice";
import FlightGroupSlice from "./features/promotion/FlightGroupsSlice";
import NewSupplierSlice from "./features/supplier/AddNewSupplier";
import AgentsSlice from "./features/setupRestPages/agentsSlice";
import Bank_curr_RoleSlice from "./features/setupRestPages/Bank_curr_RoleSlice";
import Price_Airlines_SuppRoleSlice from "./features/setupRestPages/Price_AirLine_SuppRole";
import Agency_Doc_Userlevel_Slice from "./features/agency/agency_doc_userlevel_slice";
import TransferSupplierSlice from "./features/umrah/TransferSupplierSlice";
import HotelRoomSlice from "./features/umrah/HotleRoomSlice";
import HotelRateSlice from "./features/umrah/HotelRateSlice";
import profileHeaderSlice from "./features/userLogin/profileHeaderSlice";
import dashboardSlice from "./features/dashboard/dashboardSlice";
import usersSlice from "./features/agency/usersSlice";
import NewRegistrationSlice from "./features/setupFeature/NewRegistration/NewRegistrationSlice";
import requestsSlice from "./features/requests/requestsSlice";
import landingPageSlice from "./features/landingPage/landingPageSlice";
import Banner_About_Services_Slice from "./features/agency/homeviewsittingslices/banner_about_services_slice";
import UserSlice from "./features/userLogin/UserSlice";
import Finance_Voucher_Manual_Invoice_Hotel from "./features/Finance/Vouchers/ManualInvoice/Hotel";
import Chat_of_Account from "./features/Finance/Account/ChartOfAccountSlice";
import NotificationSlice from "./features/utilis/NotficationSlice";
import ChequeBookSlice from "./features/Finance/Account/BankBook/ChequeBookSlice";
import ApiCredentials from "./features/setupFeature/ApiCredential/ApiCredentials";
import ErApiCredentialSlice from "./features/setupFeature/ApiCredential/ErCredential";
import PkApiCredentialSlice from "./features/setupFeature/ApiCredential/PKCredentialSlice";
import AmadeusApiCredentialSlice from "./features/setupFeature/ApiCredential/AmadeusCredentialSlice";
import PaApiCredentialSlice from "./features/setupFeature/ApiCredential/PACredentialSlice";
import PFApiCredentialSlice from "./features/setupFeature/ApiCredential/PFCredentialSlice";
import FZApiCredentialSlice from "./features/setupFeature/ApiCredential/FZCredentialSlice";
import FlyJinnahApiCredentialSlice from "./features/setupFeature/ApiCredential/9PCredentialSlice";
import G9CredentialSlice from "./features/setupFeature/ApiCredential/G9CredentialSlice";
import WYApiCredentialSlice from "./features/setupFeature/ApiCredential/WYCredentialSlice";
import SabreApiCredentialSlice from "./features/setupFeature/ApiCredential/SabreCredentialSlice";
import homeSlice from "./features/home/homeSlice";
import FlightSearchSlice from "./features/home/FlightSearchSlice";
import rolesPermissionsSlice from "./features/agency/rolesPermissionSlice";
import FlightBookingList from "./features/group/FlightBookingList";
import VoucherEntry from "./features/Finance/Vouchers/VoucherEntry/Voucher";
import bookingDetails from "./features/booking/bookingDetailsSlice";
import bookingStatus from "./features/bookingStatus/bookingStatus";
import flightrequest from "./features/requests/flightrequest";
import FlightSlice from "./features/Finance/Vouchers/ManualInvoice/Flight";
import fligthBookingSlice from "./features/booking/fligthBookingSlice";
import VoucherSlice from "./features/Voucher/VoucherSlice";
import agencyPermissionsSlice from "./features/agency/agencyPermissionsSlice";
import SaleReportSlice from "./features/Finance/Sale/SaleReportSlice";
import TPApiCredentialSlice from "./features/setupFeature/ApiCredential/TPCredentialSlice";
import PaxRefundsSlicejs from "./features/PaxRefund/PaxRefundsSlice.js";
import StatementSlice from "./features/Statement/StatementSlice.js";
import updateSlice from "./features/booking/updateSlice.js";
import voidTicketSlice from "./features/booking/voidTicketSlice.js";
import agentPnrReducer from "./features/agentPnr/agentPnr.js";
import accStructureSlice from "./features/booking/accStructure.js";
import openingBalance from "./features/OpeningBalance/openingBalance.js";
// import accStructure from "./features/booking/accStructure.js";
const Store = configureStore({
  reducer: {
    openingBalance: openingBalance,
    // Stament
    agentPnr: agentPnrReducer,    accStructure: accStructureSlice,
    //Void
    voidTicket: voidTicketSlice,

    //update booking
    update: updateSlice,

    Statement: StatementSlice,
    // Pax Refund || Crdit Note
    PaxRefunds: PaxRefundsSlicejs,
    //landing page Slice
    landingPage: landingPageSlice,
    //voucheEntrie
    voucherEntries: VoucherSlice,
    //home page Slice
    home: homeSlice,

    // booking Details slice
    booking: bookingDetails,

    // requests section slice
    requestsSlice: requestsSlice,
    //flight requestion
    flightrequest: flightrequest,
    //user level slice
    roleAndPermissions: rolesPermissionsSlice,
    //user slice data
    usersSlice: usersSlice,
    //dashboardSlice
    dashboardSlice: dashboardSlice,
    //login Slice
    userloginslice: profileHeaderSlice,
    user: UserSlice,
    // make payments
    makePayments: MakePaymentsSlice,
    allPayments: PaymentsAllSlices,
    // visa Slices
    visaCountry: CountrySlice,
    visaType: VisaTypeSlice,
    visaTerm: VisaTermSlice,
    visaRates: VisaRateSlice,
    // NewAgencyRegistration
    newAgency: NewRegistrationSlice,
    // hotel slices
    hotel: HotelSlice,
    transfer: TransferSlice,
    visaSupplier: VisaSupplierSlice,
    transferSupplier: TransferSupplierSlice,
    hotelRoom: HotelRoomSlice,
    hotelRates: HotelRateSlice,
    // Add Flash news slices
    addFlashNews: AddFlashNewsSlice,
    promotion: PromotionSlice,
    circular: CircularSlice,
    flightGroup: FlightGroupSlice,
    bookingStatus: bookingStatus,
    // newSupplier
    supplier: NewSupplierSlice,
    //Agents slice
    agentsCategories: AgentsSlice,
    // Bank_curr_RoleSlice
    bank_curr_roleSlice: Bank_curr_RoleSlice,
    //Price airline supprole
    price_airlines_suppRoleSlice: Price_Airlines_SuppRoleSlice,

    // *************Agency section *************
    //Agency_doc_userlevel
    agency_doc_userlevel_Slice: Agency_Doc_Userlevel_Slice,
    // view home sitting
    banner_about_services_slice: Banner_About_Services_Slice,
    agencyPermissions: agencyPermissionsSlice,

    // Finance -> Voucher -> Manual Invoice -> hotel

    manualInv_hotel: Finance_Voucher_Manual_Invoice_Hotel,

    //voucher entry
    voucher_entry: VoucherEntry,

    // Finance -> Account -> Chart Of Account ->
    chartAccount: Chat_of_Account,

    chequeBook: ChequeBookSlice,

    // notification
    notification: NotificationSlice,

    //api credentials
    apiCredentials: ApiCredentials,

    erCredential: ErApiCredentialSlice,

    pkCredential: PkApiCredentialSlice,

    amaduesCredential: AmadeusApiCredentialSlice,

    paCredential: PaApiCredentialSlice,

    pfCredential: PFApiCredentialSlice,

    fzCredential: FZApiCredentialSlice,

    flyJinnahCredential: FlyJinnahApiCredentialSlice,

    g9Credential: G9CredentialSlice,

    tpCredential: TPApiCredentialSlice,

    wyCredential: WYApiCredentialSlice,

    sabreCredential: SabreApiCredentialSlice,

    //flight search results
    FlightSearch: FlightSearchSlice,

    //fligth booking
    fligth_booking: fligthBookingSlice,

    ///// Fligt Booking slice
    FlightBookingList: FlightBookingList,

    ///// manual invoice Flight
    manual_inv_flight: FlightSlice,

    ///// sale Report
    SaleReport: SaleReportSlice,
  },
});
export default Store;
