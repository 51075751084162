import React, { useEffect, useState } from "react";
import Passanger from "./Passanger";
import SideBar from "./SideBar";
import SelectedFlight from "./SelectedFlights";
import SessionExpire from "./SessionExpire";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
  getCurrencyObj,
} from "../../../Utils/currencyExchange";
import { ClickAwayListener, TextField } from "@mui/material";
import { Form, Formik, ErrorMessage } from "formik";
import { asyncSearchAgencyData } from "../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import RefundAndExchange from "../FlightsTab/RefundExchange/RefundAndExchange";
import { useNavigate } from "react-router-dom";
import {
  setBookButtonDisable,
  setExpandFlightDetails,
} from "../../../redux/features/home/FlightSearchSlice";
import { getSectorsName } from "../../../Utils/FlightSector";
import { pricingairline } from "../../../Utils/airlinePricing";
import { ExclusiveSetors } from "../../../Utils/exclusiveSector";
import {
  asyncPostFligthBooking,
  setFligthBookingEmpty,
} from "../../../redux/features/booking/fligthBookingSlice";
import BtnLoader from "../../AppForm/BtnLoader";
import { TwoDecimalPoint } from "../../../Utils/FixedTwoDecimal";
import moment from "moment";
import Ancillary from "./checkout_ancillary/Ancillary";
import Timer from "./Timer";
// import Ancillary from "./checkout_ancillary/Ancillary";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableBooking, setBookingDisable] = useState(true);
  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const { newSupplier } = useSelector((state) => state.supplier);

  let { selectedFlight, bookButton, sialpnrData, returnFlight } = useSelector(
    (state) => state.FlightSearch
  );

  const supplierListItem = newSupplier?.find((it) =>
    it?.supp_name
      ?.toLocaleUpperCase()
      ?.includes(
        selectedFlight?.booking?.gds === "AMADEUS"
          ? selectedFlight?.gds
          : selectedFlight?.booking?.gds?.toLocaleUpperCase()
      )
  );
  const { agentGroup } = useSelector((state) => state.agentsCategories);

  const { searchAgency } = useSelector((state) => state.newAgency);
  const {
    bookingData,
    bookingLoading,
    error: bookingError,
  } = useSelector((state) => state.fligth_booking);
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  //airline
  const airline_Supplier = addAirLinesData?.find(
    (it) => it.code_alpha === selectedFlight?.code
  );

  // local storage
  const localsearchValues = JSON.parse(
    localStorage.getItem("flightSearchData")
  );

  const user = JSON.parse(localStorage.getItem("loginUser"));
  // Create an array of passenger types based on the counts
  const passengerTypes = [];

  const tomorrow = moment().add(6, "months").toDate();
  const adult_Date = moment().subtract(12, "years").toDate();
  const child_Date = moment().subtract(2, "year").toDate();
  const infant_Date = moment().subtract(0, "day").toDate();

  for (let i = 0; i < localsearchValues?.adultsCount; i++) {
    passengerTypes.push({
      pax_type: "ADLT",
      title: "MR",
      f_name: "",
      l_name: "",
      nationality: "",
      dob: adult_Date, // Initialize date fields with null
      doc_type: selectedFlight?.booking?.segments?.every(
        ({ segment_type }) => segment_type === "dom"
      )
        ? "CNIC"
        : "PASSPORT",
      doc_no: selectedFlight?.booking?.segments?.every(
        ({ segment_type }) => segment_type === "dom"
      )
        ? "11111-1111111-1"
        : "AC1234567",
      issue_country: "",
      expiry_date: tomorrow, // Initialize date fields with null
      inbound: {
        baggage: "",
        meal: "",
        seat: "",
        service: "",
      },
      outbound: {
        baggage: "",
        meal: "",
        seat: "",
        service: "",
      },
    });
  }

  for (let i = 0; i < localsearchValues?.childCount; i++) {
    passengerTypes.push({
      pax_type: "CHLD",
      title: "MSTR",
      f_name: "",
      l_name: "",
      nationality: "",
      dob: child_Date, // Initialize date fields with null
      doc_type: selectedFlight?.booking?.segments?.every(
        ({ segment_type }) => segment_type === "dom"
      )
        ? "CNIC"
        : "PASSPORT",
      doc_no: selectedFlight?.booking?.segments?.every(
        ({ segment_type }) => segment_type === "dom"
      )
        ? "11111-1111111-1"
        : "AC1234567",
      issue_country: "",
      expiry_date: tomorrow,
      inbound: {
        baggage: "",
        meal: "",
        seat: "",
        service: "",
      },
      outbound: {
        baggage: "",
        meal: "",
        seat: "",
        service: "",
      },
    });
  }

  for (let i = 0; i < localsearchValues?.infantNo; i++) {
    passengerTypes.push({
      pax_type: "INFT",
      title: "INF",
      f_name: "",
      l_name: "",
      nationality: "",
      dob: infant_Date, // Initialize date fields with null
      doc_type: selectedFlight?.booking?.segments?.every(
        ({ segment_type }) => segment_type === "dom"
      )
        ? "CNIC"
        : "PASSPORT",
      doc_no: selectedFlight?.booking?.segments?.every(
        ({ segment_type }) => segment_type === "dom"
      )
        ? "11111-1111111-1"
        : "AC1234567",
      issue_country: "",
      expiry_date: tomorrow, // Initialize date fields with null
      inbound: {
        baggage: "",
        meal: "",
        seat: "",
        service: "",
      },
      outbound: {
        baggage: "",
        meal: "",
        seat: "",
        service: "",
      },
    });
  }

  // || localsearchValues?.agent
  //     ? localsearchValues?.agent?.agent_name
  // localsearchValues?.agent ? localsearchValues?.agent?.email :
  // || localsearchValues?.agent ? localsearchValues?.agent?.phone :

  /////////////// initial values formik ////////////////
  const isAdmin = user && user.agent_id === 1;
  const agentName = isAdmin
    ? (localsearchValues?.agent && localsearchValues?.agent.agent_name) || null
    : user?.agents_comp?.agent_name;

  const email = isAdmin
    ? (localsearchValues?.agent && localsearchValues?.agent.email) || null
    : user?.email;

  const phone_no = isAdmin
    ? (localsearchValues?.agent && localsearchValues?.agent.mobile_no) || null
    : user?.mobile_no;

  const initialValues = {
    agent_name:
      agentName ||
      (localsearchValues?.agent && localsearchValues?.agent.agent_name) ||
      "",
    email:
      email ||
      (localsearchValues?.agent && localsearchValues?.agent.email) ||
      "",
    phone_no:
      phone_no ||
      (localsearchValues?.agent && localsearchValues?.agent.mobile_no) ||
      "",
    agent: localsearchValues?.agent ? localsearchValues?.agent : "",
    pax: passengerTypes,
  };

  const validationSchema = Yup.object().shape({
    pax: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Required"),
        f_name: Yup.string().required("Required"),
        l_name: Yup.string().required("Required"),
        doc_type: Yup.string().required("Required"),
        doc_no: Yup.string().required("Required"),
      })
    ),
    agent_name: Yup.string().required("Agent Name is Required"),
    email: Yup.string().required("Email is Required"),
    phone_no: Yup.string().required("Phone Number is Required"),
  });

  // if pricing is define enable booking button else disable it
  const setBookButton = (values) => {
    const pricing = airlinePricing?.find(
      (item) =>
        item.bg_id === values?.agent?.agent_grp_id &&
        item?.add_airline?.code_alpha === selectedFlight?.code
    );
    if (pricing) {
      dispatch(setBookButtonDisable(false));
    } else {
      dispatch(setBookButtonDisable(true));
    }
    return pricing;
  };

  useEffect(() => {
    if (!isAdmin) setBookingDisable(user?.agents_comp?.agent_book_permission);
    dispatch(setBookButtonDisable(false));
    dispatch(setFligthBookingEmpty());
  }, []);

  const setGds =
    selectedFlight?.booking?.gds === "SABRE" ||
    selectedFlight?.booking?.gds === "AMADEUS";
  const [cancel, setCancel] = useState(false);
  const [duration, setDuration] = useState(setGds ? 10 : 5);
  const [timer, setTimer] = useState("");
  useEffect(() => {
    if (timer?.minutes <= 0 && timer?.seconds <= 0) {
      setCancel(true);
    }
  }, [timer]);

  useEffect(() => {
    !selectedFlight && navigate("/search-results");
    window.scrollTo(0, 0); // Scroll to the top of the page
    dispatch(setExpandFlightDetails("serene1"));
  }, [dispatch]);

  //////// get prices
  const getPrices = (values) => {
    // airline pricing
    selectedFlight = flights[flights.length - 1];
    const pricing = airlinePricing?.find(
      (item) =>
        item.bg_id === values?.agent?.agent_grp_id &&
        item?.add_airline?.code_alpha === selectedFlight?.code
    );
    //airline
    const airline = addAirLinesData?.find(
      (it) => it.code_alpha === selectedFlight?.code
    );

    const adult = pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(selectedFlight?.adultBaseFare)),
      Number(CurrencyExchange(selectedFlight?.adultTaxes)),
      getSectorsName(
        localsearchValues?.locationDep,
        localsearchValues?.locationArrival
      ),
      ExclusiveSetors(localsearchValues, pricing, selectedFlight?.isExclusiveSector),
      selectedFlight?.isCodeShare,
      selectedFlight?.isEconomy,
      selectedFlight?.segmentLength,
    );
    const child = pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(selectedFlight?.childBaseFare)),
      Number(CurrencyExchange(selectedFlight?.childTaxes)),
      getSectorsName(
        localsearchValues?.locationDep,
        localsearchValues?.locationArrival
      ),
      ExclusiveSetors(localsearchValues, pricing, selectedFlight?.isExclusiveSector),
      selectedFlight?.isCodeShare,
      selectedFlight?.isEconomy,
      selectedFlight?.segmentLength,
    );
    const infant = pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(selectedFlight?.infantBaseFare)),
      Number(CurrencyExchange(selectedFlight?.infantTaxes)),
      getSectorsName(
        localsearchValues?.locationDep,
        localsearchValues?.locationArrival
      ),
      ExclusiveSetors(localsearchValues, pricing, selectedFlight?.isExclusiveSector),
      selectedFlight?.isCodeShare,
      selectedFlight?.isEconomy,
      selectedFlight?.segmentLength,
      false
    );
    return { ADLT: adult, CHLD: child, INFT: infant };
  };

  ////states for ancillary baggage
  const [totalTripCharges, setTotalTripCharges] = useState(0);
  const [anciCharges, setAnciCharges] = useState(0);
  const [selectedAncillaries, setSelectedAncillaries] = useState([]);
  // const flights = [selectedFlight, returnFlight];

  const flights = returnFlight
    ? [selectedFlight, returnFlight]
    : [selectedFlight];

  const M_flights = selectedFlight?.flight?.itenaries
    ? selectedFlight?.flight?.itenaries
    : [selectedFlight?.flight];

  return (
    <div className="col-11 mx-auto p-5 pb-0">
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={false}
        onSubmit={(values, { resetForm }) => {
          let pricing = setBookButton(values);
          if (!pricing) return;
          let prices = getPrices(values);
          const segments = flights.flatMap((flight) => [
            ...(flight?.booking?.segments || []),
          ]); // selectedFlight?.booking?.segments
          selectedFlight = flights[flights?.length - 1];

          let booking = {
            gds: selectedFlight?.booking?.gds, // should be gds code
            pnr: sialpnrData?.Data || "", // returned in pricing response
            agent_name: selectedFlight?.booking?.agent_name,
            bundleIdOutbound: selectedFlight.bundleIdOutbound,
            bundleIdInbound: selectedFlight.bundleIdInbound,
            seg_r: selectedFlight.booking.seg_r,
            shopRefId: selectedFlight?.shopRefId,
            oferRefId: selectedFlight?.oferRefId,
            oferItemRefId: selectedFlight?.oferItemRefId,

            bookdatetime: moment(new Date()).format("YYYY-MM-DDTHH:MM"),
            agent: {
              name: values?.agent_name, // should be full name having space in between
              email: values?.email, // should be in proper email form
              phone: values?.phone_no, // should be without country code
              country_code: "92", // country code without + sign
            },

            paxes: values.pax.map((item) => {
              const priceObj = prices[item.pax_type];

              const anciCharges =
                ~~item?.outbound?.baggage?.split(",")[1] +
                ~~item?.outbound?.meal?.price +
                ~~item?.outbound?.seat?.split(",")[1];
              const anciChargesInBound =
                item.inbound &&
                  (item.inbound.meal || item.inbound.baggage || item.inbound.seat)
                  ? ~~item.inbound.baggage?.split(",")[1] +
                  ~~item.inbound.meal?.price +
                  ~~item.inbound.seat?.split(",")[1]
                  : 0;

              return {
                title: item.title, // MR | MS | MRS | MSTR | INF
                first_name: item.f_name,
                last_name: item.l_name,
                nationality: item.nationality,
                DOB: item.dob,
                doc_type: item.doc_type, // CNIC | Passport
                doc_no: item.doc_no, // 11111-1111111-1 | asdf12345
                doc_issue_country: item.issue_country,
                doc_expiry: item.expiry_date,
                pax_type: item.pax_type, // ADLT | CHLD | INFT,
                pax_taxes: selectedFlight[`${item.pax_type}_TAXES`],
                baseFare_d_cur: TwoDecimalPoint(
                  CurrencyExchangeToPKR(priceObj?.BasePrice)
                ),
                ...(selectedFlight?.[`${item.pax_type}_FARE`] || {}),
                baseFare_asg_cur: TwoDecimalPoint(priceObj?.BasePrice),
                baseFare_s_cur: TwoDecimalPoint(priceObj?.BasePrice),
                ttl_tax_d_cur: TwoDecimalPoint(
                  CurrencyExchangeToPKR(priceObj?.Taxes) +
                  anciCharges +
                  anciChargesInBound
                ), //
                ttl_tax_asg_cur:
                  TwoDecimalPoint(priceObj?.Taxes) +
                  TwoDecimalPoint(CurrencyExchange(anciCharges)) +
                  TwoDecimalPoint(CurrencyExchange(anciChargesInBound)),
                ttl_tax_s_cur:
                  TwoDecimalPoint(priceObj?.Taxes) +
                  TwoDecimalPoint(CurrencyExchange(anciCharges)) +
                  TwoDecimalPoint(CurrencyExchange(anciChargesInBound)),

                ttl_sup_pay_d_cur: TwoDecimalPoint(
                  CurrencyExchangeToPKR(priceObj?.supplier_total_fare)
                ),
                ttl_sup_pay_s_cur: TwoDecimalPoint(
                  priceObj?.supplier_total_fare
                ),
                ttl_agt_pay_s_cur:
                  TwoDecimalPoint(priceObj?.total_fare) +
                  TwoDecimalPoint(CurrencyExchange(anciCharges)) +
                  TwoDecimalPoint(CurrencyExchange(anciChargesInBound)),
                ttl_agt_pay_d_cur: TwoDecimalPoint(
                  CurrencyExchangeToPKR(priceObj?.total_fare) +
                  anciCharges +
                  anciChargesInBound
                ),
                // ttl_agt_pay_d_cur: TwoDecimalPoint(
                //   selectedFlight[
                //     `${item.pax_type?.toLocaleLowerCase()}_ttl_d_curr`
                //   ]
                // ),
                ttl_agt_pay_asg_cur:
                  TwoDecimalPoint(priceObj?.total_fare) +
                  TwoDecimalPoint(CurrencyExchange(anciCharges)) +
                  TwoDecimalPoint(CurrencyExchange(anciChargesInBound)),

                agt_psf_per_d_s_cur: TwoDecimalPoint(
                  priceObj?.agt_psf_per_d_s_cur
                ),

                agt_psf_d_cur: TwoDecimalPoint(
                  CurrencyExchangeToPKR(priceObj?.psf)
                ),
                agt_psf_asg_cur: TwoDecimalPoint(priceObj?.psf),
                agt_psf_s_cur: TwoDecimalPoint(priceObj?.psf),

                agt_gst_d_cur: TwoDecimalPoint(
                  CurrencyExchangeToPKR(priceObj?.agent_gst)
                ),
                agt_gst_asg_cur: TwoDecimalPoint(priceObj?.agent_gst),
                agt_gst_s_cur: TwoDecimalPoint(priceObj?.agent_gst),

                agt_sp_d_cur: TwoDecimalPoint(
                  CurrencyExchangeToPKR(priceObj?.sp)
                ),
                agt_sp_asg_cur: TwoDecimalPoint(priceObj?.sp),
                agt_sp_s_cur: TwoDecimalPoint(priceObj?.sp),

                agt_wht_d_cur: TwoDecimalPoint(
                  CurrencyExchangeToPKR(priceObj?.agent_wht)
                ),
                agt_wht_asg_cur: TwoDecimalPoint(priceObj?.agent_wht),

                agt_wht_per_d_s_cur: TwoDecimalPoint(
                  priceObj?.agt_wht_per_d_s_cur
                ),
                agt_gst_per_d_s_cur: TwoDecimalPoint(
                  priceObj?.agt_gst_per_d_s_cur
                ),
                agt_sp_per_d_s_cur: TwoDecimalPoint(
                  priceObj?.agt_sp_per_d_s_cur
                ),

                sup_psf_per_d_s_cur: TwoDecimalPoint(
                  priceObj?.sup_psf_per_d_s_cur
                ),
                sup_psf_d_cur: TwoDecimalPoint(
                  TwoDecimalPoint(
                    CurrencyExchangeToPKR(
                      (priceObj?.BasePrice / 100) *
                      priceObj?.sup_psf_per_d_s_cur
                    )
                  )
                ),
                sup_psf_asg_cur: TwoDecimalPoint(
                  (priceObj?.BasePrice / 100) * priceObj?.sup_psf_per_d_s_cur
                ),

                sup_gst_per_d_s_cur: TwoDecimalPoint(
                  priceObj?.sup_gst_per_d_s_cur
                ),
                sup_gst_d_cur: TwoDecimalPoint(
                  CurrencyExchangeToPKR(priceObj?.sup_gst_d_cur)
                ),

                sup_sp_per_d_s_cur: TwoDecimalPoint(
                  priceObj?.sup_sp_per_d_s_cur
                ),
                sup_sp_d_cur: TwoDecimalPoint(
                  CurrencyExchangeToPKR(
                    (priceObj?.BasePrice / 100) * priceObj?.sup_sp_per_d_s_cur
                  )
                ),

                sup_wht_per_d_s_cur: TwoDecimalPoint(
                  priceObj?.sup_wht_per_d_s_cur
                ),
                sup_wht_d_cur: TwoDecimalPoint(
                  CurrencyExchangeToPKR(priceObj?.airline_wht)
                ),
                sup_wht_s_cur: TwoDecimalPoint(priceObj?.airline_wht),

                unappropriate: TwoDecimalPoint(priceObj?.income),

                income_type: priceObj?.income_type,
                other_income: TwoDecimalPoint(priceObj?.other_income),
                other_income_type: priceObj?.other_income_type,
                supplier_type: "flight",
                inbound: item.inbound,
                flightss: [item.inbound, item.outbound],
                outbound: item.outbound,
              };
            }),

            segments: segments,
            tp_ur_code: selectedFlight?.booking?.tp_ur_code, // mandatory for travelport only
            platting_carrier: selectedFlight?.booking?.platting_carrier, // mandatory for travelport only
            cookie: selectedFlight?.cookie, // mandatory for travelport only
            transaction_identifier: selectedFlight?.transaction_identifier, // mandatory for travelport only
            tp_ssr_verssion: selectedFlight?.booking?.tp_ssr_verssion, // mandatory for travelport only
            tp_trace_id: selectedFlight?.booking?.tp_trace_id, // mandatory for travelport only
            adult_count: localsearchValues?.adultsCount,
            child_count: localsearchValues?.childCount,
            infant_count: localsearchValues?.infantNo,

            ttl_agent_fare: Number(selectedFlight?.ttl_agent_fare), // "calculated price total"
            ttlagent_curr_fare: Number(selectedFlight?.ttlagent_curr_fare),
            ttl_supplier_fare: Number(selectedFlight?.ttl_supplier_fare),
            ttlsup_curr_fare: Number(selectedFlight?.ttlsup_curr_fare),

            user_id: user?.user_id,
            agent_acc_id: values?.agent?.acc_id || user?.agents_comp?.acc_id, // agent id in case of selected other wise logged in user id
            supplier_acc_id: supplierListItem?.acc_id, // supplier id
            roe: getCurrencyObj()?.roe, // id rate of exchange
            curr: getCurrencyObj()?.currency_id, // currency id
            remarks: `${user?.user_id}-${user?.f_name || ""} ${user?.l_name || ""
              } auto booking`,
            ...selectedFlight?.booking?.fare_req_data,
          };

          dispatch(asyncPostFligthBooking({ booking, navigate }));
        }}
      >
        {({ values, setFieldValue, handleChange }) => {
          return (
            <Form>
              <div className="d-flex justify-content-between">
                <div className="col-sm-9">
                  <div className="me-4">
                    <div>
                      <SelectedFlight
                        cancel={cancel}
                        duration={duration}
                        setTimer={setTimer}
                        timer={timer}
                        availabilityTime={JSON.parse(
                          localStorage.getItem("timer")
                        )}
                      />
                    </div>

                    <div className="mt-4 bg-white borderradius shadow-sm p-3">
                      <RefundAndExchange />
                    </div>
                    <div className="mt-4">
                      <Passanger
                        setFieldValue={setFieldValue}
                        values={values}
                        handleChange={handleChange}
                        ErrorMessage={ErrorMessage}
                      />
                    </div>
                    {/* {selectedFlight?.code === '9P' ? (
                    <div className="mt-4 bg-white borderradius shadow-sm p-3">
                    {console.log(values)}
                    {console.log()}

                    <Ancillary
                      setFieldValue={setFieldValue}
                      values={values}
                      index={0}
                      setTripCharges={(value) => {
                        setTotalTripCharges(totalTripCharges + value);
                        setAnciCharges(anciCharges + value);
                      }}
                      selectedAncillaries={selectedAncillaries}
                      setSelectedAncillaries={setSelectedAncillaries}
                    />
                    </div>
                  ) : null} */}
                    {selectedFlight?.code === "9P"
                      ? M_flights.map((flight, index) =>
                        selectedFlight?.booking?.segments?.filter(
                          ({ trip_type }) =>
                            trip_type ===
                            (index === 0 ? "outbound" : "inbound")
                        )?.length === 1 ? (
                          <div
                            key={index}
                            className="mt-4 bg-white borderradius shadow-sm p-3"
                          >
                            <h5>
                              {index === 0
                                ? "Outbound Flight Ancillaries"
                                : "Return Flight Ancillaries"}
                            </h5>
                            <Ancillary
                              flight={flight}
                              sel_flight={selectedFlight}
                              setFieldValue={setFieldValue}
                              values={values}
                              index={index}
                              // setTripCharges={(value) => {
                              //   setTotalTripCharges(totalTripCharges + value);
                              //   setAnciCharges(anciCharges + value);
                              // }}
                              selectedAncillaries={selectedAncillaries[index]}
                              setSelectedAncillaries={(selected) => {
                                const newAncillaries = [
                                  ...selectedAncillaries,
                                ];
                                newAncillaries[index] = selected;
                                setSelectedAncillaries(newAncillaries);
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )
                      )
                      : null}
                    {/* ////////////////////// agent contact details ///////////////////// */}

                    <div className="mt-4">
                      <div className="p-4 bg-white shadow-sm borderradius">
                        <h4>Agent - PAX Contact</h4>

                        <div className="row py-3">
                          <div className="col-sm-3 relative">
                            <TextField
                              fullWidth
                              variant="standard"
                              label="Agent Name"
                              autoComplete="off"
                              disabled={user?.agent_id !== 1}
                              name="agent_name"
                              value={values.agent_name}
                              onChange={(e) => {
                                const value = e.target.value;
                                setFieldValue("agent_name", value);
                                setFieldValue("agent", "");
                                setFieldValue("email", "");
                                setFieldValue("phone_no", "");
                                dispatch(asyncSearchAgencyData(value)); // Fetch data based on selected agent_name
                              }}
                              onClick={() => setagentDRopDwn(!agentDRopDwn)}
                              onFocus={(e) => {
                                e.target.select();
                              }}
                            />

                            {agentDRopDwn ? (
                              <ClickAwayListener
                                onClickAway={() => setagentDRopDwn(false)}
                              >
                                <div className="agent_dropdown">
                                  {searchAgency?.length > 0 ? (
                                    searchAgency?.map((item, indx) => {
                                      return (
                                        <div
                                          key={indx}
                                          className="p-3"
                                          onClick={(e) => {
                                            setFieldValue(
                                              "agent_name",
                                              item.agent_name
                                            );
                                            setFieldValue("agent", item);
                                            setFieldValue("email", item?.email);
                                            setFieldValue(
                                              "phone_no",
                                              item?.phone
                                            );
                                            setagentDRopDwn(false);
                                            //agent group
                                            const agent_grp = agentGroup?.find(
                                              (it) =>
                                                it.agent_grp_id ===
                                                item?.agent_grp_id
                                            ); //set local storage
                                            localStorage.setItem(
                                              "currency",
                                              JSON.stringify(
                                                agent_grp
                                                  ? agent_grp?.currency
                                                  : ""
                                              )
                                            );
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {item.agent_name}
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="p-3">
                                      No record available.
                                    </div>
                                  )}
                                </div>
                              </ClickAwayListener>
                            ) : null}
                            <ErrorMessage
                              name="agent_name"
                              component={"div"}
                              className="text-danger mt-1"
                            />
                          </div>
                          <div className="col-sm-3">
                            <TextField
                              fullWidth
                              label="Email"
                              type="email"
                              id="standard-basic"
                              autoComplete="off"
                              variant="standard"
                              // disabled={user?.user_id !== 1}
                              name="email"
                              value={values.email}
                              onChange={(e) => {
                                const value = e.target.value;
                                setFieldValue("email", value);
                              }}
                            />
                            <ErrorMessage
                              name="email"
                              component={"div"}
                              className="text-danger mt-1"
                            />
                          </div>
                          <div className="col-sm-3">
                            <TextField
                              fullWidth
                              id="standard-basic"
                              label="Phone Number"
                              autoComplete="off"
                              variant="standard"
                              // disabled={user?.user_id !== 1}
                              name="phone_no"
                              value={values.phone_no}
                              onChange={(e) => {
                                const value = e.target.value;
                                setFieldValue("phone_no", value);
                              }}
                            />
                            <ErrorMessage
                              name="phone_no"
                              component={"div"}
                              className="text-danger mt-1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  ////////////////// TERMS AND CONDITIONS //////////////// */}
                    <div className="mt-4">
                      <div className="p-4 bg-white shadow-sm borderradius">
                        <h4>Terms and Condition</h4>
                        <div className="border-top d-flex align-items-center py-3 pt-4">
                          <div className="checkbox-rect">
                            <input type="checkbox" id="term_condition" />
                            <label
                              htmlFor="term_condition"
                              className="color_primary pt-1"
                            >
                              <span className="text-danger">I Accept</span>{" "}
                              Terms & Condition{" "}
                              <span className="text-danger">And</span> Policies
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  ////  BOOK BUTTON ////////// */}
                    {!cancel ? (
                      <div className="my-4">
                        {bookButton && values?.agent?.agent_grp_id ? (
                          <h5 className="text-danger">
                            Pricing for this airline is not set, Please contact
                            with your support team.
                          </h5>
                        ) : null}

                        <button
                          type="submit"
                          className="button_style"
                          disabled={bookingLoading || !disableBooking}
                        >
                          {bookingLoading ? <BtnLoader /> : "Book Now"}
                        </button>
                        {bookingError ? (
                          <h5 className="text-danger pt-2">
                            {typeof bookingError === "string"
                              ? bookingError
                              : "Error Occured while Booking"}{" "}
                          </h5>
                        ) : null}
                      </div>
                    ) : (
                      <div className="my-4">
                        <button className="button_style" disabled={cancel}>
                          Book Now
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {/*  //////////////// SIDE BAR  ///////////////////////// */}
                <div className="col-sm-3 custom-sticky">
                  <div className="custom-sticky">
                    {/* <SessionExpire /> */}
                    <div className="shadow-sm borderradius p-4 mb-3 bg-white">
                      <h4>
                        Available Limit:{" "}
                        <span
                          className={
                            values?.agent &&
                              values?.agent?.credit_limit -
                              values?.agent?.credit_used >=
                              0
                              ? "color_primary"
                              : "text-danger"
                          }
                        >
                          {values?.agent &&
                            `${CurrencyCode()} ${TwoDecimalPoint(
                              CurrencyExchange(
                                values?.agent?.credit_limit -
                                values?.agent?.credit_used ||
                                user?.agents_comp?.credit_limit -
                                user?.agents_comp?.credit_used
                              )
                            )}`}
                        </span>
                      </h4>
                    </div>
                    <SideBar
                      agent={values.agent}
                      selectedAncillaries={selectedAncillaries}
                      values={values}
                      isCodeShare={selectedFlight?.isCodeShare}
                      isEconomy={selectedFlight?.isEconomy}
                      segmentLength={selectedFlight?.segmentLength}
                      isExclusiveSector={selectedFlight?.isExclusiveSector}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Checkout;
