import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  MdOutlineFlightTakeoff,
  MdOutlineFlightLand,
  MdCheck,
  MdRemoveRedEye,
  MdFlight,
  MdOutlineRestaurant,
  MdSwapHoriz,
  MdOutlineAirlineSeatReclineNormal,
} from "react-icons/md";
import { BsBagCheck, BsHandbag } from "react-icons/bs";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { useDispatch, useSelector } from "react-redux";
import { LightTooltip } from "./LightTooltip";
import {
  set_Airline_filter_drp,
  set_All_Prices_Array,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { ExclusiveSetors } from "../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import { FaChevronCircleLeft, FaChevronCircleRight, FaSuitcase, FaSuitcaseRolling } from "react-icons/fa";
import Slider from "react-slick";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import { NODEURL } from "../../../../redux/features/ApiConfig";
import { Box } from "@mui/material";
import { CgMenuGridR } from "react-icons/cg";
import { RefundExchange } from "../../../Common/RefundExchange";

/////////////// Sial /////////////
function SereneApi({
  data,
  serene,
  index,
  isExpanded,
  toggleDetail,
  selectedFare,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("Fare options");
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  const { selectedFlight } = useSelector((state) => state.FlightSearch);

  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  //local storage data
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const user = JSON.parse(localStorage.getItem("loginUser"));

  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "ER"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "ER");

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  // calculate the lowest fare price
  const lowestFarePrice = (paxes) => {
    paxes = Array.isArray(paxes) ? paxes : [paxes];
    const adult = paxes[0];
    const child = searchValues?.childCount > 0 ? paxes[1] : {};
    const infant = searchValues?.infantNo > 0 ? paxes[paxes?.length - 1] : {};
    let newFare =
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(adult?.Price?.BaseAmount?.["#text"]))),
        Number(
          CurrencyExchange(
            Number(adult?.Price?.TaxSummary?.TotalTaxAmount?.["#text"])
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(child?.Price?.BaseAmount?.["#text"]))),
        Number(
          CurrencyExchange(
            Number(child?.Price?.TaxSummary?.TotalTaxAmount?.["#text"])
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(infant?.Price?.BaseAmount?.["#text"]))),
        Number(
          CurrencyExchange(
            Number(infant?.Price?.TaxSummary?.TotalTaxAmount?.["#text"])
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.infantNo || 0);

    return `${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
  };

  const singlePaxFare = (pax, paxCount) => {
    let newFare =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(pax?.Price?.BaseAmount?.["#text"]))),
        Number(
          CurrencyExchange(
            Number(pax?.Price?.TaxSummary?.TotalTaxAmount?.["#text"])
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * paxCount || 0;

    return `${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
  };

  //discount calculation
  const DiscountPrice = (paxes) => {
    paxes = Array.isArray(paxes) ? paxes : [paxes];
    const adult = paxes[0];
    const child = searchValues?.childCount > 0 ? paxes[1] : {};
    const infant = searchValues?.infantNo > 0 ? paxes[paxes?.length - 1] : {};
    let newDiscount =
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(adult?.Price?.BaseAmount?.["#text"]))),
        Number(
          CurrencyExchange(
            Number(adult?.Price?.TaxSummary?.TotalTaxAmount?.["#text"])
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(child?.Price?.BaseAmount?.["#text"]))),
        Number(
          CurrencyExchange(
            Number(child?.Price?.TaxSummary?.TotalTaxAmount?.["#text"])
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(Number(infant?.Price?.BaseAmount?.["#text"]))),
        Number(
          CurrencyExchange(
            Number(infant?.Price?.TaxSummary?.TotalTaxAmount?.["#text"])
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.infantNo || 0);

    if (newDiscount > 0) {
      return `OFF ${CurrencyCode()} ${TwoDecimalPoint(newDiscount)}`;
    } else {
      return "";
    }
    //  return `Off ${CurrencyCode()} ${TwoDecimalPoint(newDiscount)}`;
  };

  // calculate the supplier fare price
  const supplierPrice = (paxes) => {
    paxes = Array.isArray(paxes) ? paxes : [paxes];
    const adult = paxes[0];
    const child = searchValues?.childCount > 0 ? paxes[1] : null;
    const infant = searchValues?.infantNo ? paxes[paxes?.length - 1] : null;

    let newFare = Number(adult?.Price?.TotalAmount?.["#text"]);
    if (child) {
      newFare = newFare + Number(child?.Price?.TotalAmount?.["#text"]);
    }
    if (infant) {
      newFare = newFare + Number(infant?.Price?.TotalAmount?.["#text"]);
    }
    return TwoDecimalPoint(newFare);
  };
  //////////// convert time to required formate
  const timeDuration = (time1, time2) => {
    if (!time1 || !time2) {
      return null;
    }
    const depMoment = moment(time1);
    const arrMoment = moment(time2);
    const duration = moment.duration(arrMoment.diff(depMoment));
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };
  // get city name
  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };
  ///////////////////
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }
  /// USE EFFECT
  useEffect(() => {
    dispatch(set_Airline_filter_drp("PF"));
    dispatch(
      set_All_Prices_Array(
        lowestFarePrice(data?.BAGGAGE_FARE[0]?.FARE_PAX_WISE)?.split(" ")[1]
      )
    );
  }, [dispatch]);

  const origin = serene?.segments?.at(0);
  const destination = serene?.segments?.at(serene?.segments?.length - 1);

  return (
    <>
      <div
        className="search_engin_result_box flight_card_shadow bg-white"
        id={`sial${index}`}
      >
        <div
          className="shadow cursorpointer"
          onClick={() => {
            toggleDetail(index);
            setTimeout(() => {
              !isExpanded && scrollToElement(`sial${index}`);
            }, 200);
          }}
        >
          <div className="row align-items-center">
            <div className="col-2">
              <div className="px-2">
                <div className="text-center">
                  <img
                    src={"/NEW_LOGO/ER.png"}
                    alt="airsial"
                    width={90}
                    height={90}
                  />
                </div>
                <p className="text-center">
                  {serene?.segments
                    ?.map(
                      (
                        segment
                      ) => `${segment?.MarketingCarrierInfo?.CarrierDesigCode}
                            ${segment?.MarketingCarrierInfo?.MarketingCarrierFlightNumberText}`
                    )
                    ?.join(" - ")}
                </p>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightTakeoff
                      size={30}
                      className="color_primary"
                    />
                  </div>
                  <div>
                    <h6>
                      {moment(origin?.Dep?.AircraftScheduledDateTime).format(
                        "ddd DD-MMM-YY"
                      )}
                    </h6>
                    <h6 className="my-3">
                      {origin?.Dep?.AircraftScheduledDateTime?.split("T")
                        ?.at(1)
                        ?.slice(0, 5)}
                    </h6>
                    <h6>
                      {origin?.Dep?.IATA_LocationCode} - {getCity(locDeparture)}
                    </h6>
                  </div>
                </div>
                <div>
                  <h6 className="text-center">
                    {timeDuration(
                      origin?.Dep?.AircraftScheduledDateTime,
                      destination?.Arrival?.AircraftScheduledDateTime
                    )}
                  </h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">Non-Stop</h6>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightLand size={30} className="color_primary" />
                  </div>

                  <div>
                    <h6>
                      {moment(
                        destination?.Arrival?.AircraftScheduledDateTime
                      ).format("ddd DD-MMM-YY")}
                    </h6>
                    <h6 className="my-3">
                      {destination?.Arrival?.AircraftScheduledDateTime?.split(
                        "T"
                      )
                        ?.at(1)
                        ?.slice(0, 5)}
                    </h6>
                    <h6>
                      {destination?.Arrival?.IATA_LocationCode} -{" "}
                      {getCity(locArrival)}
                    </h6>
                  </div>
                </div>
              </div>{" "}
            </div>

            <div className="col-3">
              <div className="shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body">
                {
                  <>
                    <h6 className="text-danger">
                      {DiscountPrice(
                        selectedFare
                          ? selectedFlight?.fare?.OfferItem?.FareDetail
                          : serene?.fares?.at(0)?.OfferItem?.FareDetail
                      )}
                    </h6>
                    <div className="d-flex align-items-end gap-3">
                      <h4>
                        {lowestFarePrice(
                          selectedFare
                            ? selectedFlight?.fare?.OfferItem?.FareDetail
                            : serene?.fares?.at(0)?.OfferItem?.FareDetail
                        )}
                      </h4>
                      <img
                        src={"/NEW_LOGO/ER.png"}
                        alt=""
                        width={40}
                        height={40}
                      />
                    </div>
                    {user?.agents_comp?.agent_grp_show ? (
                      <div className="fw-bold fs-5">{data?.agent_name}</div>
                    ) : null}

                    <button
                      onClick={() => toggleDetail(index)}
                      className="button_style w-75 my-3"
                      type="button"
                    >
                      <div>
                        <MenuOpenIcon className="me-1" />
                        Flight Detail
                      </div>
                    </button>
                  </>
                }
              </div>
            </div>
          </div>
        </div>

        {isExpanded ? (
          <div className="p-4">
            {/* Tabs start */}
            <div className="d-flex mb-4 flight_details_tabs">
              {["Fare options", "Flight details"].map((item, index) => {
                const active = selectedTab === item;
                return (
                  <button
                    key={index}
                    onClick={() => setSelectedTab(item)}
                    className={active ? "active" : ""}
                  >
                    {item}
                  </button>
                );
              })}
            </div>

            {selectedTab === "Flight details" && (
              <div className="row rounded-2 shadow-sm border p-4">
                <div className="row rounded-2 search_engin_result_box bg-white p-4">
                  {serene.segments?.map((item, inx, array) => {
                    return (
                      <ConnectedFlight
                        item={item}
                        inx={inx}
                        calculateTotalTimeDifference={timeDuration}
                        fligthSegment={array}
                        operatedAirline={"ER"}
                        convertTime={timeDuration}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {/* /////// additional fares /////// */}

            {selectedTab === "Fare options" ? (
              <div className="row rounded-2 search_engin_result_box bg-white p-4">
                <Fares
                  fares={serene?.fares}
                  sereneFLT={serene}
                  singPaxFareTotal={singlePaxFare}
                  lowestFarePrice={lowestFarePrice}
                  supplierPrice={supplierPrice}
                  perPaxPrice={singlePaxFare}
                  searchValues={searchValues}
                  selectedFare={selectedFare}
                  DiscountPrice={DiscountPrice}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
}

const Fares = ({
  fares,
  searchValues,
  lowestFarePrice,
  supplierPrice,
  perPaxPrice,
  sereneFLT,
  selectedFare,
  DiscountPrice
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const CustomPrevArrow = (props) => {
    if (fares?.length < 4) return null;
    return (
      <div
        onClick={props.onClick}
        className="position-absolute top-50 translate-middle-y cursor-pointer custom-left-position custom-text-color"
      >
        <FaChevronCircleLeft size={30} />
      </div>
    )
  }
  const CustomNextArrow = (props) => {
    if (fares?.length < 4) return null;
    return (
      <div
        onClick={props.onClick}
        className="position-absolute top-50 translate-middle-y cursor-pointer custom-right-position custom-text-color custom-z-index"
      >
        <FaChevronCircleRight size={30} />
      </div>
    )
  }
  var settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: fares?.length > 4 ? 4 : fares.length,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const getPaxSupplierPrice = (pax, count) => {
    const basePrice =
      Number(CurrencyExchange(Number(pax?.Price?.BaseAmount?.["#text"]))) || 0;
    const taxes =
      Number(pax?.Price?.TaxSummary?.TotalTaxAmount?.["#text"]) || 0;
    return { basePrice, taxes };
  };

  let selectedpaxes = selectedFare?.fare?.OfferItem?.FareDetail;
  selectedpaxes = Array.isArray(selectedpaxes) ? selectedpaxes : [selectedpaxes];
  const adult = selectedpaxes[0];
  const child = searchValues?.childCount > 0 ? selectedpaxes[1] : {};
  const infant = searchValues?.infantNo > 0 ? selectedpaxes[selectedpaxes?.length - 1] : {};

  let baggage =
    selectedFare?.fare?.BaggageAllowance?.baggage
  baggage = baggage
    ? baggage
    : "No Baggage";

  return (
    <>
      {selectedFare ?
        (
          <div>
            <div className="fs-4 theme-text-color mb-3 fw-bold">
              {selectedFare?.fare?.classList?.Name}
            </div>
            <Box display={"flex"} alignItems={"center"}>
              <Box>
                <FaSuitcaseRolling size={25} className='color_primary' />
              </Box>
              <h6 className='ps-2 pt-2'>Upto 7 kg</h6>
            </Box>

            <Box display={"flex"} alignItems={"center"} pt={2}>
              <Box>
                <FaSuitcase size={22} className='color_primary' />
              </Box>
              <h6 className='ps-2 pt-2'>{baggage}</h6>
            </Box>
            <Box display={"flex"}
              alignItems={"center"}
              pt={2}
            >
              <MdOutlineRestaurant
                size={25}
                className="color_primary"
              />
              <h6 className="ps-2 pt-2">Meal: System Allocated</h6>
            </Box>
            <Box display={"flex"}
              alignItems={"center"}
              pt={2}>
              <Box>
                <MdOutlineAirlineSeatReclineNormal size={25} className="color_primary" />
              </Box>
              <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
            </Box>
            <Box
              pt={2}
              display={"flex"}
              alignItems={"center"}
            >
              <MdSwapHoriz
                size={30}
                className="color_primary"
              />
              <h6 className="pt-1 ps-2 text-success">
                Refundable
              </h6>
            </Box>
            <div className="flight-detail-headings">
              <RefundExchange />
            </div>
            <div>
              <LightTooltip
                title={
                  <div className="p-3">
                    <div className="d-flex justify-content-between align-items-end">
                      <div className="d-flex me-4">
                        <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                        <h5>* {searchValues?.adultsCount}</h5>
                      </div>
                      <h6 style={{ minWidth: "7rem" }}>
                        {perPaxPrice(adult, 1)}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-end my-2">
                      <div className="d-flex">
                        <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                        <h5>* {searchValues?.childCount}</h5>
                      </div>
                      <h6 style={{ minWidth: "7rem" }}>
                        {perPaxPrice(child, 1)}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-end">
                      <div className="d-flex">
                        <h5 style={{ minWidth: "3rem" }}>INF</h5>
                        <h5>* {searchValues?.infantNo}</h5>
                      </div>
                      <h6 style={{ minWidth: "7rem" }}>
                        {perPaxPrice(infant, 1)}
                      </h6>
                    </div>
                  </div>
                }
                placement="top"
              >

                <button
                  type="button"
                  className="button_style px-4"
                >
                  {lowestFarePrice(selectedFare?.fare?.OfferItem?.FareDetail)}
                </button>
              </LightTooltip>
            </div>
          </div>
        )
        :
        (
          <Slider {...settings}>
            {fares?.map((brandFare, index) => {
              let paxes = brandFare?.OfferItem?.FareDetail;
              paxes = Array.isArray(paxes) ? paxes : [paxes];
              const adult = paxes[0];
              const child = searchValues?.childCount > 0 ? paxes[1] : {};
              const infant = searchValues?.infantNo ? paxes[paxes?.length - 1] : {};

              let baggage = brandFare?.BaggageAllowance?.baggage
              baggage = baggage ? baggage : "No Baggage";
              return (
                <div className="pt-4" key={index}>
                  <div key={brandFare?.OfferID}>
                    <div className="fs-4 theme-text-color mb-3 fw-bold">
                      {brandFare?.classList?.Name}
                    </div>

                    <Box display={"flex"} alignItems={"center"}>
                      <Box>
                        <FaSuitcaseRolling size={25} className='color_primary' />
                      </Box>
                      <h6 className='ps-2 pt-2'>Upto 7 kg</h6>
                    </Box>

                    <Box display={"flex"} alignItems={"center"} pt={2}>
                      <Box>
                        <FaSuitcase size={22} className='color_primary' />
                      </Box>
                      <h6 className='ps-2 pt-2'>{baggage}</h6>
                    </Box>
                    <Box display={"flex"}
                      alignItems={"center"}
                      pt={2}
                    >
                      <MdOutlineRestaurant
                        size={25}
                        className="color_primary"
                      />
                      <h6 className="ps-2 pt-2">Meal: System Allocated</h6>
                    </Box>
                    <Box display={"flex"}
                      alignItems={"center"}
                      pt={2}>
                      <Box>
                        <MdOutlineAirlineSeatReclineNormal size={25} className="color_primary" />
                      </Box>
                      <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
                    </Box>
                    <Box
                      pt={2}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <MdSwapHoriz
                        size={30}
                        className="color_primary"
                      />
                      <h6 className="pt-1 ps-2 text-success">
                        Refundable
                      </h6>
                    </Box>
                    <div className="flight-detail-headings">
                      <RefundExchange />
                    </div>

                    {true ? (
                      <div className="pt-3 px-2">
                        {!brandFare?.OfferItem?.FareDetail ? (
                          <button disabled className="button_style">
                            Sold Out
                          </button>
                        ) : (
                          <LightTooltip
                            title={
                              <div className="p-3">
                                <div className="d-flex justify-content-between align-items-end">
                                  <div className="d-flex me-4">
                                    <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                    <h5>* {searchValues?.adultsCount}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {perPaxPrice(adult, 1)}
                                  </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end my-2">
                                  <div className="d-flex">
                                    <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                    <h5>* {searchValues?.childCount}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {perPaxPrice(child, 1)}
                                  </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end">
                                  <div className="d-flex">
                                    <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                    <h5>* {searchValues?.infantNo}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {perPaxPrice(infant, 1)}
                                  </h6>
                                </div>
                              </div>
                            }
                            placement="top"
                          >
                            <h6 className="text-danger ps-2">{DiscountPrice(brandFare?.OfferItem?.FareDetail)}</h6>
                            <button
                              type="button"
                              className="button_style px-4"
                              onClick={() => {
                                let bookingBody = {
                                  flight: sereneFLT,
                                  fare: brandFare,
                                  gds: "SERENE-API",
                                  price: lowestFarePrice(
                                    brandFare?.OfferItem?.FareDetail
                                  ),
                                  oferRefId: brandFare?.OfferID,
                                  oferItemRefId: brandFare?.OfferItem?.OfferItemID,
                                  shopRefId: sereneFLT?.ShoppingResponseRefID,

                                  ttl_agent_fare: TwoDecimalPoint(
                                    CurrencyExchangeToPKR(
                                      Number(
                                        lowestFarePrice(
                                          brandFare?.OfferItem?.FareDetail
                                        )
                                          ?.split(" ")
                                          ?.at(1) || 0
                                      )
                                    )
                                  ),
                                  ttlagent_curr_fare: Number(
                                    lowestFarePrice(brandFare?.OfferItem?.FareDetail)
                                      ?.split(" ")
                                      ?.at(1) || 0
                                  ),
                                  ttl_supplier_fare: supplierPrice(
                                    brandFare?.OfferItem?.FareDetail
                                  ),
                                  ttlsup_curr_fare: TwoDecimalPoint(
                                    CurrencyExchange(
                                      supplierPrice(brandFare?.OfferItem?.FareDetail)
                                    )
                                  ),

                                  adlt_ttl_d_curr: CurrencyExchangeToPKR(
                                    Number(
                                      perPaxPrice(adult, searchValues?.adultsCount)
                                    )
                                  ),
                                  chld_ttl_d_curr: CurrencyExchangeToPKR(
                                    Number(
                                      perPaxPrice(child, searchValues?.childCount)
                                    )
                                  ),
                                  inft_ttl_d_curr: CurrencyExchangeToPKR(
                                    Number(
                                      perPaxPrice(infant, searchValues?.infantNo)
                                    )
                                  ),

                                  adultBaseFare: getPaxSupplierPrice(
                                    adult,
                                    searchValues?.adultsCount
                                  ).basePrice,

                                  childBaseFare: getPaxSupplierPrice(
                                    child,
                                    searchValues?.childCount
                                  ).basePrice,
                                  infantBaseFare: getPaxSupplierPrice(
                                    infant,
                                    searchValues?.infantNo
                                  ).basePrice,

                                  adultTaxes: getPaxSupplierPrice(
                                    adult,
                                    searchValues?.adultsCount
                                  ).taxes,

                                  childTaxes: getPaxSupplierPrice(
                                    child,
                                    searchValues?.childCount
                                  ).taxes,

                                  infantTaxes: getPaxSupplierPrice(
                                    infant,
                                    searchValues?.infantNo
                                  ).taxes,

                                  ADLT_TAXES: adult?.Price?.TaxSummary?.Tax?.map(
                                    (tax) => {
                                      return {
                                        code: tax?.TaxCode,
                                        amount: tax?.Amount?.["#text"],
                                      };
                                    }
                                  ),
                                  CHLD_TAXES: child?.Price?.TaxSummary?.Tax?.map(
                                    (tax) => {
                                      return {
                                        code: tax?.TaxCode,
                                        amount: tax?.Amount?.["#text"],
                                      };
                                    }
                                  ),
                                  INFT_TAXES: infant?.Price?.TaxSummary?.Tax?.map(
                                    (tax) => {
                                      return {
                                        code: tax?.TaxCode,
                                        amount: tax?.Amount?.["#text"],
                                      };
                                    }
                                  ),

                                  baggageName: brandFare?.classList?.Name,
                                  logo: "Serene",
                                  code: "ER",

                                  booking: {
                                    gds: "SERENE", // should be gds code
                                    pnr: "", // returned in pricing response
                                    agent_name: sereneFLT?.agent_name || "serene",
                                    segments: sereneFLT?.segments?.map((segment) => ({
                                      departure_date: moment(
                                        segment?.Dep?.AircraftScheduledDateTime
                                      ).format("YYYY-MM-DD"),
                                      baggage: baggage,
                                      departure_time:
                                        segment?.Dep?.AircraftScheduledDateTime?.split(
                                          "T"
                                        )
                                          ?.at(1)
                                          ?.slice(0, 5),
                                      arrival_date: moment(
                                        segment?.Arrival?.AircraftScheduledDateTime
                                      ).format("YYYY-MM-DD"),
                                      arrival_time:
                                        segment?.Arrival?.AircraftScheduledDateTime?.split(
                                          "T"
                                        )
                                          ?.at(1)
                                          ?.slice(0, 5),
                                      origin_terminal: "",
                                      desti_terminal: "",
                                      flight_number:
                                        segment?.MarketingCarrierInfo
                                          ?.MarketingCarrierFlightNumberText,
                                      number_in_party: "",
                                      res_book_desig_code: "",
                                      status: "NN",
                                      des_location: searchValues?.locationArrival,
                                      dest_code: searchValues?.locationArrival
                                        ?.split("-")
                                        ?.at(0),

                                      marketing_airline: "ER",
                                      orig_location: searchValues?.locationDep,
                                      orig_code: searchValues?.locationDep
                                        ?.split("-")
                                        ?.at(0),
                                      marriage_grp: "",
                                      cabin_class: brandFare?.classList?.Name,
                                      segment_type: getSectorsName(
                                        searchValues?.locationDep,
                                        searchValues?.locationArrival
                                      ), // dom | siti | soto | group
                                    })),
                                    tp_ur_code: "",
                                    platting_carrier: "ER",
                                    tp_ssr_verssion: 0,
                                    tp_trace_id: "",
                                  },
                                };

                                dispatch(
                                  setselectedFlight({
                                    ...bookingBody,
                                  })
                                );
                                navigate("/check-out");
                              }}
                            >
                              {lowestFarePrice(brandFare?.OfferItem?.FareDetail)}
                            </button>
                          </LightTooltip>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </Slider>
        )
      }
    </>
  );
};

const ConnectedFlight = ({
  item,
  inx,
  calculateTotalTimeDifference,
  fligthSegment,
  operatedAirline,
  convertTime,
}) => {
  const [airport_name, setairport_name] = useState("");
  const [arr_airport_name, setArrairport_name] = useState("");

  //api call for fatching airport name
  useEffect(() => {
    const fetchDataDep = async () => {
      try {
        let airportCode = item?.Dep?.IATA_LocationCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        // Handle the data here, for example, set it in the state
        setairport_name(data);
      } catch (error) {
        // Handle errors here
      }
    };

    const fetchDataArr = async () => {
      try {
        let airportCode = item?.Arrival?.IATA_LocationCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        // Handle the data here, for example, set it in the state
        setArrairport_name(data);
      } catch (error) {
        // Handle errors here
      }
    };

    fetchDataDep(); // Call the fetchData function
    fetchDataArr(); // Call the fetchData function
  }, []);

  return (
    <div key={inx}>
      <div className="row align-items-center">
        <div className="col-2">
          <img src={"/NEW_LOGO/ER.png"} alt="logo" width={70} height={70} />
          <h6 className="mt-3 ms-2">
            {item?.MarketingCarrierInfo?.CarrierDesigCode}{" "}
            {item?.MarketingCarrierInfo?.MarketingCarrierFlightNumberText}
          </h6>

          {item?.flightSegment?.operatingAirline?.code ? (
            <div className="mt-2">
              Operated by : {item?.flightSegment?.operatingAirline?.code}
            </div>
          ) : null}
        </div>
        <div className="col-10 d-flex justify-content-between align-items-center">
          <div className="col-5 d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightTakeoff size={30} className="color_primary" />
            </div>
            <div className="">
              <h6>
                {moment(item?.Dep?.AircraftScheduledDateTime).format(
                  "ddd DD-MMM-YY"
                )}
              </h6>

              <h6 className="my-3">
                {item?.Dep?.AircraftScheduledDateTime?.split("T")
                  ?.at(1)
                  ?.slice(0, 5)}
              </h6>
              <h6>
                {item?.Dep?.IATA_LocationCode} - {airport_name}
              </h6>
              {item?.flightSegment?.departureAirport?.terminal && (
                <h6 className="color_primary">
                  (Terminal-{item?.flightSegment?.departureAirport?.terminal})
                </h6>
              )}
            </div>
          </div>

          <div className="col-2 d-flex flex-column align-items-start">
            <div className="d-flex justify-content-center">
              <MdFlight className="color_primary fs-5 rotate-right" />
              <h6 className="text-center">Trip Time</h6>
            </div>
            <div className="border_rounded mb-2" />
            <h6 className="text-center ms-4">
              {convertTime(
                item?.Dep?.AircraftScheduledDateTime,
                item?.Arrival?.AircraftScheduledDateTime
              )}
            </h6>
          </div>
          <div className="col-5 d-flex align-items-center ps-4">
            <div className="me-3">
              <MdOutlineFlightLand size={30} className="color_primary" />
            </div>
            <div>
              <h6>
                {moment(item?.Arrival?.AircraftScheduledDateTime).format(
                  "ddd DD-MMM-YY"
                )}
              </h6>
              <h6 className="my-3">
                {item?.Arrival?.AircraftScheduledDateTime?.split("T")
                  ?.at(1)
                  ?.slice(0, 5)}
              </h6>
              <h6>
                {item?.Arrival?.IATA_LocationCode} - {arr_airport_name}
              </h6>
              {item?.flightSegment?.arrivalAirport?.terminal && (
                <h6 className="color_primary">
                  (Terminal-{item?.flightSegment?.arrivalAirport?.terminal})
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
      {inx < fligthSegment.length - 1 && (
        <div className="col-12 d-flex justify-content-center align-items-center my-4">
          <div className="border_rounded w-25" />
          <div className="color_primary mx-2 d-flex justify-content-center align-items-center">
            <AirlineSeatReclineNormalIcon className="color_primary" />{" "}
            <h6 className="pt-2">
              Layover time:{" "}
              <span className="fs-5">
                {" "}
                {calculateTotalTimeDifference(
                  item?.Arrival?.AircraftScheduledDateTime,
                  fligthSegment[inx + 1]?.Dep?.AircraftScheduledDateTime
                )}
              </span>
            </h6>
          </div>
          <div className="border_rounded w-25" />
        </div>
      )}
    </div>
  );
};

export default SereneApi;
