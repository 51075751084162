const getAmount = (type, pricing, isEconomy, isCodeShare) => {
  let amount = 0;
  if (isCodeShare) {
    amount = isEconomy ? pricing?.[`codeshare_com_${type}_eco`] : pricing?.[`codeshare_com_${type}_b_f`]
  } else {
    amount = isEconomy ? pricing?.[`com_${type}_eco`] : pricing?.[`com_${type}_b_f`]
  }
  return amount;
}
const getAmountOption = (type, pricing, isEconomy, isCodeShare) => {
  let amount = 0;
  if (isCodeShare) {
    amount = isEconomy ? pricing?.[`codeshare_com_${type}_option_eco`] : pricing?.[`codeshare_com_${type}_option_b_f`]
  } else {
    amount = isEconomy ? pricing?.[`com_${type}_option_eco`] : pricing?.[`com_${type}_option_b_f`]
  }
  return amount;
}

export const pricingairline = (
  airline,
  pricing,
  BasePrice,
  Taxes,
  sector,
  isExclusive,
  isCodeShare,
  isEconomy = true,
  segmentsCount = 1,
  isNotInfant = true,
) => {
  const exclusivePrice = pricing?.ApiPricingSectorModels?.at(0);
  const initAmount = {
    dom: isExclusive ? exclusivePrice?.price : getAmount("dom", pricing, isEconomy),
    siti: isExclusive ? exclusivePrice?.price : getAmount("intl", pricing, isEconomy, isCodeShare && pricing?.code_share_intl),
    soto: isExclusive ? exclusivePrice?.price : getAmount("soto", pricing, isEconomy, isCodeShare && pricing?.code_share_soto),


    dom_option: isExclusive ? exclusivePrice?.price_option : getAmountOption("dom", pricing, isEconomy),

    siti_option: isExclusive ? exclusivePrice?.price_option : getAmountOption("intl", pricing, isEconomy, isCodeShare && pricing?.code_share_intl),
    soto_option: isExclusive ? exclusivePrice?.price_option : getAmountOption("soto", pricing, isEconomy, isCodeShare && pricing?.code_share_soto),

    dom_percent: isExclusive ? true : pricing?.com_dom_type ? false : true,
    siti_percent: isExclusive ? true : pricing?.com_intl_type ? false : true,
    soto_percent: isExclusive ? true : pricing?.com_soto_type ? false : true,

    is_segment_wise: pricing?.is_segment_wise,
    subsidary_amount: isNotInfant ? pricing?.subsidary_amount || 0 : 0,
    subsidary_option: isNotInfant ? pricing?.subsidary_option : "NET NET",
  };
  let total_fare = 0;
  let supplier_total_fare = 0;
  let sp = 0;
  let psf = 0;
  let sup_psf_per_d_s_cur = 0;
  let income = 0;
  let income_type = "";
  let other_income = initAmount?.is_segment_wise ? Number(initAmount?.subsidary_amount) * segmentsCount : Number(initAmount?.subsidary_amount);
  let other_income_type = initAmount?.subsidary_option;
  let discount_given = 0;
  let airline_disc = 0;
  let airline_wht = 0;
  let agent_wht = 0;
  let agent_gst = 0;
  let agt_wht_per_d_s_cur = 0;
  let sup_psf_d_cur = 0;
  let sup_gst_per_d_s_cur = 0;
  let sup_gst_d_cur = 0;
  let sup_sp_per_d_s_cur = 0;
  let sup_wht_per_d_s_cur = 0;
  let agt_psf_per_d_s_cur = 0;
  let agt_sp_per_d_s_cur = 0;
  let agt_gst_per_d_s_cur = 0;
  if (pricing) {
    if (sector === "dom") {
      if (airline?.com_dom > 0) {
        if (initAmount?.dom > 0 && airline?.com_dom < initAmount?.dom) {
          if (initAmount?.dom_option === "PSF") {
            // psf = (BasePrice * initAmount?.dom) / 100;
            psf = initAmount?.dom_percent ? (BasePrice * initAmount?.dom) / 100 : initAmount?.dom;
            agt_psf_per_d_s_cur = initAmount?.dom;
            airline_disc = (BasePrice * airline?.com_dom) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes + parseFloat(psf);
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
            other_income = psf;
            other_income_type = "PSF";
          } else if (initAmount?.dom_option === "Discount") {
            // agent wht
            if (pricing?.wht > 0) {
              sp = initAmount?.dom_percent ? (BasePrice * initAmount?.dom) / 100 : initAmount?.dom;
              agt_sp_per_d_s_cur = initAmount?.dom;
              agent_wht = (sp * pricing?.wht) / 100;
              agt_wht_per_d_s_cur = pricing?.wht;
              airline_disc = (BasePrice * airline?.com_dom) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp + agent_wht;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = total_fare - supplier_total_fare;
              income_type = "disc_given";
            } else {
              sp = initAmount?.dom_percent ? (BasePrice * initAmount?.dom) / 100 : initAmount?.dom;
              agt_sp_per_d_s_cur = initAmount?.dom;
              airline_disc = (BasePrice * airline?.com_dom) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = total_fare - supplier_total_fare;
              income_type = "disc_given";
            }
          } else {
            airline_disc = (BasePrice * airline?.com_dom) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
          }
        } else if (initAmount?.dom > 0 && airline?.com_dom > initAmount?.dom) {
          if (initAmount?.dom_option === "PSF") {
            // psf = (BasePrice * initAmount?.dom) / 100;
            psf = initAmount?.dom_percent ? (BasePrice * initAmount?.dom) / 100 : initAmount?.dom;
            agt_psf_per_d_s_cur = initAmount?.dom;
            airline_disc = (BasePrice * airline?.com_dom) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes + parseFloat(psf);
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
            other_income = psf;
            other_income_type = "PSF";
          } else if (initAmount?.dom_option === "Discount") {
            if (pricing?.wht > 0) {
              sp = initAmount?.dom_percent ? (BasePrice * initAmount?.dom) / 100 : initAmount?.dom;
              agt_sp_per_d_s_cur = initAmount?.dom;
              agent_wht = (sp * pricing?.wht) / 100;
              airline_disc = (BasePrice * airline?.com_dom) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp + agent_wht;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = airline_disc - sp;
              income_type = "Discount";
              other_income = airline_wht - agent_wht;
              other_income_type = "wht";
            } else {
              sp = (BasePrice * initAmount?.dom) / 100;
              agt_sp_per_d_s_cur = initAmount?.dom;
              airline_disc = (BasePrice * airline?.com_dom) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = supplier_total_fare - total_fare;
              if (income < 0) {
                income_type = "disc_given";
              } else if (income > 0) {
                income_type = "Discount";
              } else {
                income_type = "";
              }
            }
          } else {
            airline_disc = (BasePrice * airline?.com_dom) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
          }
        } else if (
          initAmount?.dom > 0 &&
          airline?.com_dom === initAmount?.dom
        ) {
          if (pricing?.wht > 0) {
            sp = initAmount?.dom_percent ? (BasePrice * initAmount?.dom) / 100 : initAmount?.dom;
            agt_sp_per_d_s_cur = initAmount?.dom;
            agent_wht = (sp * pricing?.wht) / 100;
            airline_disc = (BasePrice * airline?.com_dom) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes - sp + agent_wht;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
          } else {
            sp = initAmount?.dom_percent ? (BasePrice * initAmount?.dom) / 100 : initAmount?.dom;
            agt_sp_per_d_s_cur = initAmount?.dom;
            airline_disc = (BasePrice * airline?.com_dom) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes - sp;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = supplier_total_fare - total_fare;
            income_type = "disc_given";
          }
        } else {
          airline_disc = (BasePrice * airline?.com_dom) / 100;
          sup_sp_per_d_s_cur = airline?.com_dom;
          airline_wht = (airline_disc * airline?.wht) / 100;
          sup_wht_per_d_s_cur = airline?.wht;
          total_fare = BasePrice + Taxes;
          supplier_total_fare = BasePrice + Taxes - airline_disc + airline_wht;
          income = airline_disc - airline_wht;
          income_type = "Discount";
        }
      } else {
        ///////// airline com code domestic is zero
        if (initAmount?.dom > 0) {
          if (initAmount?.dom_option === "Discount") {
            sp = initAmount?.dom_percent
              ? (BasePrice * initAmount?.dom) / 100
              : initAmount?.dom;
            agt_sp_per_d_s_cur = initAmount?.dom;
            agt_gst_per_d_s_cur = pricing?.gst;
            total_fare = BasePrice + Taxes - sp;
            supplier_total_fare = BasePrice + Taxes;
            income = sp;
            income_type = "disc_given";
          } else if (initAmount?.dom_option === "PSF") {
            psf = initAmount?.dom_percent
              ? (BasePrice * initAmount?.dom) / 100
              : initAmount?.dom;
            agt_psf_per_d_s_cur = initAmount?.dom;
            agent_gst = pricing?.gst > 0 ? (psf * pricing?.gst) / 100 : 0;
            supplier_total_fare = BasePrice + Taxes;
            total_fare = BasePrice + Taxes + parseFloat(psf) + agent_gst;
            income = psf;
            income_type = "PSF";
          } else {
            total_fare = BasePrice + Taxes;
            supplier_total_fare = BasePrice + Taxes;
          }
        } else {
          total_fare = BasePrice + Taxes;
          supplier_total_fare = BasePrice + Taxes;
        }
      }
    } else if (sector === "siti") {
      if (airline?.com_intl > 0) {
        if (initAmount?.siti > 0 && airline?.com_intl < initAmount?.siti) {
          if (initAmount?.siti_option === "PSF") {
            // psf = (BasePrice * initAmount?.siti) / 100;
            psf = initAmount?.siti_percent ? (BasePrice * initAmount?.siti) / 100 : initAmount?.siti;
            agt_psf_per_d_s_cur = initAmount?.dom;
            airline_disc = (BasePrice * airline?.com_intl) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes + parseFloat(psf);
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
            other_income = psf;
            other_income_type = "PSF";
          } else if (pricing?.siti_option === "Discount") {
            // agent wht
            if (pricing?.wht > 0) {
              sp = initAmount?.siti_percent ? (BasePrice * initAmount?.siti) / 100 : initAmount?.siti;
              agt_sp_per_d_s_cur = initAmount?.dom;
              agent_wht = (sp * pricing?.wht) / 100;
              airline_disc = (BasePrice * airline?.com_intl) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp + agent_wht;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = total_fare - supplier_total_fare;
              income_type = "disc_given";
            } else {
              sp = initAmount?.siti_percent ? (BasePrice * initAmount?.siti) / 100 : initAmount?.siti;
              agt_sp_per_d_s_cur = initAmount?.dom;
              airline_disc = (BasePrice * airline?.com_intl) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = total_fare - supplier_total_fare;
              income_type = "disc_given";
            }
          } else {
            airline_disc = (BasePrice * airline?.com_intl) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
          }
        } else if (
          initAmount?.siti > 0 &&
          airline?.com_intl > initAmount?.siti
        ) {
          if (initAmount?.siti_option === "PSF") {
            // psf = (BasePrice * initAmount?.siti) / 100;
            psf = initAmount?.siti_percent ? (BasePrice * initAmount?.siti) / 100 : initAmount?.siti;
            agt_psf_per_d_s_cur = initAmount?.dom;
            airline_disc = (BasePrice * airline?.com_intl) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes + parseFloat(psf);
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
            other_income = psf;
            other_income_type = "PSF";
          } else if (initAmount?.siti_option === "Discount") {
            if (pricing?.wht > 0) {
              sp = initAmount?.siti_percent ? (BasePrice * initAmount?.dominitAmount?.siti) / 100 : initAmount?.dominitAmount?.siti;
              agt_sp_per_d_s_cur = initAmount?.dom;
              agent_wht = (sp * pricing?.wht) / 100;
              airline_disc = (BasePrice * airline?.com_intl) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp + agent_wht;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = airline_disc - sp;
              income_type = "Discount";
              other_income = airline_wht - agent_wht;
              other_income_type = "wht";
            } else {
              sp = initAmount?.siti_percent ? (BasePrice * initAmount?.siti) / 100 : initAmount?.siti;
              agt_sp_per_d_s_cur = initAmount?.dom;
              airline_disc = (BasePrice * airline?.com_intl) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = supplier_total_fare - total_fare;
              if (income < 0) {
                income_type = "disc_given";
              } else if (income > 0) {
                income_type = "Discount";
              } else {
                income_type = "";
              }
            }
          } else {
            airline_disc = (BasePrice * airline?.com_intl) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
          }
        } else if (
          initAmount?.siti > 0 &&
          airline?.com_intl === initAmount?.siti
        ) {
          if (pricing?.wht > 0) {
            sp = initAmount?.siti_percent ? (BasePrice * initAmount?.siti) / 100 : initAmount?.siti;
            agt_sp_per_d_s_cur = initAmount?.dom;
            agent_wht = (sp * pricing?.wht) / 100;
            airline_disc = (BasePrice * airline?.com_intl) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes - sp + agent_wht;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
          } else {
            sp = (BasePrice * initAmount?.siti) / 100;
            agt_sp_per_d_s_cur = initAmount?.dom;
            airline_disc = (BasePrice * airline?.com_intl) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes - sp;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = supplier_total_fare - total_fare;
            income_type = "disc_given";
          }
        } else {
          airline_disc = (BasePrice * airline?.com_intl) / 100;
          sup_sp_per_d_s_cur = airline?.com_dom;
          airline_wht = (airline_disc * airline?.wht) / 100;
          sup_wht_per_d_s_cur = airline?.wht;
          total_fare = BasePrice + Taxes;
          supplier_total_fare = BasePrice + Taxes - airline_disc + airline_wht;
          income = airline_disc - airline_wht;
          income_type = "Discount";
        }
      } else {
        ///////// airline com code international siti is zero
        if (initAmount?.siti > 0) {
          if (initAmount?.siti_option === "Discount") {
            sp = initAmount?.siti_percent
              ? (BasePrice * initAmount?.siti) / 100
              : initAmount?.siti;
            agt_sp_per_d_s_cur = initAmount?.dom;
            agt_psf_per_d_s_cur = initAmount?.dom;
            agt_gst_per_d_s_cur = pricing?.gst;
            total_fare = BasePrice + Taxes - sp;
            supplier_total_fare = BasePrice + Taxes;
            income = sp;
            income_type = "disc_given";
          } else if (initAmount?.siti_option === "PSF") {
            psf = initAmount?.siti_percent
              ? (BasePrice * initAmount?.siti) / 100
              : parseFloat(initAmount?.siti);
            agent_gst = pricing?.gst > 0 ? (psf * pricing?.gst) / 100 : 0;
            supplier_total_fare = BasePrice + Taxes;
            total_fare = BasePrice + Taxes + psf + agent_gst;
            income = psf;
            income_type = "PSF";
          } else {
            total_fare = BasePrice + Taxes;
            supplier_total_fare = BasePrice + Taxes;
          }
        } else {
          total_fare = BasePrice + Taxes;
          supplier_total_fare = BasePrice + Taxes;
        }
      }
    } else if (sector === "soto") {
      //soto conditions
      if (airline?.soto > 0) {
        if (initAmount?.soto > 0 && airline?.soto < initAmount?.soto) {
          if (initAmount?.soto_option === "PSF") {
            // psf = (BasePrice * initAmount?.soto) / 100;
            psf = initAmount?.soto_percent ? (BasePrice * initAmount?.soto) / 100 : initAmount?.soto;
            agt_psf_per_d_s_cur = initAmount?.dom;
            airline_disc = (BasePrice * airline?.soto) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes + parseFloat(psf);
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
            other_income = psf;
            other_income_type = "PSF";
          } else if (initAmount?.soto_option === "Discount") {
            // agent wht
            if (pricing?.wht > 0) {
              sp = initAmount?.soto_percent
                ? (BasePrice * initAmount?.soto) / 100
                : initAmount?.soto;
              agt_sp_per_d_s_cur = initAmount?.dom;
              agent_wht = (sp * pricing?.wht) / 100;
              airline_disc = (BasePrice * airline?.soto) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp + agent_wht;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = total_fare - supplier_total_fare;
              income_type = "disc_given";
            } else {
              sp = initAmount?.soto_percent
                ? (BasePrice * initAmount?.soto) / 100
                : initAmount?.soto;
              agt_sp_per_d_s_cur = initAmount?.dom;
              airline_disc = (BasePrice * airline?.soto) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = total_fare - supplier_total_fare;
              income_type = "disc_given";
            }
          } else {
            airline_disc = (BasePrice * airline?.soto) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
          }
        } else if (initAmount?.soto > 0 && airline?.soto > initAmount?.soto) {
          if (initAmount?.soto_option === "PSF") {
            // psf = (BasePrice * initAmount?.soto) / 100;
            psf = initAmount?.soto_percent ? (BasePrice * initAmount?.soto) / 100 : initAmount?.soto;
            agt_psf_per_d_s_cur = initAmount?.dom;
            airline_disc = (BasePrice * airline?.soto) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes + parseFloat(psf);
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
            other_income = psf;
            other_income_type = "PSF";
          } else if (initAmount?.soto_option === "Discount") {
            if (initAmount?.wht > 0) {
              sp = initAmount?.soto_percent
                ? (BasePrice * initAmount?.soto) / 100
                : initAmount?.soto;
              agt_sp_per_d_s_cur = initAmount?.dom;
              agent_wht = (sp * pricing?.wht) / 100;
              airline_disc = (BasePrice * airline?.soto) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp + agent_wht;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = airline_disc - sp;
              income_type = "Discount";
              other_income = airline_wht - agent_wht;
              other_income_type = "wht";
            } else {
              sp = initAmount?.soto_percent
                ? (BasePrice * initAmount?.soto) / 100
                : initAmount?.soto;
              agt_sp_per_d_s_cur = initAmount?.dom;
              airline_disc = (BasePrice * airline?.soto) / 100;
              sup_sp_per_d_s_cur = airline?.com_dom;
              airline_wht = (airline_disc * airline?.wht) / 100;
              sup_wht_per_d_s_cur = airline?.wht;
              total_fare = BasePrice + Taxes - sp;
              supplier_total_fare =
                BasePrice + Taxes - airline_disc + airline_wht;
              income = supplier_total_fare - total_fare;
              if (income < 0) {
                income_type = "disc_given";
              } else if (income > 0) {
                income_type = "Discount";
              } else {
                income_type = "";
              }
            }
          } else {
            airline_disc = (BasePrice * airline?.soto) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = airline_disc - airline_wht;
            income_type = "Discount";
          }
        } else if (initAmount?.soto > 0 && airline?.soto === initAmount?.soto) {
          if (pricing?.wht > 0) {
            sp = initAmount?.soto_percent
              ? (BasePrice * initAmount?.soto) / 100
              : initAmount?.soto;
            agt_sp_per_d_s_cur = initAmount?.dom;
            agent_wht = (sp * pricing?.wht) / 100;
            airline_disc = (BasePrice * airline?.soto) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes - sp + agent_wht;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
          } else {
            sp = initAmount?.soto_percent
              ? (BasePrice * initAmount?.soto) / 100
              : initAmount?.soto;
            agt_sp_per_d_s_cur = initAmount?.dom;
            airline_disc = (BasePrice * airline?.soto) / 100;
            sup_sp_per_d_s_cur = airline?.com_dom;
            airline_wht = (airline_disc * airline?.wht) / 100;
            sup_wht_per_d_s_cur = airline?.wht;
            total_fare = BasePrice + Taxes - sp;
            supplier_total_fare =
              BasePrice + Taxes - airline_disc + airline_wht;
            income = supplier_total_fare - total_fare;
            income_type = "disc_given";
          }
        } else {
          airline_disc = (BasePrice * airline?.soto) / 100;
          sup_sp_per_d_s_cur = airline?.com_dom;
          airline_wht = (airline_disc * airline?.wht) / 100;
          sup_wht_per_d_s_cur = airline?.wht;
          total_fare = BasePrice + Taxes;
          supplier_total_fare = BasePrice + Taxes - airline_disc + airline_wht;
          income = airline_disc - airline_wht;
          income_type = "Discount";
        }
      } else {
        ///////// airline com code soto is zero
        if (initAmount?.soto > 0) {
          if (initAmount?.soto_option === "Discount") {
            sp = initAmount?.soto_percent
              ? (BasePrice * initAmount?.soto) / 100
              : initAmount?.soto;
            agt_sp_per_d_s_cur = initAmount?.dom;
            agt_gst_per_d_s_cur = pricing?.gst;
            total_fare = BasePrice + Taxes - sp;
            supplier_total_fare = BasePrice + Taxes;
            income = sp;
            income_type = "disc_given";
          } else if (initAmount?.soto_option === "PSF") {
            psf = initAmount?.soto_percent
              ? (BasePrice * initAmount?.soto) / 100
              : initAmount?.soto;
            agt_psf_per_d_s_cur = initAmount?.dom;
            agent_gst = pricing?.gst > 0 ? (psf * pricing?.gst) / 100 : 0;
            supplier_total_fare = BasePrice + Taxes;
            total_fare = BasePrice + Taxes + parseFloat(psf) + agent_gst;
            income = psf;
            income_type = "PSF";
          } else {
            total_fare = BasePrice + Taxes;
            supplier_total_fare = BasePrice + Taxes;
          }
        } else {
          total_fare = BasePrice + Taxes;
          supplier_total_fare = BasePrice + Taxes;
        }
      }
    }
    //////////// return statement if pricing is available

    const total = total_fare + (other_income_type === "Discount" ? -(other_income) : other_income);
    const supplier_total = BasePrice + Taxes;
    const totalIncome = supplier_total - total;
    let incomeType = "";
    if (totalIncome < 0) {
      incomeType = "PSF";
    } else if (totalIncome > 0) {
      incomeType = "disc_given";
    } else {
      incomeType = "";
    }
    return {
      BasePrice: BasePrice,
      Taxes: Taxes,
      total_fare: total,
      supplier_total_fare: supplier_total,
      income: Math.abs(totalIncome),
      income_type: incomeType,
      discount_given: Number(discount_given) + (other_income_type === "Discount" ? other_income : 0),
      airline_disc: airline_disc,
      airline_wht: airline_wht,
      agent_wht: agent_wht,
      other_income: other_income,
      other_income_type: other_income_type === "Net Net" ? "Net" : other_income_type === "PSF" ? "income" : "Disc-given",
      sp: incomeType === "disc_given" ? Math.abs(totalIncome) : 0,
      psf: incomeType === "PSF" ? Math.abs(totalIncome) : 0,
      agent_gst: agent_gst,
      initAmount: initAmount,
      pricing: pricing,
      agt_wht_per_d_s_cur: agt_wht_per_d_s_cur,
      sup_psf_per_d_s_cur: sup_psf_per_d_s_cur,
      sup_psf_d_cur: sup_psf_d_cur,
      sup_gst_per_d_s_cur: sup_gst_per_d_s_cur,
      sup_gst_d_cur: sup_gst_d_cur,
      sup_sp_per_d_s_cur: sup_sp_per_d_s_cur,
      sup_wht_per_d_s_cur: sup_wht_per_d_s_cur,
      agt_psf_per_d_s_cur: agt_psf_per_d_s_cur,
      agt_sp_per_d_s_cur: agt_sp_per_d_s_cur,
      agt_gst_per_d_s_cur: agt_gst_per_d_s_cur,
    };
  } else {
    const total = BasePrice + Taxes + (other_income_type === "Discount" ? -(other_income) : other_income);
    const supplier_total = BasePrice + Taxes;
    const totalIncome = supplier_total - total
    let incomeType = "";
    if (totalIncome < 0) {
      incomeType = "disc_given";
    } else if (totalIncome > 0) {
      incomeType = "PSF";
    } else {
      incomeType = "";
    }
    return {
      BasePrice: BasePrice,
      Taxes: Taxes,
      total_fare: total,
      supplier_total_fare: supplier_total,
      income: Math.abs(totalIncome),
      income_type: incomeType,
      discount_given: Number(discount_given) + (other_income_type === "Discount" ? other_income : 0),
      airline_disc: airline_disc,
      airline_wht: airline_wht,
      agent_wht: agent_wht,
      other_income: other_income,
      other_income_type: other_income_type,
      sp: incomeType === "disc_given" ? Math.abs(totalIncome) : 0,
      psf: psf,
      agent_gst: agent_gst,
      initAmount: initAmount,
      pricing: pricing,
      agt_wht_per_d_s_cur: agt_wht_per_d_s_cur,
      sup_psf_per_d_s_cur: sup_psf_per_d_s_cur,
      sup_psf_d_cur: sup_psf_d_cur,
      sup_gst_per_d_s_cur: sup_gst_per_d_s_cur,
      sup_gst_d_cur: sup_gst_d_cur,
      sup_sp_per_d_s_cur: sup_sp_per_d_s_cur,
      sup_wht_per_d_s_cur: sup_wht_per_d_s_cur,
      agt_psf_per_d_s_cur: agt_psf_per_d_s_cur,
      agt_sp_per_d_s_cur: agt_sp_per_d_s_cur,
      agt_gst_per_d_s_cur: agt_gst_per_d_s_cur,
    };
  }
};
