import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
    agentPnr: null,
    isLoading: false,
    error: null,
};

export const asyncPostAgentPnr = createAsyncThunk(
    "asyncPostAgentPnr/post",
    async (value, { rejectWithValue }) => {
        console.log("value", value);
        try {
            const response = await NODEURL.post("/api/v1/b2b/agentPnr", value);
            return await response.data.data;
        } catch (error) {
            rejectWithValue(error);
        }
    }
);

const agentPnr = createSlice({
    name: "agentPnr",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(asyncPostAgentPnr.pending, (state, { payload }) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(asyncPostAgentPnr.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = null;
            state.agentPnr = payload;
        });
        builder.addCase(asyncPostAgentPnr.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload;
        })
    }
})

export default agentPnr.reducer;
