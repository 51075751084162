import moment from "moment";
import React from "react";
import { MdOutlineFlightLand, MdOutlineFlightTakeoff } from "react-icons/md";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { CurrencyCode } from "../../../../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";

const FlyJinnahReturnCard = ({
  lowestTotalFare,
  timeDuration,
  segments,
  flyjinnahItem,
  index,
  toggleDetail,
  totalDiscount,
  fareBreakdowns
}) => {

  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  const firstSegment = segments?.at(0);
  const lastSegment = segments?.at(segments?.length - 1);

  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };

  return (

    <div style={{ borderBottom: index === 0 ? "1px solid lightgray" : "" }}
      onClick={(e) => {
        toggleDetail(index);
      }}>
      <div className="row align-items-center ">
        <div className="row col-9">
          <div className="col-2">
            <div className="px-2">
              <div className="text-center">
                <img
                  src={`/NEW_LOGO/${firstSegment?.flightNumber?.slice(0, 2)}.png`}
                  alt="9P"
                  style={{
                    width: "90px",
                    height: "90px",
                    objectFit: "contain",
                  }}
                />
                <div className="pt-3">
                  {segments?.map((it, idx, arr) => (
                    <span className="pe-1" key={idx}>{`${it?.flightNumber}
                     
                     `}</span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-10">
            <div className="d-flex justify-content-evenly align-items-center">
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <MdOutlineFlightTakeoff size={30} className="color_primary" />
                </div>
                <div>
                  <h6>
                    {moment(firstSegment?.departureDateTimeLocal?.split("T")[0]).format(
                      "ddd DD-MMM-YY"
                    )}
                  </h6>
                  <h6 className="my-3">
                    {moment(
                      firstSegment?.departureDateTimeLocal?.split("T")[1],
                      "HH:mm:ss"
                    ).format("HH:mm")}
                  </h6>
                  <h6>
                    {firstSegment?.origin?.airportCode} -{" "}
                    {index === 0 ? getCity(locDeparture) : getCity(locArrival)}
                  </h6>
                </div>
              </div>

              <div>
                <h6 className="text-center">
                  {timeDuration(
                    segments[0]?.departureDateTimeLocal,
                    segments[segments?.length - 1]?.arrivalDateTimeLocal
                  )}
                </h6>
                <div className="border_rounded mb-2" />
                <h6 className="mx-3">
                  {segments?.length === 1
                    ? "Non-Stop"
                    : segments?.length === 2
                      ? "One-Stop"
                      : segments?.length === 3
                        ? "Two-Stop"
                        : segments?.length === 4
                          ? "Three Stop"
                          : "Four Stop"}
                </h6>

                {segments?.length > 1 && (
                  <h6 className="text-center">
                    {segments
                      ?.slice(1)
                      ?.map((seg) => seg?.origin?.airportCode)
                      ?.join(", ")}
                  </h6>
                )}
              </div>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <MdOutlineFlightLand size={30} className="color_primary" />
                </div>
                <div>
                  <h6>
                    {moment(lastSegment?.arrivalDateTimeLocal?.split("T")[0]).format(
                      "ddd DD-MMM-YY"
                    )}
                  </h6>
                  <h6 className="my-3">
                    {moment(
                      lastSegment?.arrivalDateTimeLocal?.split("T")[1],
                      "HH:mm:ss"
                    ).format("HH:mm")}
                  </h6>
                  <h6>
                    {lastSegment?.destination?.airportCode} -{" "}
                    {index === 0 ? getCity(locArrival) : getCity(locDeparture)}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 py-2">
          <div
            className={`d-flex flex-column align-items-center justify-content-center result_box_body`}
          >
            <>
              {index !== 0 ? (
                <>
                  <h6 className="text-danger ms-2">{totalDiscount(fareBreakdowns)}</h6>
                  <div className="d-flex align-items-center gap-3">
                    <div className="h4">
                      {(lowestTotalFare(fareBreakdowns))}
                    </div>
                    <img
                      src={`/NEW_LOGO/${lastSegment?.flightNumber?.slice(0, 2)}.png`}
                      alt="9p"
                      width={40}
                      height={40}
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      toggleDetail(index);
                    }}
                    className="button_style w-75 mb-2 cursorpointer mt-2"
                    type="button"
                  >
                    <div>
                      <MenuOpenIcon className="me-1" />
                      Flight Detail
                    </div>
                  </button>
                </>
              ) : null}
            </>


          </div>
        </div>
      </div>
    </div>
  );
};

export default FlyJinnahReturnCard;
